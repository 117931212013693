import { z } from 'zod'

export const courseAndInstitutionRegisterFormDataSchema = z.object({
  course_id: z.string(),
  institution_id: z.string(),
})

export type CourseAndInstitutionRegisterFormDataSchemaType = z.infer<
  typeof courseAndInstitutionRegisterFormDataSchema
>
