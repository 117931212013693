import { AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';
import { FiPhoneCall } from 'react-icons/fi';
import { GrYoutube } from 'react-icons/gr';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { BiLogoBlogger } from 'react-icons/bi';

import styles from './styles.module.scss';

export function Footer() {
  return (
    <>
      <div className={styles.footer_container}>
        <h1>Fale conosco</h1>
        <div className={styles.email}>
          <h1>Email:</h1>
          <a href='mailto:contato@possoestudareducacao.com.br'>
            <HiOutlineMailOpen />
            contato@possoestudareducacao.com.br
          </a>
        </div>
        <div className={styles.column}>
          <span>
            <FiPhoneCall /> (85) 2180--6407
          </span>
          <span>
            <FiPhoneCall /> (85)98210-6409
          </span>
        </div>

        <div className={styles.column}>
          <div className={styles.hour}>
            <p>Seg a Sex: 08h00 às 18h00</p>
            <p>Sábado: 09h00 às 12h00</p>
          </div>
        </div>
      </div>

      <div className={styles.background}>
        <div className={styles.column}>
          <h4>Posso Estudar</h4>
          <a href='/quem-somos'>Quem Somos</a>
          <a href='/frequently-asked-questions'>Duvidas frequentes</a>
          <a href='https://api.whatsapp.com/send?phone=+5585982106409&text=Ol%C3%A1%20gostaria%20de%20ajuda%20para%20fazer%20a%20minha%20pr%C3%A9-matr%C3%ADcula'>
            Contato
          </a>
        </div>
        <div className={styles.line}></div>
        <div className={styles.column}>
          <h4>Instituição</h4>
          <a href='/amigoPE'>Amigo PE</a>
          <a href='/partner-school'>Seja uma instituição parceira</a>
        </div>
        <div className={styles.line}></div>
        <div className={styles.column}>
          <h4>Redes Sociais</h4>
          <div>
            <AiFillInstagram></AiFillInstagram>
            <a
              className={styles.social}
              href='https://www.instagram.com/possoestudareducacao/'
            >
              Instagram
            </a>
          </div>
          <div>
            <GrYoutube></GrYoutube>
            <a
              className={styles.social}
              href='https://www.youtube.com/channel/UCkxrvjuv0__LkzDWsjAVWNA'
            >
              Youtube
            </a>
          </div>
          <div>
            <AiFillLinkedin></AiFillLinkedin>
            <a
              className={styles.social}
              href='https://www.linkedin.com/company/posso-estudar-educa%C3%A7%C3%A3o/'
            >
              Linkedin
            </a>
          </div>
          <div>
            <BiLogoBlogger></BiLogoBlogger>
            <a
              className={styles.social}
              href='https://possoestudareducacao.blogspot.com/'
            >
              Blog
            </a>
          </div>
        </div>
      </div>
      <div className={styles.backgroundBottom}>
        <p>{`© ${new Date().getFullYear()} Posso Estudar Educação. Todos os direitos reservados`}</p>
      </div>
    </>
  );
}
