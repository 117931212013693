export const handleKeyUpCpf = (e?: React.FormEvent<HTMLInputElement>) => {
  if (!e) return (e.currentTarget.value = '');

  e.currentTarget.maxLength = 14;
  let value = e.currentTarget.value;

  value = value.replace(/\D/g, '');
  const length = value.length;

  if (length >= 3 && length <= 5) value = value.replace(/^(\d{3})/, '$1.');
  if (length >= 6 && length <= 8)
    value = value.replace(/^(\d{3})(\d{3})/, '$1.$2.');
  if (length >= 9 && length <= 10)
    value = value.replace(/^(\d{3})(\d{3})(\d{3})/, '$1.$2.$3-');
  if (length === 11)
    value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  e.currentTarget.value = value;
};

export const removeKeyUpValues = (e: React.FormEvent<HTMLInputElement>) => {
  e.currentTarget.value = '';
};

export const cpfMask = (cpf: string) => {
  const regex = (text: string) =>
    text.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  const cpfFormatted = cpf ? regex(cpf) : '';
  return cpfFormatted;
};

export const cpfPattern = '[0-9]{3}.[0-9][0-9][0-9].[0-9][0-9][0-9]-[0-9][0-9]';
