import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

// styles
import styles from './styles.module.scss';

// utils
import {
  StudentForthStepType,
  studentFourthStepFormDataSchema,
  StudentFormType,
} from '../../../utils/schemas/studentFormDataSchema';
import { stringToDate } from '../../../utils/formatting/dateMask';

//hooks
import { useValidateSchema } from '../../../hooks/useSchemaValidator';
import api from '../../../services/api';
import { USE_TERMS } from '../../../constants';

//components
import { Header } from '../../../components/header';
import { Statusbar } from '../../../components/Statusbar';
import { Input } from '../../../components/Input';
import { TitleFormStudents } from '../../../components/TitleForm';
import { Loader } from '../../../components/Loader';
import { FooterButtons } from '../../../components/FooterButtons';
import { Select } from '../../../components/Select';
import { handleKeyUpMoney } from '../../../utils/formatting/money';
import { AlunoRepository } from '../../admin/repositories/aluno.repository';
import { IFriends, IRegisterStudent } from '../../../interfaces/types';
import { Checkbox } from '../../../components/Checkbox';
import { Modal } from '../../../components/Modal';

interface IFormStudent {
  social_media: string;
  pe_code: string;
  family_income: string;
}

export function StudentsFormStepFour() {
  const [loader, setLoader] = useState(true);
  const [openTerms, setOpenTerms] = useState(false);
  const [friend, setFriend] = useState({} as IFriends);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const { register, isSubmitting, errors, handleSubmit } = useValidateSchema(
    studentFourthStepFormDataSchema as unknown as StudentForthStepType,
  );
  const navigate = useNavigate();
  const { course_id } = useParams();
  const { state } = useLocation();
  const hasErrors =
    !!errors?.pe_code || !!errors?.family_income || !!errors?.social_media;

  const formatIncome = (income: string) => {
    const value = income
      .replace('R$ ', '')
      .replaceAll('.', '')
      .replace(',', '');
    return +value;
  };

  const formatData = (data: StudentFormType) => ({
    alunocpf: data.cpf,
    alunonascimento: data?.date_of_birth,
    alunonome: data.name,
    code_pe: data.pe_code,
    conheceu: data.social_media,
    endereco: data.address,
    idcurso: +course_id,
    responsavelcpf: data.cpf_responsible,
    responsavelemail: data.email,
    responsavelnascimento: data?.date_of_birth_responsible,
    responsavelnome: data.name_responsible,
    responsaveltelefone: data.telephone,
    ultimaescola: data.current_school,
    friend_pe_id: friend.id,
    accepted_terms: acceptedTerms,
    family_income: formatIncome(data.family_income),
    cep: data.cep,
    second_phone: data.second_phone,
  });

  const getPeFriend = async (code_pe: string) => {
    const { data }: any = await api.get(`friends/by-code?code=${code_pe}`);

    if (!data?.friend) {
      toast('Código PE errado.', { type: 'error' });
      return true;
    }

    const { friend } = data;
    setFriend(friend);
    return false;
  };

  const handleOnSubmit = async (formData: IFormStudent) => {
    const { cadastrar } = AlunoRepository.GetInstance();

    const dataStudent = {
      ...state?.student,
      ...state?.responsible,
      ...formData,
      responsavelnascimento: stringToDate(
        state.responsible.date_of_birth_responsible,
      ),
      alunonascimento: state.student?.date_of_birth
        ? stringToDate(state.student.date_of_birth)
        : undefined,
    };

    if (formData?.pe_code) {
      const hasError = await getPeFriend(formData?.pe_code);
      if (hasError) return;
    }

    const formattedData = formatData(
      dataStudent,
    ) as unknown as IRegisterStudent;

    // eslint-disable-next-line no-async-promise-executor
    const register = new Promise(async (resolve, reject) => {
      try {
        const response = await cadastrar(formattedData);

        if (!hasErrors && response.status === 200) {
          navigate(`/register-student/${course_id}/success`);
          resolve(null);
        }
      } catch (error) {
        toast(error?.response?.data?.message?.message);
        reject(error?.response?.data?.message?.message);
        setLoader(false);
      }
    });

    toast.promise(register, {
      pending: 'Realizando inscrição...',
      success: 'Aluno inscrito!',
      error: 'Nao foi possível realizar a inscrição',
    });
  };

  const handleToggleTermsOfUse = () => setOpenTerms(true);

  const handleCloseModal = (accepted: boolean) => {
    setOpenTerms(false);
    setAcceptedTerms(accepted);
  };

  return (
    <>
      <Header />

      {isSubmitting && loader && <Loader />}

      {openTerms && (
        <Modal.Root title='Termos de uso' content={USE_TERMS}>
          <Modal.ButtonRoot>
            <Modal.Button
              labelText='Não concordo'
              handleClickButton={() => handleCloseModal(false)}
            />
            <Modal.Button
              labelText='Concordo'
              handleClickButton={() => handleCloseModal(true)}
              isPrimary
            />
          </Modal.ButtonRoot>
        </Modal.Root>
      )}

      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        className={styles.container}
      >
        <Statusbar step='step_4' />

        <span>Etapa 4 de 4</span>
        <div className={styles.content_form}>
          <TitleFormStudents title='Pesquisa' />
          <div className={styles.double_input}>
            <Input
              text='Qual sua renda familiar'
              name='family_income'
              register={{ ...register('family_income') }}
              placeholder='Insira sua renda familiar'
              onKeyUp={handleKeyUpMoney}
              errors={errors?.family_income}
            />
            <Input
              text='Código do amigo PE'
              name='pe_code'
              register={{ ...register('pe_code') }}
              placeholder='Insira o código de indicação do amigo PE'
              errors={errors?.pe_code}
            />
          </div>

          <div className={styles.use_terms}>
            <Select
              question='Como conheceu nosso programa?'
              name='social_media'
              register={{ ...register('social_media') }}
            />

            <Checkbox
              name='use_terms'
              checked={acceptedTerms}
              register={{ ...register('use_terms') }}
              onClick={handleToggleTermsOfUse}
              left_text='Aceitar termos de uso.'
            />
          </div>

          <FooterButtons
            redirectLink={`/register-student/${course_id}/step-3`}
            disabled={!acceptedTerms}
          />
        </div>
      </form>
    </>
  );
}
