import React, { createContext, useCallback, useContext, useState } from 'react'
import api from '../services/api'
import { Aluno } from '../pages/admin/entities/aluno.entity'
import { Instituicao } from '../pages/admin/entities/instituicao.entity'

interface AuthState {
  token: string
  aluno?: Aluno
  instituicao?: Instituicao
  admin?: any
}

interface SignInCredentials {
  login: string
  password: string
  role: 'aluno' | 'instituicao' | 'admin'
}

interface AuthContextData {
  token: string
  aluno?: Aluno
  instituicao?: Instituicao
  admin?: any
  signIn({ login, password, role }: SignInCredentials): Promise<void | Error>
  signOut(): void
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

interface IAuthProvider {
  children: React.ReactNode
}

export function AuthProvider({ children }: IAuthProvider) {
  const [data, setData] = useState<AuthState>((): AuthState => {
    const token = localStorage.getItem('@PE:token')
    const aluno = localStorage.getItem('@PE:aluno')
    const instituicao = localStorage.getItem('@PE:instituicao')
    const admin = localStorage.getItem('@PE:admin')

    if (token) {
      if (aluno != 'undefined') {
        return {
          token,
          aluno: JSON.parse(aluno),
        }
      } else if (instituicao != 'undefined') {
        return {
          token,
          instituicao: JSON.parse(instituicao),
        }
      } else if (admin != 'undefined') {
        return {
          token,
          admin: JSON.parse(admin),
        }
      }
    }

    return {} as AuthState
  })

  const signIn = useCallback(
    async ({ login, password, role }: SignInCredentials) => {
      try {
        const response = await api.post(`/${role}/login`, {
          login,
          password,
        })

        const { token, aluno, instituicao, admin } = response.data

        localStorage.setItem('@PE:token', token)
        localStorage.setItem('@PE:aluno', JSON.stringify(aluno))
        localStorage.setItem('@PE:instituicao', JSON.stringify(instituicao))
        localStorage.setItem('@PE:admin', JSON.stringify(admin))

        setData({ token, aluno, instituicao, admin })
      } catch (error) {
        console.error('error: ', error)
        return error
      }
    },
    [],
  )

  const signOut = useCallback(() => {
    localStorage.removeItem('@PE:token')
    localStorage.removeItem('@PE:aluno')
    localStorage.removeItem('@PE:instituicao')
    localStorage.removeItem('@PE:admin')

    setData({} as AuthState)
  }, [])

  return (
    <AuthContext.Provider
      value={{
        token: data.token,
        aluno: data.aluno,
        instituicao: data.instituicao,
        admin: data.admin,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const authContext = useContext(AuthContext)

  if (!authContext) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return authContext
}
