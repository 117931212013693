import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// styles
import styles from './styles.module.scss';

// api
import { ICurso } from '../../admin/repositories/curso.repository';
import { InstitutionRepository } from '../../admin/repositories/instituicao.repository';

//components
import { Header } from '../../../components/header';
import { Statusbar } from '../../../components/Statusbar';
import { TitleFormStudents } from '../../../components/TitleForm';
import { FooterButtons } from '../../../components/FooterButtons';
import { ImageApi } from '../../../components/ImageApi';

// utils
import { formatMoney } from '../../../utils/formatting/money';

export function StudentsFormOne() {
  const { course_id } = useParams();
  const [course, setCourse] = useState({} as ICurso);
  const navigate = useNavigate();
  const { instituicao: institute } = course;

  useEffect(() => {
    const fetchCurso = async () => {
      const _curso = await InstitutionRepository.GetInstance().getCursoById(
        Number(course_id),
      );
      setCourse({
        ..._curso,
        valor: formatMoney(_curso.valor),
        valorpromocional: formatMoney(_curso.valorpromocional),
        percentual: `${_curso.percentual}%`,
      });
    };
    fetchCurso();
  }, [course_id]);

  const handleRedirectNextPage = () => {
    navigate(`/register-student/${course_id}/step-2`);
  };

  return (
    <>
      <Header />
      <div className={styles.container_form}>
        <Statusbar step='step_1' />
        <span>Etapa 1 de 4</span>
        <div className={styles.content_form}>
          <TitleFormStudents title='Revise sua escolha' />

          <div className={styles.container_institute}>
            <div className={styles.image}>
              <ImageApi height={400} width={400} url={institute?.url_imagem} />
            </div>

            <div className={styles.course_information}>
              <strong>Curso</strong>
              <span>{course?.titulo}</span>
              <strong>Instituição</strong>
              <span>{institute?.titulo}</span>
              <strong>Valor</strong>
              <span>{course?.valor}</span>
              <strong>Desconto</strong>
              <span>{course?.percentual}</span>
              <strong>Valor com desconto</strong>
              <span>{course?.valorpromocional}</span>
            </div>
          </div>

          <FooterButtons
            redirectLink={`/`}
            handleClick={handleRedirectNextPage}
            type='button'
          />
        </div>
      </div>
    </>
  );
}
