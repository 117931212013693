import { Link } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import styles from './studentsRegistered.module.scss';

import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/useAuth';

import { IResponse, IStudentByInstitution } from '../../../../interfaces/types';

import { SelectPerPage } from '../../../../components/Pagination/Select';
import { Loader } from '../../../../components/Loader';
import { Pagination } from '../../../../components/Pagination';
import { formatBrDate } from '../../../../utils/formatting/dateMask';

interface IProps {
  institution: any;
}

export function StudentsRegistered({ institution }: IProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [perPage, setPerPage] = useState(5);
  const [response, setResponse] = useState(
    {} as IResponse<IStudentByInstitution>,
  );
  const { token } = useAuth();
  const { count, data: students } = response;

  const getStudents = useCallback(async () => {
    setLoading(true);
    const headers = { Authorization: `Bearer ${token}` };
    const params = `?page=${currentPage}&perPage=${perPage}&institution_id=${institution.id}`;
    const { data } = await api.get<IResponse<IStudentByInstitution>>(
      `/institutions/students-by-courses${params}`,
      { headers },
    );
    setResponse(data);
    setLoading(false);
  }, [currentPage, perPage, setLoading]);

  useEffect(() => {
    try {
      getStudents();
    } catch (error) {
      console.error('error: ', error);
      setLoading(false);
    }
  }, [getStudents]);

  return institution ? (
    <div className={styles.institution_container}>
      <h1>{institution.titulo}</h1>

      <SelectPerPage perPage={perPage} setPerPage={setPerPage} />

      {loading && (
        <>
          <Loader /> <h1 className={styles.loading}>...Buscando</h1>
        </>
      )}

      {!loading && (
        <table className={styles.table_container}>
          <thead>
            <tr>
              <td>Código</td>
              <td>Nome</td>
              <td>Data de Nascimento</td>
              <td>Curso</td>
            </tr>
          </thead>
          {students?.length > 0 ? (
            students.map((student) => {
              return (
                <tr>
                  <td>{student.id}</td>
                  <td>{student.name}</td>
                  <td>{formatBrDate(student.birth_date)}</td>
                  <td>{student.course}</td>
                </tr>
              );
            })
          ) : (
            <p>Nenhum aluno encontrado</p>
          )}
        </table>
      )}

      {!loading && (
        <Pagination
          count={count}
          page={currentPage}
          perPage={perPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  ) : (
    <p>
      Faça login como <Link to='/aluno/login'>ALUNO</Link> ou{' '}
      <Link to='/instituicao/login'>INSTITUIÇÃO</Link>
    </p>
  );
}
