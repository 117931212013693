import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'

import { adminRoutes } from './admin.routes'
import { mainRoutes } from './main.routes'
import { Layout } from './layout'

export function AppRouter() {
  const { admin } = useAuth()

  const routes = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: admin ? [...adminRoutes, ...mainRoutes] : mainRoutes,
    },
  ])

  return <RouterProvider router={routes} />
}
