import { Suspense, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { InstitutionRepository } from '../../repositories/instituicao.repository'
import { useAuth } from '../../../../hooks/useAuth'
import styles from './institutions.admin.module.scss'
import { LinkButton } from '../../../../components/LinkButton'
import { Loader } from '@/components/Loader'

interface IInstitution {
  id: number
  titulo: string
}

export function InstitutionsPage() {
  const { token } = useAuth()
  const [loading, setLoading] = useState(true)
  const [institutions, setInstitutions] = useState<IInstitution[]>()
  const navigate = useNavigate()

  const fetchInstitutions = useCallback(async () => {
    const institutions = await InstitutionRepository.GetInstance().adminList(
      token,
    )
    setInstitutions(institutions)
    setLoading(false)
  }, [token, InstitutionRepository])

  useEffect(() => {
    fetchInstitutions()
  }, [fetchInstitutions])

  async function openInstitutions(id: string) {
    navigate(`/instituicao/${id}`)
  }

  return (
    <Suspense fallback={<Loader />}>
      <div className={styles.mainContainer}>
        <LinkButton
          link="/instituicao/adicionar"
          labelText="Adicionar Instituição"
        />

        {institutions &&
          institutions?.map((institution) => {
            return (
              <div
                onClick={() => openInstitutions(String(institution.id))}
                className={styles.unit}
              >
                <p>{institution.id}</p>
                <p>{institution.titulo}</p>
              </div>
            )
          })}

        {loading && <Loader />}
      </div>
    </Suspense>
  )
}
