import axios, { AxiosResponse } from 'axios'
// import { URLs } from './env';

const token = localStorage.getItem('@PE:token')
const api = axios.create({
  baseURL: 'https://www.possoestudareducacao.com.br/api',
  // baseURL: 'http://localhost:5000/api',
  headers: { Authorization: `Bearer ${token}` },
})

export default api

export type Error = {
  message: string
}

export type HttpResponse<T, E> = [data: T | null, error: E | null]

export interface IResponse<T> {
  data: T
}

export const treatmentForErrors = async <T, E>(
  request: Promise<AxiosResponse<T, E>>,
): Promise<HttpResponse<T, E>> => {
  try {
    const response = (await request) as unknown as IResponse<T>
    return [response.data, null]
  } catch (err) {
    const error = (err?.response?.data?.error || err) as E
    return [null, error]
  }
}
