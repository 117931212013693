import styles from './styles.module.scss';
import { ButtonHTMLAttributes } from 'react';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}

export function ButtonsRoot({ children }: IProps) {
  return <div className={styles.buttons}>{children}</div>;
}
