import React, { createContext, useContext, useState } from 'react';
import { ICurso } from '../pages/admin/repositories/curso.repository';

interface CursoState {
  pesquisa: {
    idcategoria: number;
    cidade: string;
    titulo: string;
    test: string;
  };
  selectedCurso: ICurso;
}

interface CursoStateContextData {
  pesquisa: {
    idcategoria: number;
    cidade: string;
    titulo: string;
    test: string;
  };
  selectedCurso: ICurso;
  selectCategoria: (categoria: string) => void;
  selectTitulo: (titulo: string) => void;
  selectCidade: (cidade: string) => void;
  setSelectedCurso: (curso: ICurso) => void;
}

const CursoContext = createContext<CursoStateContextData>(
  {} as CursoStateContextData,
);

interface ICursoProvider {
  children: React.ReactNode;
}

export function CursoProvider({ children }: ICursoProvider) {
  const [data, setData] = useState<CursoState>({
    pesquisa: {
      idcategoria: 2,
      cidade: 'FORTALEZA',
    },
  } as CursoState);

  function selectCidade(cidade: string) {
    setData({
      ...data,
      pesquisa: {
        ...data.pesquisa,
        cidade,
      },
    });
  }

  function setSelectedCurso(curso: ICurso) {
    setData({
      ...data,
      selectedCurso: curso,
    });
  }

  function selectCategoria(categoria: string) {
    const categoria_mapper = {
      'Ensino Básico': 2,
      'Curso Técnico': 2,
      'Curso Profissionalizante': 2,
      Idiomas: 2,
      'Graduação/Pós-Graduação': 2,
      'Cursos Livres/Prepáratórios': 2,
    };

    setData({
      ...data,
      pesquisa: {
        ...data.pesquisa,
        idcategoria: categoria_mapper[categoria] ?? 2,
      },
    });
  }

  function selectTitulo(titulo: string) {
    setData({
      ...data,
      pesquisa: {
        ...data.pesquisa,
        titulo,
      },
    });
  }

  return (
    <CursoContext.Provider
      value={{
        pesquisa: data.pesquisa,
        selectedCurso: data.selectedCurso,
        selectCategoria,
        selectCidade,
        selectTitulo,
        setSelectedCurso,
      }}
    >
      {children}
    </CursoContext.Provider>
  );
}

export function useCurso() {
  const authContext = useContext(CursoContext);

  if (!authContext) {
    throw new Error('useCurso must be used within an CursoProvider');
  }

  return authContext;
}
