import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import styles from './styles.module.scss';

interface IProps {
  page: number;
  perPage: number;
  count: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

export const Pagination = ({
  count,
  page,
  perPage,
  setCurrentPage,
}: IProps) => {
  const last = Math.ceil(count / perPage);
  const pages = {
    last,
    penultimate: last - 1,
    antePenultimate: last - 2,
  };

  const goToUnderPage = () => {
    if (page > 1) setCurrentPage(page - 1);
  };

  const goToUpperPage = () => {
    if (last > page) setCurrentPage(page + 1);
  };

  const goToSelectedPage = (_page: number) => setCurrentPage(_page);

  const disableButton = (pageComponent: number) => page === pageComponent;

  const show = (pageComponent: number) => last >= pageComponent;

  const showButton = (pageComponent: number) =>
    pageComponent > 2 && show(pageComponent);

  return (
    <div className={styles.container_pagination}>
      <button onClick={goToUnderPage} disabled={disableButton(1)}>
        <FiArrowLeft size={20} color='#747D94' />
        <span>Anterior</span>
      </button>

      <button onClick={() => goToSelectedPage(1)} disabled={disableButton(1)}>
        1
      </button>
      <button onClick={() => goToSelectedPage(2)} disabled={disableButton(2)}>
        2
      </button>

      <span>...</span>
      {showButton(page - 1) && showButton(page + 1) && (
        <button
          onClick={() => goToSelectedPage(page - 1)}
          disabled={disableButton(page + 1)}
        >
          {page - 1}
        </button>
      )}

      {showButton(page) && showButton(page + 2) && (
        <button
          onClick={() => goToSelectedPage(pages.antePenultimate)}
          disabled
        >
          {page}
        </button>
      )}

      {showButton(page + 1) && showButton(page + 2) && (
        <button onClick={() => goToSelectedPage(page + 1)} disabled={false}>
          {page + 1}
        </button>
      )}
      <span>...</span>

      {showButton(pages.penultimate) && (
        <button
          onClick={() => goToSelectedPage(pages.penultimate)}
          disabled={disableButton(pages.penultimate)}
        >
          {pages.penultimate}
        </button>
      )}
      {showButton(pages.last) && (
        <button
          onClick={() => goToSelectedPage(pages.last)}
          disabled={disableButton(pages.last)}
        >
          {pages.last}
        </button>
      )}

      <button onClick={goToUpperPage} disabled={disableButton(pages.last)}>
        <span>Próxima</span>
        <FiArrowRight size={20} color='#747D94' />
      </button>
    </div>
  );
};
