import { useCallback, useEffect, useState } from 'react';
import { BlogItem } from './item';
import styles from './styles.module.scss';
import { listBlogsService } from '../../../services/blog/blog';
import { UpdateBlogFormDataSchemaType as BlogType } from '../../../utils/schemas/blogFormDataSchema';

export const Blog = () => {
  const [blogs, setBlogs] = useState<BlogType[]>([]);

  const listBlogs = useCallback(async () => {
    const [data, error] = await listBlogsService();
    if (error) return;
    setBlogs(data.blogs);
  }, []);

  useEffect(() => {
    listBlogs();
  }, [listBlogs]);

  return (
    <div className={styles.container}>
      {blogs.length > 0 && (
        <div className={styles.header}>
          <h1>Confira alguns de nossos artigos</h1>
          <a
            href='https://possoestudareducacao.blogspot.com/'
            target='blank'
            referrerPolicy='no-referrer'
          >
            Ir para blog
          </a>
        </div>
      )}
      {blogs.length > 0 && (
        <div className={styles.content}>
          {blogs.map((blog) => {
            return (
              <BlogItem
                key={blog.id}
                title={blog.title}
                content={blog.content}
                image={{
                  alt: blog.image_url.substring(0, 10),
                  src: blog.image_url,
                }}
                url={blog.url}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
