import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../../../hooks/useAuth';
import api from '../../../../../../services/api';
import { InstitutionRepository } from '../../../../repositories/instituicao.repository';
import styles from './styles.module.scss';

export interface IInstitution {
  id: number;
  titulo: string;
  email: string;
  senha: string;
  descricoes: string;
  descricoes2: string;
  descricoes3: string;
  cidade: string;
  bairro: string;
  status: string;
  url_imagem: string;
}

export function SeeInstitutionDetails() {
  const { token } = useAuth();
  const { id } = useParams();
  const [image, setImage] = useState<File>();
  const [editing, setEditing] = useState(false);
  const [institution, setInstitution] = useState<IInstitution>();

  async function fetchInstitutions() {
    const institution = await InstitutionRepository.GetInstance().adminShowById(
      token,
      id,
    );
    setInstitution(institution);
  }

  useEffect(() => {
    fetchInstitutions();
  }, []);

  async function updateImage() {
    const institution = await InstitutionRepository.GetInstance().uploadImage(
      token,
      id,
      image,
    );
    setInstitution(institution);
  }

  async function updateInstitution() {
    await InstitutionRepository.GetInstance().updateInstituicao(
      token,
      Number(id),
      institution,
    );

    if (image) {
      await updateImage();
      setImage(null);
    }

    setEditing(false);

    fetchInstitutions();
  }

  return (
    <>
      {institution && (
        <div className={styles.container}>
          <img
            width={256}
            src={`${api.getUri()}/uploads/${institution.url_imagem}`}
          ></img>
          <div className={styles.content}>
            <input disabled value={institution.id}></input>
            <input
              onChange={(e) =>
                setInstitution({ ...institution, titulo: e.target.value })
              }
              placeholder='titulo'
              disabled={!editing}
              value={institution.titulo}
            ></input>
            <input
              onChange={(e) =>
                setInstitution({ ...institution, email: e.target.value })
              }
              placeholder='email'
              disabled={!editing}
              value={institution.email}
            ></input>
            <input
              onChange={(e) =>
                setInstitution({ ...institution, senha: e.target.value })
              }
              placeholder='senha'
              disabled={!editing}
              value={institution.senha}
            ></input>
            <input
              onChange={(e) =>
                setInstitution({ ...institution, bairro: e.target.value })
              }
              placeholder='bairro'
              disabled={!editing}
              value={institution.bairro}
            ></input>
            <input
              onChange={(e) =>
                setInstitution({ ...institution, cidade: e.target.value })
              }
              placeholder='cidade'
              disabled={!editing}
              value={institution.cidade}
            ></input>
            <input
              onChange={(e) =>
                setInstitution({ ...institution, status: e.target.value })
              }
              placeholder='status'
              disabled={!editing}
              value={institution.status}
            ></input>
            <input
              onChange={(e) =>
                setInstitution({ ...institution, descricoes: e.target.value })
              }
              placeholder='descricoes'
              disabled={!editing}
              value={institution.descricoes}
            ></input>
            <input
              onChange={(e) =>
                setInstitution({ ...institution, descricoes2: e.target.value })
              }
              placeholder='descricoes2'
              disabled={!editing}
              value={institution.descricoes2}
            ></input>
            <input
              onChange={(e) =>
                setInstitution({ ...institution, descricoes3: e.target.value })
              }
              placeholder='descricoes3'
              disabled={!editing}
              value={institution.descricoes3}
            ></input>
            {editing && (
              <div className={styles.editing}>
                <input
                  onChange={(e) => setImage(e.target.files[0])}
                  type='file'
                ></input>
                <button onClick={updateInstitution}>Atualizar Dados</button>
              </div>
            )}

            <button onClick={() => setEditing(!editing)}>
              {editing ? 'Cancelar' : 'Editar'}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
