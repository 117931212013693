import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { GrLocation } from 'react-icons/gr';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ICurso } from '../pages/admin/repositories/curso.repository';
import { InstitutionRepository } from '../pages/admin/repositories/instituicao.repository';
import { ApiImage } from './ApiImage';
import styles from './curso.page.module.css';
import { TfiAlarmClock } from 'react-icons/tfi';

interface IInstituicaoImagem {
  image_url: string;
}

interface IGoogleMapsPlaceDetails {
  name: string;
  international_phone_number: string;
  vicinity: string;
  reviews: {
    author_name: string;
    profile_photo_url: string;
    rating: number;
    text: string;
  }[];
}

declare const google: any;

export function CursoPage() {
  const { id } = useParams();
  const [curso, setCurso] = useState<ICurso>();
  const [imagens, setImagens] = useState<IInstituicaoImagem[]>([]);

  useEffect(() => {
    fetchCurso();
  }, []);

  async function fetchCurso() {
    const _curso = await InstitutionRepository.GetInstance().getCursoById(
      Number(id),
    );
    setCurso(_curso);

    const imagens = await InstitutionRepository.GetInstance().listImagens(
      _curso.idinstituicao.toString(),
    );
    setImagens(imagens);
  }

  return curso ? (
    <div className={styles.mainContainer}>
      <div className={styles.popup}>
        <div className={styles.leftContainer}>
          <h2 className={styles.mainTitle}>{curso?.instituicao?.titulo}</h2>
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <GrLocation></GrLocation>
            {curso?.instituicao?.bairro}
          </p>
          <div>
            <iframe
              title={curso?.titulo}
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDWRf5JjVw7MJr-hcsft3YWeqQ1r0TmYN4&q=${curso?.instituicao.titulo},+${curso?.instituicao.bairro},+${curso?.instituicao.cidade},+${curso?.instituicao.uf}`}
              className={styles.map}
              /*width='600' height='300'*/ loading='lazy'
            ></iframe>
          </div>
          <div className={styles.lineTwo}></div>
          <h4 className={styles.h4}>Sobre o {curso?.instituicao.titulo}</h4>
          <p>{curso?.instituicao.descricao}</p>
          <p>{curso?.instituicao.descricao2}</p>
          <p>{curso?.instituicao.descricao3}</p>
          <div>
            <Carousel className={styles.Carousel}>
              {imagens &&
                imagens.map((image) => {
                  return (
                    <Carousel.Item>
                      <ApiImage
                        width={500}
                        url={image.image_url}
                        className='d-block w-100'
                      />
                    </Carousel.Item>
                  );
                })}
            </Carousel>
          </div>
        </div>
        <div className={styles.rightContainer}>
          <img></img>
          <p className={styles.title}>{curso?.titulo}</p>
          <div className={styles.info}>
            <p>
              Turno: <span>{curso?.turno}</span>
            </p>
            <p>
              Bairro: <span>{curso?.instituicao.bairro}</span>
            </p>
            <p>
              Início: <span>{curso?.anobolsa}</span>
            </p>
          </div>
          <div className={styles.price}>
            <div className={styles.column}>
              <p>Mensalidade Integral:</p>
              <p>
                Desconto <span>Posso estudar:</span>
              </p>
              <p>Você pagará por mês:</p>
              <p className={styles.scholarships}>
                <TfiAlarmClock size={24} /> Só restam{' '}
                <span>{curso.vagas} bolsas</span>
              </p>
            </div>
            <div className={styles.column}>
              <p>R$ {curso?.valor.toString().replace('.', ',')}</p>
              <p> - {curso.percentual.split(',')[0]}%</p>
              <p>R$ {curso?.valorpromocional.toString().replace('.', ',')}</p>
            </div>
          </div>
          <div className={styles.line}></div>
          <p>
            Economize{' '}
            <span>
              R${' '}
              {(
                (Number(curso.valor.replace(',', '.')) -
                  Number(curso.valorpromocional.replace(',', '.'))) *
                12
              )
                .toFixed(2)
                .replace('.', ',')}{' '}
              / ano
            </span>{' '}
            com essa bolsa!
          </p>
          <Link
            to={`/register-student/${id}/step-1`}
            className={styles.FakeButton}
          >
            Quero essa bolsa!
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <h1>Curso nao encontrado</h1>
  );
}
