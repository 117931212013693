import { useCallback, useEffect, useState } from 'react';
import { Eye, Pencil } from 'phosphor-react';

import { useAuth } from '../../../hooks/useAuth';
import api from '../../../services/api';

import styles from './styles.module.scss';

import {
  EditPartnerFormDataSchemaType,
  formatStatus,
} from '../../../utils/schemas/registerPartnersFormDataSchema';
import { formatBrDate } from '../../../utils/formatting/dateMask';

import { Loader } from '../../../components/Loader';
import Tooltip from '../../../components/Tooltip';
import { Modal } from '../../../components/Modal';
import { EditInterestedPartnerForm } from './editForm';
import { InterestedRepository } from '../../admin/repositories/intereste-partners.repository';
import { IResponse } from '../../../interfaces/types';
import { Pagination } from '../../../components/Pagination';
import { SelectPerPage } from '../../../components/Pagination/Select';

export function InterestedPartner() {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [partner, setPartner] = useState({} as EditPartnerFormDataSchemaType);
  const [response, setResponse] = useState(
    {} as IResponse<EditPartnerFormDataSchemaType>,
  );
  const { token } = useAuth();
  const { count, data: partners } = response;

  const getPartners = useCallback(async () => {
    const data = await InterestedRepository.GetInstance().list(token, {
      page: currentPage,
      perPage,
    });
    setResponse(data);
    setLoading(false);
  }, [api, setResponse, setLoading]);

  useEffect(() => {
    getPartners();
  }, [getPartners]);

  const showMessage = (data: EditPartnerFormDataSchemaType) => {
    setPartner(data);
    setShowMessageModal(true);
  };

  const showEditPartner = (data: EditPartnerFormDataSchemaType) => {
    setPartner(data);
    setShowEditModal(true);
  };

  const closeModal = () => {
    setShowEditModal(false);
    setShowMessageModal(false);
    setPartner({} as EditPartnerFormDataSchemaType);
  };

  return (
    <div className={styles.main_container}>
      <h1>Administrador - Instituições Interessadas</h1>

      <SelectPerPage perPage={perPage} setPerPage={setPerPage} />

      {partner?.message && showMessageModal && (
        <div className={styles.content_modal}>
          <Modal.Root
            content={partner?.message}
            title='Mensagem do Responsável'
          >
            <Modal.ButtonRoot>
              <Modal.Button onClick={closeModal} labelText='Voltar' />
            </Modal.ButtonRoot>
          </Modal.Root>
        </div>
      )}

      {partner?.status && showEditModal && (
        <div className={styles.content_edit_modal}>
          <Modal.Root content='' title='Edição das Instituições'>
            <EditInterestedPartnerForm
              data={partner}
              handleGoBack={closeModal}
              setLoading={setLoading}
              closeModal={closeModal}
              getPartners={getPartners}
            />
          </Modal.Root>
        </div>
      )}

      {loading && (
        <>
          <Loader /> <h1 className={styles.loading}>...Buscando</h1>
        </>
      )}

      {!loading && (
        <table className={styles.table_container}>
          <thead>
            <tr>
              <td>Responsável</td>
              <td>Instituto</td>
              <td>CNPJ</td>
              <td>Email</td>
              <td>Telefone</td>
              <td>Contactado</td>
              <td>Status</td>
              <td>Data Cadastro</td>
              <td>Última Atualização</td>
              <div className={styles.actions}>Ações</div>
            </tr>
          </thead>
          {partners.length > 0 ? (
            partners.map((partner) => {
              return (
                <tbody>
                  <tr>
                    <td>{partner.responsible}</td>
                    <td>{partner.institute_name}</td>
                    <td>{partner.cnpj}</td>
                    <td>{partner.email}</td>
                    <td>{partner.phone}</td>
                    <td>{partner.contacted ? 'Sim' : 'Não'}</td>
                    <td>{formatStatus(partner.status)}</td>
                    <td>{formatBrDate(partner.created_at)}</td>
                    <td>{formatBrDate(partner.updated_at)}</td>
                    <div className={styles.actions}>
                      <Tooltip title='Ver mensagem'>
                        <Eye onClick={() => showMessage(partner)} size={24} />
                      </Tooltip>

                      <Tooltip title='Editar'>
                        <Pencil
                          onClick={() => showEditPartner(partner)}
                          size={24}
                        />
                      </Tooltip>
                    </div>
                  </tr>
                </tbody>
              );
            })
          ) : (
            <p>Nenhuma instituição encontrada</p>
          )}
        </table>
      )}

      <Pagination
        count={count}
        page={currentPage}
        perPage={perPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}
