import { useNavigate, useParams } from 'react-router-dom';

// styles
import styles from './students.module.scss';

// utils
import {
  StudentSecondStepType,
  studentSecondStepFormDataSchema,
} from '../../../utils/schemas/studentFormDataSchema';
import { cpfPattern, handleKeyUpCpf } from '../../../utils/formatting/cpfMask';
import {
  handleKeyUpDate,
  datePattern,
} from '../../../utils/formatting/dateMask';

//hooks
import { useValidateSchema } from '../../../hooks/useSchemaValidator';

//components
import { Header } from '../../../components/header';
import { Statusbar } from '../../../components/Statusbar';
import { Input } from '../../../components/Input';
import { TitleFormStudents } from '../../../components/TitleForm';
import { Loader } from '../../../components/Loader';
import { FooterButtons } from '../../../components/FooterButtons';

export function StudentsForm() {
  const { register, isSubmitting, errors, handleSubmit } = useValidateSchema(
    studentSecondStepFormDataSchema as unknown as StudentSecondStepType,
  );
  const navigate = useNavigate();
  const { course_id } = useParams();
  const hasErrors =
    !!errors?.cpf ||
    !!errors?.current_school ||
    !!errors?.date_of_birth ||
    !!errors?.name;

  const handleOnSubmit = (student: StudentSecondStepType) => {
    if (!hasErrors)
      navigate(`/register-student/${course_id}/step-3`, { state: { student } });
  };

  return (
    <>
      <Header />
      {isSubmitting && <Loader />}
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        className={styles.container_form}
      >
        <Statusbar step='step_2' />

        <span>Etapa 2 de 4</span>

        <div className={styles.content_form}>
          <TitleFormStudents title='Dados do Aluno' />

          <Input
            text='Nome do Aluno'
            name='name'
            register={{ ...register('name') }}
            placeholder='Insira o nome do aluno'
            errors={errors.name}
          />

          <div className={styles.double_input}>
            <Input
              text='CPF do aluno'
              name='cpf'
              register={{ ...register('cpf') }}
              placeholder='Insira o cpf do aluno'
              errors={errors?.cpf}
              onKeyUp={handleKeyUpCpf}
              pattern={cpfPattern}
            />
            <Input
              text='Data de Nascimento'
              name='date_of_birth'
              register={{ ...register('date_of_birth') }}
              placeholder='Insira a data de nascimento do aluno'
              errors={errors?.date_of_birth}
              onKeyUp={handleKeyUpDate}
              pattern={datePattern}
            />
          </div>

          <Input
            text='Qual a escola atual do Aluno'
            name='current_school'
            register={{ ...register('current_school') }}
            placeholder='Insira a escola atual do aluno'
            errors={errors?.current_school}
          />

          <FooterButtons
            redirectLink={`/register-student/${course_id}/step-1`}
          />
        </div>
      </form>
    </>
  );
}
