import { useState } from 'react';

import styles from './styles.module.scss';
import { SeeInstitutionDetails } from './seeInstitution';
import { ICourse, SeeCourses } from './seeCourses';
import { UpdateCourse } from './updateCourse';
import { SeeImages } from './seeImage';
import { AddCourse } from './addCourse';

export type TypeState =
  | 'INST'
  | 'CURSOS'
  | 'ADD CURSO'
  | 'EDIT CURSO'
  | 'VER IMAGENS';

export function InstitutionPage() {
  const [courses, setCourses] = useState<ICourse[]>([]);
  const [editingCurso, setEditingCurso] = useState({} as ICourse);
  const [state, setState] = useState<TypeState>('INST');

  async function initEditarCurso(curso: ICourse) {
    setEditingCurso(curso);
    setState('EDIT CURSO');
  }

  return (
    <div className={styles.main_container}>
      <div className={styles.buttons_container}>
        <button onClick={() => setState('INST')}>Ver Instituição</button>
        <button onClick={() => setState('CURSOS')}>Ver Cursos</button>
        <button onClick={() => setState('ADD CURSO')}>Adicionar Curso</button>
        <button onClick={() => setState('VER IMAGENS')}>Ver Imagens</button>
      </div>

      {state === 'INST' && <SeeInstitutionDetails />}

      {state == 'CURSOS' && (
        <SeeCourses
          courses={courses}
          setCourses={setCourses}
          initEditarCurso={initEditarCurso}
        />
      )}

      {state == 'EDIT CURSO' && (
        <UpdateCourse
          setState={setState}
          editingCurso={editingCurso}
          setEditingCurso={setEditingCurso}
        />
      )}

      {state == 'ADD CURSO' && (
        <AddCourse
          courses={courses}
          setCourses={setCourses}
          setState={setState}
        />
      )}

      {state == 'VER IMAGENS' && <SeeImages />}
    </div>
  );
}
