import { InstitutionsPage } from '../pages/admin/pages/institutions/institutions.admin'
import { InstitutionPage } from '../pages/admin/pages/institutions/details'
import { AdicionarInstituicaoPage } from '../pages/admin/pages/institutions/addInstitution'
import { AlunoPage } from '../pages/admin/pages/aluno.admin'
import { EditFormStudent } from '../pages/admin/pages/students/edit'
import { StudentsFormStepSuccess } from '../pages/students/success/index.tsx'
import { ShowPEFriends } from '../pages/admin/pages/showPEFriends'
import { InterestedPartners } from '../pages/admin/pages/showInterestedPartners'
import { BlogAdmin } from '../pages/admin/pages/blog/registerBlog'
import { ListBlogs } from '../pages/admin/pages/blog/listBlog'
import AlunosPage from '../pages/admin/pages/students/list'
import ErrorBoundary from '@/pages/erro-boundary'

export const adminRoutes = [
  {
    path: '*',
    element: (
      <ErrorBoundary>
        <AlunosPage />
      </ErrorBoundary>
    ),
  },
  { path: '/instituicao', element: <InstitutionsPage /> },
  { path: '/instituicao/:id', element: <InstitutionPage /> },
  { path: '/instituicao/adicionar', element: <AdicionarInstituicaoPage /> },
  { path: '/aluno', element: <AlunosPage /> },
  { path: '/aluno/:id', element: <AlunoPage /> },
  { path: '/aluno/edit/:id', element: <EditFormStudent /> },
  { path: '/aluno/edit/:id/success', element: <StudentsFormStepSuccess /> },
  { path: '/show-pe-friends', element: <ShowPEFriends /> },
  { path: '/blogs', element: <ListBlogs /> },
  { path: '/blog/register', element: <BlogAdmin /> },
  { path: '/blog/register/:id', element: <BlogAdmin /> },
  { path: '/interested-partners', element: <InterestedPartners /> },
]
