import { ICurso } from '../pages/admin/repositories/curso.repository';
import { ApiImage } from './ApiImage';
import { Button } from './Button';
import styles from './curso.module.scss';
import { FcAlarmClock } from 'react-icons/fc';

interface ICursoProps {
  curso: ICurso;
  onClickDetalhes: () => void;
  onClickQuero: () => void;
}

export function Curso({ curso, onClickDetalhes, onClickQuero }: ICursoProps) {
  const years = () => {
    const currentYear = new Date().getFullYear();
    const currentYears: string[] = [];
    for (let i = currentYear - 4; i <= currentYear + 2; i++) {
      currentYears.push(i.toString());
    }
    return currentYears;
  };

  const formatTitle = (title: string) => {
    const year = title.slice(-4);
    if (years().includes(year)) {
      const body = title.replace(year, '');
      return (
        <>
          {body + ' '} <span>{year}</span>
        </>
      );
    }
    return title;
  };

  return (
    <div className={styles.main_container}>
      <div className={styles.container}>
        <div className={styles.left_container}>
          <ApiImage width={128} url={curso.instituicao.url_imagem} />
          <p>{curso.instituicao.titulo}</p>
        </div>
        <div className={styles.right_container}>
          <p className={styles.titulo}>{formatTitle(curso.titulo)}</p>
          <p className={styles.detalhe}>
            Modalidade: {curso.modalidade == '' ? '-' : curso.modalidade}
          </p>
          <p className={styles.detalhe}>
            Turno: <span>{curso.turno}</span>
          </p>
          <div className={styles.scholarships}>
            <FcAlarmClock size={22} /> Só restam{' '}
            <span>{curso.vagas} bolsas</span>
          </div>
        </div>
      </div>
      <div className={styles.line}>-</div>
      <div className={styles.footer}>
        <table className={styles.footer_unit}>
          <tr>
            <th>Mensalidade</th>
            <th>Desconto</th>
            <th>Total_Mensal </th>
            <th>Economia_Anual</th>
          </tr>
          <tr>
            <td>{`R$ ${curso.valor} / mês`}</td>
            <td>{curso.percentual.split(',')[0]}%</td>
            <td>R${curso.valorpromocional} / mês</td>
            <td>
              R$
              {(
                (Number(curso.valor.replace(',', '.')) -
                  Number(curso.valorpromocional.replace(',', '.'))) *
                12
              )
                .toFixed(2)
                .replace('.', ',')}{' '}
              / ano
            </td>
          </tr>
        </table>
        <div className={styles.footer_unit_mobile}>
          <div>
            <th>Mensalidade</th>
            <td>{`R$ ${curso.valor} / mês`}</td>
          </div>

          <div>
            <th>Desconto</th>
            <td>{curso.percentual.split(',')[0]}%</td>
          </div>

          <div>
            <th>Total_Mensal </th>
            <td>R${curso.valorpromocional} / mês</td>
          </div>

          <div>
            <th>Economia_Anual</th>
            <td>
              R$
              {(
                (Number(curso.valor.replace(',', '.')) -
                  Number(curso.valorpromocional.replace(',', '.'))) *
                12
              )
                .toFixed(2)
                .replace('.', ',')}{' '}
              / ano
            </td>
          </div>
        </div>
        <div className={styles.footer_buttons}>
          <a href='' onClick={onClickDetalhes}>
            <Button labelText='Ver detalhes' />
          </a>
          <Button
            isPrimary
            labelText='Quero essa bolsa'
            onClick={onClickQuero}
          />
        </div>
      </div>
    </div>
  );
}
