import { ReactNode } from 'react';
import { LinkButton } from '../../../components/LinkButton';
import styles from './styles.module.scss';

interface IProps {
  title: string;
  paragraph: string;
  icon: ReactNode;
}

export function BenefitsCards({ icon: Icon, paragraph, title }: IProps) {
  return (
    <div className={styles.benefits}>
      <span>{Icon}</span>
      <h2>{title}</h2>
      <p>{paragraph}</p>
      <div className={styles.button_link}>
        <LinkButton
          labelText='Ser Parceiro'
          link='/register-interested-partner'
        />
      </div>
    </div>
  );
}
