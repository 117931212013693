import { AuthProvider } from './../hooks/useAuth';
import { CursoProvider } from './../hooks/useCurso';
import { QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import { queryClient } from './libs/query-client';

export const Providers = (props: React.PropsWithChildren) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ToastContainer />
        <CursoProvider>{props.children}</CursoProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
};
