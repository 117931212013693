import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import styles from './register.module.scss';

import { useValidateSchema } from '../../../../hooks/useSchemaValidator';
import {
  createBlogsService,
  getBlogByIdService,
  updateBlogsService,
} from '../../../../services/blog/blog';
import {
  blogFormDataSchema,
  BlogFormDataSchemaType,
} from '../../../../utils/schemas/blogFormDataSchema';
import { toast } from 'react-toastify';
import { Loader } from '../../../../components/Loader';

export const BlogAdmin = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(!!id);
  const [blog, setBlog] = useState({} as BlogFormDataSchemaType);
  const { content, image_url, title, url } = blog;
  const navigate = useNavigate();

  const { register, errors, handleSubmit, setValue } = useValidateSchema(
    blogFormDataSchema as unknown as BlogFormDataSchemaType,
    { content, image_url, title, url },
  );

  const registerBlog = async (data: BlogFormDataSchemaType) => {
    setLoading(true);
    const request = id
      ? updateBlogsService({ ...data, id })
      : createBlogsService(data);
    await request;
    setLoading(false);
    navigate('/blogs');
  };

  const getBlogById = useCallback(async () => {
    const [data, error] = await getBlogByIdService(id);
    setLoading(false);
    if (error) return toast('Algo deu errado, tente novamente.');
    setBlog(data.blog);
  }, [id]);

  useEffect(() => {
    if (id) getBlogById();
  }, [getBlogById]);

  useEffect(() => {
    if (blog) {
      setValue('title', title);
      setValue('content', content);
      setValue('image_url', image_url);
      setValue('url', url);
    }
  }, [content, image_url, title, url]);

  return (
    <div className={styles.container}>
      {loading && <Loader />}
      <h1>Adicionar Blog</h1>

      <form onSubmit={handleSubmit(registerBlog)}>
        <Input
          text='Título'
          name='title'
          register={{ ...register('title') }}
          placeholder='Insira o título do post'
          errors={errors?.title}
        />
        <Input
          text='Prévia do conteúdo'
          name='content'
          register={{ ...register('content') }}
          placeholder='Insira a prévia do conteúdo do post'
          errors={errors?.content}
        />
        <Input
          text='Link da imagem do blog'
          name='image_url'
          register={{ ...register('image_url') }}
          placeholder='Insira o link da url do post'
          errors={errors?.image_url}
        />
        <Input
          text='Url do blog'
          name='url'
          register={{ ...register('url') }}
          placeholder='Insira a url do post'
          errors={errors?.url}
        />

        <Button labelText='Salvar' />
      </form>
    </div>
  );
};
