export const formatMoney = (value: string) => {
  if (!value) return 'Verificar com administrador';
  // eslint-disable-next-line no-unsafe-optional-chaining
  const [, centavos] = value?.split('.');
  return centavos?.length === 2
    ? `R$ ${value.replace('.', ',')}`
    : `R$ ${value.replace('.', ',').concat('0')}`;
};

export const handleKeyUpMoney = (e?: React.FormEvent<HTMLInputElement>) => {
  if (!e) return (e.currentTarget.value = '');

  let value = e.currentTarget.value;

  value = value.replace(/\D/g, '');

  value = value.replace(/(\d)(\d{2})$/, '$1,$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

  e.currentTarget.value = `R$ ${value}`;
};
