import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { useCallback, useEffect, useState } from 'react';

import styles from './list.module.scss';
import { Button } from '../../../../components/Button';

import { UpdateBlogFormDataSchemaType } from '../../../../utils/schemas/blogFormDataSchema';
import {
  deleteBlogService,
  listBlogsService,
} from '../../../../services/blog/blog';
import { Loader } from '../../../../components/Loader';

export const ListBlogs = () => {
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState<UpdateBlogFormDataSchemaType[]>([]);
  const navigate = useNavigate();

  const getBlogs = useCallback(async () => {
    const [data, error] = await listBlogsService();
    setLoading(false);
    if (error) return toast('Ocorreu algum erro, tente novamente.');
    setBlogs(data.blogs);
  }, []);

  const deleteBlog = useCallback(
    async (id: string) => {
      setLoading(true);
      const [_, error] = await deleteBlogService(id);
      setLoading(false);
      if (error) return toast('Ocorreu algum erro, tente novamente.');
      await getBlogs();
    },
    [getBlogs],
  );

  const redirectToRegisterBlog = () => navigate('/blog/register');

  const redirectToUpdateBlog = (id: string) => navigate('/blog/register/' + id);

  useEffect(() => {
    getBlogs();
  }, [getBlogs]);

  return (
    <div className={styles.container}>
      {loading && <Loader />}
      <div className={styles.header}>
        <h1>Blogs Registrados</h1>
        <Button
          labelText='Adicionar novo blog'
          onClick={redirectToRegisterBlog}
        />
      </div>
      {blogs.length > 0 &&
        blogs.map((blog) => {
          return (
            <div className={styles.table}>
              <div className={styles.content}>
                <strong>Título: </strong>
                <span>{blog.title}</span>
              </div>
              <div className={styles.content}>
                <strong>Preview do conteúdo: </strong>
                <span>{blog.content}</span>
              </div>
              <div className={styles.content}>
                <strong>Link da imagem: </strong>
                <span>{blog.image_url}</span>
              </div>
              <div className={styles.content}>
                <strong>Link do blog: </strong>
                <span>{blog.url}</span>
              </div>
              <FiEdit
                onClick={() => redirectToUpdateBlog(blog.id)}
                size={22}
                color='#1a961a'
                className={styles.icon}
              />
              <FiTrash2
                onClick={() => deleteBlog(blog.id)}
                size={22}
                color='red'
                className={styles.delete_icon}
              />
            </div>
          );
        })}
    </div>
  );
};
