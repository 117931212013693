import { useEffect } from 'react';
import { InstitutionRepository } from '../../../../repositories/instituicao.repository';
import { useAuth } from '../../../../../../hooks/useAuth';
import { useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { TypeState } from '..';

export interface ICourse {
  id: number;
  idcategoria: number;
  titulo: string;
  turno: string;
  modalidade: string;
  anobolsa: number;
  descricoes: string;
  valor: string;
  valorpromocional: string;
  vagas: number;
  percentual: string;
  status: string;
  excluido: string;
}

interface IProps {
  initEditarCurso: (course: ICourse) => void;
  setCourses: React.Dispatch<React.SetStateAction<ICourse[]>>;
  courses: ICourse[];
}

export const SeeCourses = ({
  initEditarCurso,
  courses,
  setCourses,
}: IProps) => {
  const { token } = useAuth();
  const { id } = useParams();

  useEffect(() => {
    fetchCursos();
  }, []);

  async function fetchCursos() {
    const cursos = await InstitutionRepository.GetInstance().adminShowCourses(
      token,
      id,
    );
    setCourses(cursos);
  }

  async function desativarCurso(course_id: number) {
    const cursos =
      await InstitutionRepository.GetInstance().adminAtualizarCursoAtivado(
        token,
        Number(id),
        course_id,
      );
    setCourses(cursos);
  }

  async function deletarCurso(course_id: string) {
    const cursoDeletado =
      await InstitutionRepository.GetInstance().adminDeletarCurso(
        token,
        id,
        course_id,
      );

    const novoCursos = courses.filter((curso) => curso.id != cursoDeletado.id);
    setCourses(novoCursos);
  }

  return courses?.length > 0 ? (
    <div>
      {courses.map((curso) => {
        return (
          <div className={styles.curso}>
            <p>{curso.titulo}</p>
            <p>||</p>
            <a href='#' onClick={() => desativarCurso(curso.id)}>
              {curso.status}
            </a>
            <p>||</p>
            <div className={styles.content_button}>
              <button onClick={() => deletarCurso(curso.id.toString())}>
                DELETAR
              </button>
              <button onClick={() => initEditarCurso(curso)}>EDITAR</button>
            </div>
          </div>
        );
      })}
    </div>
  ) : (
    <div>Essa instituição nao possui cursos</div>
  );
};
