import { z } from 'zod';

export const registerPartnerFormDataSchema = z.object({
  institute_name: z
    .string()
    .nonempty({ message: 'O nome da instituição é obrigatório' })
    .min(3, { message: 'O nome precisa de ter no mínimo 3 caracteres' }),
  cnpj: z
    .string()
    .nonempty({ message: 'O cnpj é obrigatório' })
    .min(14, { message: 'Formato de cnpj inválido' }),
  email: z.string().nonempty({ message: 'O email é obrigatório' }).email(),
  phone: z
    .string()
    .nonempty({ message: 'O telefone é obrigatório' })
    .min(10, { message: 'Formato de telefone inválido' }),
  city: z
    .string()
    .nonempty({ message: 'O nome da instituição é obrigatório' })
    .min(3, { message: 'O nome precisa de ter no mínimo 3 caracteres' }),
  responsible: z
    .string()
    .nonempty({ message: 'O nome da instituição é obrigatório' })
    .min(3, { message: 'O nome precisa de ter no mínimo 3 caracteres' }),
  message: z
    .string()
    .nonempty({ message: 'A mensagem é obrigatória' })
    .min(10, { message: 'A mensagem precisa de ter no mínimo 10 caracteres' }),
});

export type RegisterPartnerFormDataSchemaType = z.infer<
  typeof registerPartnerFormDataSchema
>;

export type EditPartnerFormDataSchemaType =
  RegisterPartnerFormDataSchemaType & {
    created_at: string;
    updated_at: string;
    status: 'pending' | 'approved' | 'reproved' | 'in_progress' | 'canceled';
    contacted: boolean;
  };

export const formatStatus = (status: string) =>
  ({
    pending: 'Pendente',
    approved: 'Aprovado',
    reproved: 'Reprovado',
    canceled: 'Cancelado',
    in_process: 'Em processo',
  })[status];

export const editPartnerFormDataSchema = z.object({
  status: z.string(),
  contacted: z.string(),
});
export type EditStatusPartnerFormDataSchemaType = z.infer<
  typeof editPartnerFormDataSchema
>;
