import { useState } from 'react'
import { useAuth } from '../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import styles from './login.module.scss'
import { toast } from 'react-toastify'

export function LoginAluno() {
  const { signIn } = useAuth()
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  async function handleLogin() {
    const error = await signIn({ login, password, role: 'aluno' })

    if (error)
      return toast(
        (error as Error)?.message || 'Ocorreu algum erro, tente novamente.',
        {
          type: 'error',
        },
      )

    navigate('/perfil')
  }

  return (
    <div className={styles.background}>
      <div className={styles.backgroundTwo}>
        <div className={styles.column}>
          <img src="/logo posso.png"></img>
          <div className={styles.text2}>
            <p>Entrar - Aluno</p>
          </div>
          <div className={styles.text}>
            <p>CPF do aluno:</p>
          </div>
          <input
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            placeholder="CPF do Aluno..."
          ></input>
          <div className={styles.text}>
            <p>CPF do responsável:</p>
          </div>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="CPF do Responsável..."
          ></input>
          <div className={styles.bar}></div>
          <button onClick={handleLogin}>Entrar</button>
        </div>
        <div className={styles.columnTwo}>
          <img src="/login-illustrator.png"></img>
        </div>
      </div>
    </div>
  )
}
