import styles from './styles.module.scss';
import { Book } from 'phosphor-react';

import partners from '../../assets/images/partners.png';
import { BenefitsCards } from './benefitsCard';
import { Partners } from './currentPartners';
import { ADVANTAGES } from '../../constants';
import { Link } from 'react-router-dom';

export function PartnerSchool() {
  const { ONE, TWO, THREE } = ADVANTAGES;
  return (
    <div className={styles.partner_school}>
      <Link to={'/register-interested-partner'}>
        <img src={partners} alt='Imagem vem ser nosso parceiro' />
      </Link>

      <section className={styles.second_section}>
        <h1>Vantagens de anunciar sua vaga no Posso Estudar Educação</h1>
        <div className={styles.benefits_content}>
          <BenefitsCards
            title={ONE.TITLE}
            paragraph={ONE.ADVANTAGE}
            icon={<Book size={48} />}
          />
          <BenefitsCards
            title={TWO.TITLE}
            paragraph={TWO.ADVANTAGE}
            icon={<Book size={48} />}
          />
          <BenefitsCards
            title={THREE.TITLE}
            paragraph={THREE.ADVANTAGE}
            icon={<Book size={48} />}
          />
        </div>
      </section>

      <section className={styles.contact}>
        <strong className={styles.responsive}>
          Email:
          <span>sejaparceiro@possoestudareducacao.com.br</span>
        </strong>
        <strong className={styles.small_mobile}>
          Email: <span>sejaparceiro</span>
          <span>@possoestudareducacao.com.br</span>
        </strong>
        <strong>
          <img src='/whatsapp.png' /> WhatsApp:<span>(85)9 8607-9070</span>
        </strong>
      </section>

      <section className={styles.third_section}>
        <h2>Parceiros que utilizam o Posso Estudar Educação</h2>
        <h4>
          Confira as instituições que já utilizam o Posso Estudar para captar
          alunos
        </h4>
        <Partners />
      </section>
    </div>
  );
}
