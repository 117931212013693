import { FormCapitalize } from './form';
import styles from './styles.module.scss';
import capitalize_image from '../../../assets/images/capitalize.jpg';

export const Capitalize = () => {
  return (
    <div className={styles.container}>
      <h1>Receba nosso conteúdo sobre as melhores bolsas.</h1>
      <h3>Preencha o formulário abaixo.</h3>
      <div className={styles.content_grid}>
        <div className={styles.content_image}>
          <img src={capitalize_image} alt='Imagem de uma criança estudando.' />
        </div>
        <FormCapitalize />
      </div>
    </div>
  );
};
