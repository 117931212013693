import styles from './styles.module.scss';

interface IProps {
  title: string;
  url: string;
  content: string;
  image: { src: string; alt: string };
}

export const BlogItem = ({ content, image, title, url }: IProps) => {
  const redirectToBlog = () => window.open(url, 'blank');

  const formatText = (text: string, maxSize: number) => {
    const lengthIsMajor = text.length > maxSize;
    const newText = `${text.substring(0, maxSize)}...`;
    return lengthIsMajor ? newText : text;
  };

  return (
    <div onClick={redirectToBlog} className={styles.container}>
      <img src={image.src} alt={image.alt} />
      <div className={styles.content}>
        <strong>{formatText(title, 59)}</strong>
        <p>{formatText(content, 180)}</p>
      </div>
    </div>
  );
};
