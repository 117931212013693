import { InputHTMLAttributes } from 'react';
import { FieldError } from 'react-hook-form';

import styles from './styles.module.scss';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  question: string;
  register?: any;
  errors?: FieldError;
}

export function Select({ question, register, ...props }: IProps) {
  return (
    <div className={styles.social_media}>
      <p>{question}</p>
      <select {...register} {...props}>
        <option value='Linkedin'>Linkedin</option>
        <option value='Facebook'>Facebook</option>
        <option value='Instagram'>Instagram</option>
        <option value='Google'>Google</option>
        <option value='Indicação de Amigos'>Indicação de Amigos</option>
        <option value='Outros'>Outros</option>
      </select>
    </div>
  );
}
