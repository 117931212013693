import { FaUserCircle } from 'react-icons/fa'
import { useAuth } from '../hooks/useAuth'
import styles from './aluno.module.css'
import { useEffect, useState } from 'react'
import { AlunoRepository } from './admin/repositories/aluno.repository'
import api from '../services/api'
import { Aluno } from './admin/entities/aluno.entity'

export function AlunoPerfilPage() {
  const { aluno, token, signOut } = useAuth()
  const [documentos, setDocumentos] = useState<any[]>([])
  const [editing, setEditing] = useState(false)

  const [alunoData, setAlunoData] = useState<Aluno>({} as Aluno)

  const [responsavelEmail, setResponsavelEmail] = useState('')
  const [responsavelTelefone, setResponsavelTelefone] = useState('')
  const [responsavelTelefone2, setResponsavelTelefone2] = useState('')

  async function fetchAluno() {
    if (!token || !aluno) {
      return
    }

    const _aluno = await AlunoRepository.GetInstance().findById(token)

    setAlunoData(_aluno)

    setResponsavelEmail(_aluno.responsavelemail)
    setResponsavelTelefone(_aluno.responsaveltelefone)
    setResponsavelTelefone2(_aluno.responsaveltelefone2)
  }

  useEffect(() => {
    fetchDocumentos()
    fetchAluno()
  }, [])

  async function fetchDocumentos() {
    if (!token || !aluno) {
      return
    }

    const documentos = await AlunoRepository.GetInstance().documentos(token)
    setDocumentos(documentos)
  }

  async function handleUpdate() {
    setEditing(false)

    const aluno = await AlunoRepository.GetInstance().atualizar(token, {
      responsavelemail: responsavelEmail,
      responsaveltelefone: responsavelTelefone,
      responsaveltelefone2: responsavelTelefone2,
    })

    setAlunoData(aluno)
    setResponsavelEmail(aluno.responsavelemail)
    setResponsavelTelefone(aluno.responsaveltelefone)
    setResponsavelTelefone2(aluno.responsaveltelefone2)
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.background}>
        {editing ? (
          <div>
            <button onClick={() => setEditing(false)}>Cancelar</button>
            <button onClick={handleUpdate}>Atualizar</button>
          </div>
        ) : (
          <button onClick={() => setEditing(true)}>Editar</button>
        )}
        <FaUserCircle></FaUserCircle>
        <h3>{alunoData.alunonome}</h3>

        <div className={styles.row}>
          <p>CPF do aluno: </p>
          <p>{alunoData.alunocpf}</p>
        </div>
        <div className={styles.line}></div>
        <div className={styles.row}>
          <p>CPF Responsavel:</p>
          <p>{alunoData.responsavelcpf}</p>
        </div>
        {editing ? (
          <>
            <div className={styles.row}>
              <p>EMAIL Responsavel: </p>
              <input
                value={responsavelEmail}
                onChange={(e) => setResponsavelEmail(e.target.value)}
              ></input>
            </div>
            <div className={styles.row}>
              <p>TELEFONE Responsavel: </p>
              <input
                value={responsavelTelefone}
                onChange={(e) => setResponsavelTelefone(e.target.value)}
              ></input>
            </div>
            <div className={styles.row}>
              <p>TELEFONE 2 Responsavel:</p>
              <input
                value={responsavelTelefone2}
                onChange={(e) => setResponsavelTelefone2(e.target.value)}
              ></input>
            </div>
          </>
        ) : (
          <>
            <div className={styles.row}>
              <p>EMAIL Responsavel: </p>
              <p>{alunoData.responsavelemail}</p>
            </div>
            <div className={styles.row}>
              <p>TELEFONE Responsavel: </p>
              <p>{alunoData.responsaveltelefone}</p>
            </div>
            <div className={styles.row}>
              <p>TELEFONE 2 Responsavel: </p>
              <p>{alunoData.responsaveltelefone2}</p>
            </div>
          </>
        )}
        <div className={styles.documentsContainer}>
          {documentos?.length > 0 ? (
            documentos?.map((doc) => {
              return (
                <p>
                  {doc.tipo} -{' '}
                  <a
                    target="blank"
                    href={`${api.getUri()}/uploads/documentos/${doc.arquivo}`}
                  >
                    {doc.arquivo}
                  </a>
                </p>
              )
            })
          ) : (
            <p>Nao ha documentos</p>
          )}
          <a className={styles.FakeButton} href={'/'} onClick={signOut}>
            Logout
          </a>
        </div>
      </div>
    </div>
  )
}
