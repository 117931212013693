import {
  BlogFormDataSchemaType,
  UpdateBlogFormDataSchemaType as BlogType,
} from '../../utils/schemas/blogFormDataSchema';
import api, { Error, HttpResponse, treatmentForErrors } from '../api';

type UpdateBlog = BlogFormDataSchemaType & {
  id: string;
};

export const listBlogsService = (): Promise<
  HttpResponse<{ blogs: BlogType[] }, Error>
> => {
  const request = api.get('/admin/blogs');
  return treatmentForErrors(request);
};

export const getBlogByIdService = (id: string) => {
  const request = api.get(`/admin/blog/${id}`);
  return treatmentForErrors(request);
};

export const deleteBlogService = (id: string) => {
  const request = api.delete(`/admin/blog/${id}`);
  return treatmentForErrors(request);
};

export const createBlogsService = (body: BlogFormDataSchemaType) => {
  const request = api.post('/admin/blog', body);
  return treatmentForErrors(request);
};

export const updateBlogsService = (body: UpdateBlog) => {
  const request = api.put(`/admin/blog/${body.id}`, body);
  return treatmentForErrors(request);
};
