import { useState } from 'react';
import { InstitutionRepository } from '../../repositories/instituicao.repository';
import { useAuth } from '../../../../hooks/useAuth';
import styles from './addInstitution.module.scss';
import { useNavigate } from 'react-router-dom';

interface ICreateInstituicao {
  titulo: string;
  descricoes: string;
  bairro: string;
  cidade: string;
  uf: string;
  status: string;
  email: string;
  senha: string;
  excluido: string;
}

export function AdicionarInstituicaoPage() {
  const { token } = useAuth();
  const [instituicao, setInstituicao] = useState<ICreateInstituicao>({
    bairro: '',
    cidade: '',
    descricoes: '',
    email: '',
    excluido: '',
    senha: '',
    status: 'Ativo',
    titulo: '',
    uf: '',
  } as ICreateInstituicao);
  const [image, setImage] = useState<File>();
  const navigate = useNavigate();

  async function adicionarInstituicao() {
    if (!image) return;

    const _instituicao = await InstitutionRepository.GetInstance().adminCreate(
      token,
      { data: instituicao },
    );

    await updateImagem(_instituicao.id);
    navigate('/instituicao/' + _instituicao.id);
  }

  async function updateImagem(id: string) {
    const instituicao = await InstitutionRepository.GetInstance().uploadImage(
      token,
      id,
      image,
    );
    setInstituicao(instituicao);
  }

  function changeInput(fieldName, value) {
    const new_instituicao = instituicao;
    new_instituicao[fieldName] = value;
    setInstituicao(new_instituicao);
  }

  return (
    <div className={styles.unit}>
      <input
        onChange={(e) =>
          setInstituicao({ ...instituicao, titulo: e.target.value })
        }
        placeholder='titulo'
        value={instituicao.titulo}
      ></input>
      <input
        onChange={(e) =>
          setInstituicao({ ...instituicao, email: e.target.value })
        }
        placeholder='email'
        value={instituicao.email}
      ></input>
      <input
        onChange={(e) =>
          setInstituicao({ ...instituicao, senha: e.target.value })
        }
        placeholder='senha'
        value={instituicao.senha}
      ></input>
      <input
        onChange={(e) =>
          setInstituicao({ ...instituicao, bairro: e.target.value })
        }
        placeholder='bairro'
        value={instituicao.bairro}
      ></input>
      <input
        onChange={(e) =>
          setInstituicao({ ...instituicao, cidade: e.target.value })
        }
        placeholder='cidade'
        value={instituicao.cidade}
      ></input>
      <input
        onChange={(e) =>
          setInstituicao({ ...instituicao, descricoes: e.target.value })
        }
        placeholder='descricoes'
        value={instituicao.descricoes}
      ></input>
      <input
        onChange={(e) =>
          setInstituicao({ ...instituicao, status: e.target.value })
        }
        placeholder='descricoes'
        value={'Ativo'}
      ></input>
      <input onChange={(e) => setImage(e.target.files[0])} type='file'></input>
      <button onClick={adicionarInstituicao}>Adicionar Instituição</button>
      <div className={styles.unit}></div>
    </div>
  );
}
