import styles from './styles.module.scss';

interface IProps {
  openTerms: () => void;
  handleAcceptTerms: (accept: boolean) => void;
}

export const UseTerms = ({ openTerms, handleAcceptTerms }: IProps) => {
  return (
    <div className={styles.use_terms_container} onClick={openTerms}>
      Ao continuar você concorda e aceita nossos <span>Termos de Uso.</span>
      <span onClick={() => handleAcceptTerms(true)}>Aceito</span>
    </div>
  );
};
