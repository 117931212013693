import api from '../../../services/api';
import { Curso } from '../entities/curso.entity';
import { BaseRepository } from './base.repository';

export interface ICurso {
  id: number;
  idinstituicao: number;
  idcategoria: number;
  titulo: string;
  turno: string;
  modalidade: string;
  anobolsa: number;
  descricoes: null;
  valor: string;
  valorpromocional: string;
  vagas: number;
  percentual: string;
  destaque: string;
  status: string;
  excluido: string;
  logusuario: number;
  logdata: string;
  instituicao: {
    id: number;
    url_imagem: string;
    titulo: string;
    cidade: string;
    bairro: string;
    descricao: string;
    descricao2: string;
    descricao3: string;
    uf: string;
    map_url: string;
  };
}

export class CursoRepository extends BaseRepository {
  private static Instance: CursoRepository = null;
  public static GetInstance() {
    return (this.Instance = this.Instance || new CursoRepository());
  }

  list(): Promise<Curso[]> {
    throw new Error('Method not implemented.');
  }

  async findById(id: number): Promise<Curso> {
    throw new Error('Method not implemented.');
  }

  async save(entity: Curso): Promise<Curso> {
    throw new Error('Method not implemented.');
  }

  async listTitulosByCidadeAndCategoria(
    idcategoria: number,
    cidade: string,
    bairro: string,
  ) {
    const headers = { Authorization: `Bearer ${this.token}` };
    const response = await api.get('/instituicao/titulos', {
      headers,
      params: {
        idcategoria,
        cidade,
        bairro,
      },
    });
    return response.data as any[];
  }

  async listarCursos(
    token: string,
    idcategoria: number,
    cidade?: string,
    titulo?: string,
    bairro?: string,
  ) {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    const response = await api.get('/curso', {
      headers,
      params: {
        idcategoria,
        cidade,
        titulo,
        bairro,
      },
    });

    return response.data as ICurso[];
  }

  async listarCursosDaInstituicao(token: string, idinstituicao?: string) {
    const response = await api.get('/instituicao/cursos/' + idinstituicao);
    return response.data.cursos as ICurso[];
  }

  async listarCursosDaInstituicaoPorCategoria(
    token: string,
    idinstituicao: string,
    idcategoria: string,
  ) {
    const response = await api.get(
      '/instituicao/cursos/' + idinstituicao + '/' + idcategoria,
    );
    return response.data as ICurso[];
  }

  async listarCursosDaCategoria(idcategoria: string) {
    const response = await api.get('/curso/categoria/' + idcategoria);
    return response.data.cursos as ICurso[];
  }
}
