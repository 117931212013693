import styles from './styles.module.scss';

interface IProps {
  title: string;
}

export function TitleFormStudents({ title }: IProps) {
  return (
    <div className={styles.container}>
      <p>{title}</p>
    </div>
  );
}
