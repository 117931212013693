import { MdArrowForwardIos } from 'react-icons/md';
import styles from './styles.module.scss';

export const PathInformation = () => {
  return (
    <div className={styles.container}>
      <div className={styles.instruction}>
        <img src='/chapeu.png' />
        <p>Escolha o curso ou série que deseja optar</p>
      </div>
      <div>
        <MdArrowForwardIos color='#FC5B11' />
      </div>
      <div className={styles.instruction}>
        <img src='/write.png' />
        <p>Encontre as principais informações e realize sua inscrição</p>
      </div>
      <div>
        <MdArrowForwardIos color='#FC5B11' />
      </div>
      <div className={styles.instruction}>
        <img src='/comment.png' />
        <p>Após o recebimento pela central, entraremos em contato</p>
      </div>
      <div>
        <MdArrowForwardIos color='#FC5B11'></MdArrowForwardIos>
      </div>
      <div className={styles.instruction}>
        <img src='/like.png' />
        <p>Logo você já poderá estudar pagando menos</p>
      </div>
    </div>
  );
};
