export const USE_TERMS =
  'Ao utilizar os nossos Serviços, o usuário aceita e concorda com todos os termos e condições expostas que se encontram vigentes na data. Alertamos que estes Termos e Condições de Uso poderão ser modificados a qualquer momento pela Posso Estudar Educação em virtude de alterações na legislação ou nos Serviços, em decorrência da utilização de novas ferramentas tecnológicas ou, ainda, sempre que, a exclusivo critério da Posso Estudar Educação , tais alterações se façam necessárias. A utilização dos Serviços online disponibilizados pela Posso Estudar Educação por qualquer usuário implicará em expressa aceitação destes Termos e Condições de Uso.';

export const PE_INDICATOR_VALUE = 15;

export const QUESTIONS = {
  ONE: {
    QUESTION: ' Quem pode ter a bolsa de estudo posso estudar educação?',
    RESPONSE:
      'O posso estudar educação tem a missão de entregar a oportunidade de uma educação de qualidade  há todo estudante que deseje ter a oportunidade de uma vaga em uma instituição educacional , o aluno que já é da instituição educacional não poderá participar do programa de bolsa de estudo apenas novos alunos que ainda não são da instituição de ensino desejada.',
  },
  TWO: {
    QUESTION: 'Como se cadastrar em uma bolsa de estudo?',
    RESPONSE:
      'Escolha o curso ou série que deseja optar, Encontre as principais informações e realize sua inscrição, Após o recebimento pela central, entraremos em contato, Logo você já poderá estudar pagando menos.',
  },
  THREE: {
    QUESTION: 'Ao me cadastrar já obtenho a bolsa de estudo Posso Estudar?',
    RESPONSE:
      'Após o seu cadastro , o aluno receberá a ligação de um consultor e passará pela entrevista após a entrevista terá a resposta da aprovação ou reprovação da bolsa de estudo.',
  },
  FOUR: {
    QUESTION: 'O posso estudar educação é um programa do governo ou privado?',
    RESPONSE:
      'O posso estudar educação  não é um programa do governo. Sua atuação é em parceria de escolas , e instituições educacionais  com o objetivo de ampliar o acesso à educação por meio da concessão de bolsas de estudo de até 60% nas mensalidades, até o fim do curso ou da etapa de formação.',
  },
  FIVE: {
    QUESTION: 'Taxa de manutenção?',
    RESPONSE:
      'O pagamento da taxa de manutenção é importante para a confirmação do benefício da bolsa de estudo até o final do curso. Esse pagamento deve ser efetuado ao Posso Estudar Educação anualmente  no mês de Maio  por boleto bancário, Pix ou cartão de credito  autorização enviada via   e-mail , WhatsApp .',
  },
};

export const ADVANTAGES = {
  ONE: {
    TITLE: ' Analise de captação do aluno',
    ADVANTAGE:
      'A analise da captação e prospecção do aluno fica a cargo da a equipe   de matricula posso estudar educação.',
  },
  TWO: {
    TITLE: 'Quais instituições podem ser parceiros posso estudar educação?',
    ADVANTAGE:
      'Escolas básicas privadas , cursos de idiomas , cursos técnicos , cursos profissionalizantes , faculdades .',
  },
  THREE: {
    TITLE: 'Quais as modalidades participantes?',
    ADVANTAGE: `Seja presencial ou ead ,as instituições educacionais poderão participar, basta se cadastrar no formulário, seja parceiro ou entrar em contato com nossos contatos abaixo via email ou whatsApp:`,
  },
};
