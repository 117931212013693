import { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

import api from '../../../../services/api';

import { Input } from '../../../../components/Input';
import { useValidateSchema } from '../../../../hooks/useSchemaValidator';
import {
  RegisterPartnerFormDataSchemaType,
  registerPartnerFormDataSchema,
} from '../../../../utils/schemas/registerPartnersFormDataSchema';
import {
  cnpjPattern,
  handleKeyUpCnpj,
} from '../../../../utils/formatting/cnpjMask';
import { handleKeyUpPhone } from '../../../../utils/formatting/phoneMask';

import { Loader } from '../../../../components/Loader';
import { FooterButtons } from '../../../../components/FooterButtons';

export function RegisterInterestedPartner() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { register, errors, handleSubmit } = useValidateSchema(
    registerPartnerFormDataSchema as unknown as RegisterPartnerFormDataSchemaType,
  );

  const handleOnSubmit = async (
    dataForm: RegisterPartnerFormDataSchemaType,
  ) => {
    setLoading(true);

    const treatmentError = () => {
      toast(data.partner.message, { type: 'error' });
      setLoading(false);
    };

    const success = () => {
      navigate('/interested-partners');
      setLoading(false);
    };

    const { data } = await api.post('/interested-partners', dataForm);
    return !data.partner?.id ? treatmentError() : success();
  };

  return (
    <>
      {loading && <Loader />}
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        className={styles.container_form}
      >
        <h1>Cadastro</h1>
        <div className={styles.double_input}>
          <Input
            text='Nome da Instituição'
            name='institute_name'
            register={{ ...register('institute_name') }}
            placeholder='Insira o nome do responsável'
            errors={errors?.institute_name}
          />
          <Input
            text='CNPJ'
            name='cnpj'
            register={{ ...register('cnpj') }}
            placeholder='Insira o cpf do responsável'
            onKeyUp={handleKeyUpCnpj}
            pattern={cnpjPattern}
            errors={errors?.cnpj}
          />
        </div>

        <div className={styles.double_input}>
          <Input
            text='Email'
            name='email'
            register={{ ...register('email') }}
            placeholder='Insira o email'
            errors={errors?.email}
          />
          <Input
            text='Telefone'
            name='phone'
            register={{ ...register('phone') }}
            placeholder='Insira o cpf do responsável'
            onKeyUp={handleKeyUpPhone}
            errors={errors?.phone}
          />
        </div>

        <div className={styles.double_input}>
          <Input
            text='Cidade'
            name='city'
            register={{ ...register('city') }}
            placeholder='Insira a cidade'
            errors={errors?.city}
          />
          <Input
            text='Seu nome'
            name='responsible'
            register={{ ...register('responsible') }}
            placeholder='Insira o nome do responsável'
            errors={errors?.responsible}
          />
        </div>
        <Input
          text='Mensagem'
          name='message'
          register={{ ...register('message') }}
          placeholder='Insira a mensagem'
          errors={errors?.message}
        />
        <FooterButtons redirectLink='/home' right_label='Salvar' />
      </form>
    </>
  );
}
