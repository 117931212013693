import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './styles.module.scss';
import { InstitutionRepository } from '../../admin/repositories/instituicao.repository';
import { ImageApi } from '../../../components/ImageApi';

interface IPartner {
  url_image: string;
  name: string;
  id: number;
}

export function Partners() {
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [slidesPerView, setSlidesPerView] = useState(6);

  useEffect(() => {
    const getInstitutes = async () => {
      const courses = await InstitutionRepository.GetInstance().listRandom('2');

      const coursesFormatted = courses
        .filter((c) => !!c)
        .map((c) => ({
          url_image: c.url_imagem,
          name: c.titulo,
          id: c.id,
        }));
      setPartners(coursesFormatted);
    };
    getInstitutes();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const count = () => {
        if (window.innerWidth > 850) return 6;
        return window.innerWidth < 610 ? 3 : 4;
      };
      setSlidesPerView(count());
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [window.innerWidth]);

  return (
    <div className={styles.container}>
      <Swiper
        slidesPerView={slidesPerView}
        slidesPerGroup={6}
        navigation
        pagination={{ clickable: true }}
        loop={true}
        className={styles.partners}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        speed={1000}
      >
        {partners.map((partner) => {
          return (
            <SwiperSlide key={partner.id}>
              <ImageApi
                url={partner.url_image}
                alt={`Logo da escola - ${partner.name}`}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
