import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../../../hooks/useAuth';
import { InstitutionRepository } from '../../../../repositories/instituicao.repository';
import { ApiImage } from '../../../../../../components/ApiImage';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';

interface IInstitutionImage {
  id: number;
  image_url: string;
}

export const SeeImages = () => {
  const { token } = useAuth();
  const { id } = useParams();
  const [image, setImage] = useState<File>();
  const [imagens, setImagens] = useState<IInstitutionImage[]>([]);

  async function addImage() {
    const institution_image =
      await InstitutionRepository.GetInstance().adicionarFotoAInstituicao(
        token,
        id,
        image,
      );
    setImagens([...imagens, institution_image]);
  }

  async function deleteImage(imageId: string) {
    await InstitutionRepository.GetInstance().removerFotoAInstituicao(
      token,
      imageId,
    );
    const new_images = imagens.filter(
      (image) => image.id.toString() != imageId,
    );
    setImagens(new_images);
  }

  async function fetchImagens() {
    const _imagens =
      await InstitutionRepository.GetInstance().listarImagensDaInstituicao(
        token,
        id,
      );
    setImagens(_imagens);
  }

  useEffect(() => {
    fetchImagens();
  }, []);

  return (
    <div className={styles.image_container}>
      {imagens.map((imagem) => {
        return (
          <div className={styles.image_content}>
            <ApiImage width={256} url={imagem.image_url} />
            <button onClick={() => deleteImage(imagem.id.toString())}>
              DELETAR
            </button>
          </div>
        );
      })}

      <div>
        <input
          onChange={(e) => setImage(e.target.files[0])}
          type='file'
        ></input>
        <button disabled={!image} onClick={addImage}>
          Adicionar foto
        </button>
      </div>
    </div>
  );
};
