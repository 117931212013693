import api from '../../../services/api';
import { IResponse } from '../../../interfaces/types';
import { EditPartnerFormDataSchemaType } from '../../../utils/schemas/registerPartnersFormDataSchema';

export class InterestedRepository {
  token?: string;

  setToken(_token?: string) {
    this.token = _token;
  }

  private static Instance: InterestedRepository = null;
  public static GetInstance() {
    const instance = this.Instance ? this.Instance : new InterestedRepository();
    return instance;
  }

  async list(
    token: string,
    params: { page: number; perPage: number },
  ): Promise<IResponse<EditPartnerFormDataSchemaType>> {
    const headers = { Authorization: `Bearer ${token}` };

    const { data } = await api.get<{
      partners: IResponse<EditPartnerFormDataSchemaType>;
    }>('/interested-partners', {
      headers,
      params,
    });

    return data.partners;
  }
}
