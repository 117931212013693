import { FooterButtons } from '@/components/FooterButtons'
import { CustomerSelect } from '@/components/Select/selectCustomized'
import styles from './course-by-student.module.scss'
import { useListCourses } from '@/pages/@hooks/use-list-courses'
import { useListInstitutions } from '@/pages/@hooks/use-list-institutions'
import { ListCoursesService } from '@/services/courses/list-courses'
import { ListInstitutionsService } from '@/services/institutions'
import { useValidateSchema } from '@/hooks/useSchemaValidator'
import {
  courseAndInstitutionRegisterFormDataSchema,
  CourseAndInstitutionRegisterFormDataSchemaType,
} from '@/utils/schemas/courseAndInstitutionRegisterFormDataSchema'
import { useEditRegisteredCourse } from '@/pages/@hooks/use-edit-registered-course'
import { UpdateCourseService } from '@/services/courses/update-courses'
import { Loader } from '@/components/Loader'
import { useEffect } from 'react'

const listCourseService = new ListCoursesService()
const listInstitutionsService = new ListInstitutionsService()
const updateCourseService = new UpdateCourseService()

export const CoursesByStudent = () => {
  const {
    institution,
    enrolledCourses,
    registrations,
    courseOptions,
    filterCoursesByInstitution,
  } = useListCourses({
    listCourseService,
  })

  const { institutions } = useListInstitutions({
    listInstitutionsService,
  })

  const { register, handleSubmit, setValue } = useValidateSchema(
    courseAndInstitutionRegisterFormDataSchema as unknown as CourseAndInstitutionRegisterFormDataSchemaType,
    {
      course_id: enrolledCourses?.id?.toString(),
      institution_id: institution?.id?.toString(),
    },
  )

  const { mutation } = useEditRegisteredCourse({
    updateCourseService,
    id: registrations?.length > 0 ? +registrations[0].id : 1,
  })

  const onSubmitForm = (
    data: CourseAndInstitutionRegisterFormDataSchemaType,
  ) => {
    const registration = registrations[0]
    mutation.mutate({
      idaluno: registration.idaluno,
      idcurso: +data.course_id,
      idinstituicao: +data.institution_id,
    })
  }

  useEffect(() => {
    setValue('course_id', enrolledCourses?.id?.toString())
    setValue('institution_id', institution?.id?.toString())
  }, [enrolledCourses?.id, institution?.id])

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmitForm)}>
      {mutation.isPending && <Loader />}
      <h1>Curso e Instituição</h1>
      <div className={styles.selects}>
        <CustomerSelect
          title="Curso"
          options={courseOptions}
          name="course_id"
          register={{ ...register('course_id') }}
        />
        <CustomerSelect
          title="Instituição"
          options={institutions || []}
          name="institution_id"
          register={{ ...register('institution_id') }}
          onChange={(event) => filterCoursesByInstitution(+event.target.value)}
        />
      </div>
      <FooterButtons
        containerStyles="flex_end"
        right_label="Salvar"
        redirectLink={`/aluno`}
        type="submit"
      />
    </form>
  )
}
