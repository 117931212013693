type IResponse<T> = T[]

export function useFormatOptions() {
  const formatOptions = <T>(data: IResponse<T>, key: string) => {
    console.log('data: ', data, key)
    const teste = data
      ? data?.map((d: any) => ({ text: d[key], value: d.id }))
      : []
    console.log('teste: ', teste)
    return teste
  }

  return {
    formatOptions,
  }
}
