import styles from './wpp.module.css';

export function Info() {
  const path = window.location.pathname;
  const isAdmin = path.includes('/aluno') || path.includes('/instituicao');
  return isAdmin ? (
    <span></span>
  ) : (
    <div className={styles.info}>
      <a
        className={styles.wpp}
        href='https://api.whatsapp.com/send?phone=+5585982106409&text=Ol%C3%A1%20gostaria%20de%20ajuda%20para%20fazer%20a%20minha%20pr%C3%A9-matr%C3%ADcula'
      >
        <img src='/wpp2.png'></img>
      </a>
    </div>
  );
}
