import styles from './styles.module.scss';
import { ButtonHTMLAttributes } from 'react';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}

export function FooterButtonsRoot({ children }: IProps) {
  return <div className={`${styles.footer_buttons}`}>{children}</div>;
}
