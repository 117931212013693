export const handleKeyUpPhone = (e?: React.FormEvent<HTMLInputElement>) => {
  if (!e) return (e.currentTarget.value = '');

  e.currentTarget.maxLength = 14;
  let value = e.currentTarget.value;

  value = value.replace(/\D/g, '');
  const length = value.length;

  if (length >= 2 && length <= 3) value = value.replace(/^(\d{2})/, '($1)');
  if (length >= 4 && length <= 6)
    value = value.replace(/^(\d{2})(\d{1})/, '($1) $2');
  if (length >= 7 && length <= 10)
    value = value.replace(/^(\d{2})(\d{1})(\d{4})/, '($1) $2$3');
  if (length >= 11 && length <= 14)
    value = value.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2$3-$4');

  e.currentTarget.value = value;
};

export const removeKeyUpValues = (e: React.FormEvent<HTMLInputElement>) => {
  e.currentTarget.value = '';
};

export const phonePattern = '([0-9]{2})[0-9]{5}-[0-9]{4}';
