import { Course } from '../../domain/entities/course'
import api, { HttpResponse, treatmentForErrors } from '../api'
import { IUpdateRegisteredCourses } from '@/domain/contracts/registered-courses'

export class UpdateCourseService implements IUpdateRegisteredCourses {
  async update(
    id: number,
    registered: Course,
  ): Promise<HttpResponse<void, Error>> {
    const request = api.put(`/courses/${id}`, registered)
    return treatmentForErrors(request)
  }
}
