import { IInstitution } from '@/domain/entities/institution'
import { IListCourses } from '../../domain/contracts/courses'
import { Course } from '../../domain/entities/course'
import api, { HttpResponse, treatmentForErrors } from '../api'

export class ListCoursesService implements IListCourses {
  async loadByStudentId(
    id: number,
  ): Promise<
    HttpResponse<
      {
        data: {
          courses: Course
          institutions: IInstitution
          registrations: Course[]
        }
      },
      Error
    >
  > {
    const request = api.get(`/courses/by-student-id/${id}`)
    return treatmentForErrors(request)
  }

  async loadById(id: number): Promise<HttpResponse<Course, Error>> {
    const request = api.get(`/courses/${id}`)
    return treatmentForErrors(request)
  }

  async loadAll(): Promise<HttpResponse<{ courses: Course[] }, Error>> {
    const request = api.get('/courses')
    return treatmentForErrors(request)
  }
}
