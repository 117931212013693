import { formatDate } from './../formatting/dateMask';
import { z } from 'zod';
import { Aluno } from '../../pages/admin/entities/aluno.entity';

export const studentSecondStepFormDataSchema = z.object({
  name: z
    .string()
    .nonempty({ message: 'O nome é obrigatório' })
    .min(3, { message: 'O nome precisa de ter no mínimo 3 caracteres' }),
  cpf: z
    .string()
    .nonempty({ message: 'O cpf é obrigatório' })
    .min(14, { message: 'Formato de cpf inválido' }),
  date_of_birth: z
    .string()
    .nonempty({ message: 'A data de nascimento é obrigatória' })
    .min(10, { message: 'Formato de data inválido' }),
  current_school: z
    .string()
    .nonempty({ message: 'A escola atual é obrigatória' })
    .min(3, {
      message: 'A atual escola precisa de ter no mínimo 3 caracteres',
    }),
});

export const studentThirdStepFormDataSchema = z.object({
  name_responsible: z
    .string()
    .nonempty({ message: 'O nome do responsável é obrigatório' })
    .min(3, { message: 'O nome precisa de ter no mínimo 3 caracteres' }),
  cpf_responsible: z
    .string()
    .nonempty({ message: 'O cpf do responsável é obrigatório' })
    .min(14, { message: 'Formato de cpf inválido' }),
  date_of_birth_responsible: z
    .string()
    .nonempty({ message: 'A data de nascimento é obrigatória' })
    .min(10, { message: 'Formato de data inválido' }),
  telephone: z
    .string()
    .nonempty({ message: 'O telefone é obrigatório' })
    .min(10, { message: 'Formato de telefone inválido' }),
  second_phone: z
    .string()
    .nonempty({ message: 'O telefone é obrigatório' })
    .min(10, { message: 'Formato de telefone inválido' }),
  address: z.string().nonempty({ message: 'O endereço é obrigatório' }).min(3, {
    message: 'O endereço precisa de ter no mínimo 3 caracteres',
  }),
  cep: z.string().nonempty({ message: 'O cep é obrigatório' }),
  email: z.string().nonempty({ message: 'O email é obrigatório' }).email(),
});

export const editStudentFormDataSchema = z.object({
  status: z.string().nonempty(),
  name: z
    .string()
    .nonempty({ message: 'O nome é obrigatório' })
    .min(3, { message: 'O nome precisa de ter no mínimo 3 caracteres' }),
  cpf: z
    .string()
    .nonempty({ message: 'O cpf é obrigatório' })
    .min(14, { message: 'Formato de cpf inválido' }),
  date_of_birth: z
    .string()
    .nonempty({ message: 'A data de nascimento é obrigatória' })
    .min(10, { message: 'Formato de data inválido' }),
  current_school: z
    .string()
    .nonempty({ message: 'A escola atual é obrigatória' })
    .min(3, {
      message: 'A atual escola precisa de ter no mínimo 3 caracteres',
    }),
  name_responsible: z
    .string()
    .nonempty({ message: 'O nome do responsável é obrigatório' })
    .min(3, { message: 'O nome precisa de ter no mínimo 3 caracteres' }),
  cpf_responsible: z
    .string()
    .nonempty({ message: 'O cpf do responsável é obrigatório' })
    .min(14, { message: 'Formato de cpf inválido' }),
  date_of_birth_responsible: z
    .string()
    .nonempty({ message: 'A data de nascimento é obrigatória' })
    .min(10, { message: 'Formato de data inválido' }),
  telephone: z
    .string()
    .nonempty({ message: 'A data de nascimento é obrigatória' })
    .min(10, { message: 'Formato de data inválido' }),
  second_phone: z.string().nullable(),
  address: z.string().nonempty({ message: 'O endereço é obrigatório' }).min(3, {
    message: 'O endereço precisa de ter no mínimo 3 caracteres',
  }),
  cep: z.string().nonempty({ message: 'O cep é obrigatório' }),
  email: z.string().nonempty({ message: 'O email é obrigatório' }).email(),
});

export const studentFourthStepFormDataSchema = z.object({
  social_media: z.string().nullable(),
  family_income: z
    .string()
    .nonempty({ message: 'Informar a renda é obrigatória' }),
  pe_code: z.string().nullable(),
  use_terms: z.boolean(),
});

export type StudentSecondStepType = z.infer<
  typeof studentSecondStepFormDataSchema
>;

export type StudentThirdStepType = z.infer<
  typeof studentThirdStepFormDataSchema
>;

export type StudentForthStepType = z.infer<
  typeof studentFourthStepFormDataSchema
>;

export type StudentFormType = StudentSecondStepType &
  StudentThirdStepType &
  StudentForthStepType;

export type EditStudentFormType = z.infer<typeof editStudentFormDataSchema>;

export const formatValidatorDefaultValues = (
  student: Aluno,
): EditStudentFormType => {
  const status = student.status === 'Aguardando' ? 'pending' : student.status;
  return {
    address: student.endereco,
    cep: student.cep,
    cpf: student.alunocpf,
    cpf_responsible: student.responsavelcpf,
    current_school: student.ultimaescola,
    date_of_birth: formatDate(student.alunonascimento.toString()),
    date_of_birth_responsible: formatDate(student.alunonascimento.toString()),
    email: student.responsavelemail,
    name: student.alunonome,
    name_responsible: student.responsavelnome,
    telephone: student.responsaveltelefone,
    second_phone: student.responsaveltelefone2,
    status,
  };
};
