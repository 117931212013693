import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

// styles
import styles from './styles.module.scss'

//components
import { Header } from '../../../../../components/header'
import { FooterButtons } from '../../../../../components/FooterButtons'
import { TitleFormStudents } from '../../../../../components/TitleForm'
import { Input } from '../../../../../components/Input'
import { CustomerSelect } from '../../../../../components/Select/selectCustomized'
import { Loader } from '../../../../../components/Loader'

// utils
import {
  EditStudentFormType,
  editStudentFormDataSchema,
  formatValidatorDefaultValues,
} from '../../../../../utils/schemas/studentFormDataSchema'
import { useValidateSchema } from '../../../../../hooks/useSchemaValidator'
import {
  cpfPattern,
  handleKeyUpCpf,
} from '../../../../../utils/formatting/cpfMask'
import {
  datePattern,
  handleKeyUpDate,
  formatDate,
} from '../../../../../utils/formatting/dateMask'
import { handleKeyUpPhone } from '../../../../../utils/formatting/phoneMask'
import { handleKeyUpCep } from '../../../../../utils/formatting/cepMask'

// repository
import { Aluno } from '../../../entities/aluno.entity'
import { AlunoRepository } from '../../../repositories/aluno.repository'
import { CoursesByStudent } from './courses'

export function EditFormStudent() {
  const [loading, setLoading] = useState(false)
  const { state } = useLocation() as { state: Aluno }
  const navigate = useNavigate()
  const { id } = useParams()

  const formattedValidatorDefaultValues = formatValidatorDefaultValues(state)
  const [stateStudent, setStatus] = useState(
    formattedValidatorDefaultValues?.status,
  )

  const { register, errors, handleSubmit } = useValidateSchema(
    editStudentFormDataSchema as unknown as EditStudentFormType,
    formattedValidatorDefaultValues,
  )

  const formatData = (data: EditStudentFormType) => {
    return {
      alunocpf: data.cpf,
      alunonascimento: formatDate(data.date_of_birth),
      alunonome: data.name,
      cep: data.cep,
      endereco: data.address,
      responsavelcpf: data.cpf_responsible,
      responsavelemail: data.email,
      responsavelnascimento: formatDate(data.date_of_birth_responsible),
      responsavelnome: data.name_responsible,
      responsaveltelefone: data.telephone,
      responsaveltelefone2: data.second_phone,
      ultimaescola: data.current_school,
      status: stateStudent || data.status,
    }
  }

  const handleOnSubmit = async (student: EditStudentFormType) => {
    setLoading(true)
    try {
      const formattedStudent = formatData(student)
      const token = localStorage.getItem('@PE:token')
      await AlunoRepository.GetInstance().atualizar(token, {
        ...formattedStudent,
        id,
      })
      navigate(`/aluno/edit/${id}/success`)
      setLoading(false)
    } catch (error) {
      console.error('error: ', error?.response?.data?.message?.message)
      setLoading(false)
    }
  }

  return (
    <>
      <Header />
      {loading && <Loader />}
      <div className={styles.forms}>
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          className={styles.container_form}
        >
          <section className={styles.form_fields}>
            <section className={styles.content_form}>
              <TitleFormStudents title="Dados do Aluno" />

              <Input
                text="Nome do Aluno"
                name="name"
                register={{ ...register('name') }}
                placeholder="Insira o nome do aluno"
                errors={errors.name}
              />

              <Input
                text="CPF do aluno"
                name="cpf"
                register={{ ...register('cpf') }}
                placeholder="Insira o cpf do aluno"
                errors={errors?.cpf}
                onKeyUp={handleKeyUpCpf}
                pattern={cpfPattern}
              />
              <Input
                text="Data de Nascimento"
                name="date_of_birth"
                register={{ ...register('date_of_birth') }}
                placeholder="Insira a data de nascimento do aluno"
                errors={errors?.date_of_birth}
                onKeyUp={handleKeyUpDate}
                pattern={datePattern}
              />

              <Input
                text="Qual a escola atual do Aluno"
                name="current_school"
                register={{ ...register('current_school') }}
                placeholder="Insira a escola atual do aluno"
                errors={errors?.current_school}
              />
            </section>

            <section className={styles.content_form}>
              <TitleFormStudents title="Dados do Responsável" />

              <div className={styles.double_input}>
                <Input
                  text="Nome do Responsável"
                  name="name_responsible"
                  register={{ ...register('name_responsible') }}
                  placeholder="Insira o nome do responsável"
                  errors={errors?.name_responsible}
                />
                <Input
                  text="Email"
                  name="email"
                  register={{ ...register('email') }}
                  placeholder="Insira o email do responsável"
                  errors={errors?.email}
                />
              </div>

              <div className={styles.double_input}>
                <Input
                  text="CPF do Responsável"
                  name="cpf_responsible"
                  register={{ ...register('cpf_responsible') }}
                  placeholder="Insira o cpf do responsável"
                  onKeyUp={handleKeyUpCpf}
                  pattern={cpfPattern}
                  errors={errors?.cpf_responsible}
                />
                <Input
                  text="Data de Nascimento"
                  name="date_of_birth_responsible"
                  register={{ ...register('date_of_birth_responsible') }}
                  placeholder="Insira a data de nascimento do responsável"
                  errors={errors?.date_of_birth_responsible}
                  onKeyUp={handleKeyUpDate}
                  pattern={datePattern}
                />
              </div>

              <div className={styles.double_input}>
                <Input
                  text="Telefone"
                  name="telephone"
                  register={{ ...register('telephone') }}
                  placeholder="Insira o telefone do responsável"
                  errors={errors?.telephone}
                  onKeyUp={handleKeyUpPhone}
                />
                <Input
                  text="Telefone 2"
                  name="second_phone"
                  register={{ ...register('second_phone') }}
                  placeholder="Insira o outro telefone do responsável"
                  errors={errors?.second_phone}
                  onKeyUp={handleKeyUpPhone}
                />
              </div>

              <div className={styles.double_input}>
                <Input
                  text="Endereço"
                  name="address"
                  register={{ ...register('address') }}
                  placeholder="Insira o endereço"
                  errors={errors?.address}
                />
                <Input
                  text="CEP"
                  name="cep"
                  register={{ ...register('cep') }}
                  placeholder="Insira o cep"
                  errors={errors?.cep}
                  onKeyUp={handleKeyUpCep}
                />
              </div>
            </section>
          </section>

          <section className={styles.actions}>
            <CustomerSelect
              title="Status"
              options={[
                { text: 'Pendente', value: 'pending' },
                { text: 'Em processo', value: 'in_processing' },
                { text: 'Cancelado', value: 'canceled' },
                { text: 'Reprovado', value: 'reproved' },
                { text: 'Aprovado', value: 'approved' },
              ]}
              name="Status"
              value={stateStudent}
              onChange={(e) => setStatus(e.target.value)}
            />
            <FooterButtons right_label="Salvar" redirectLink={`/aluno`} />
          </section>
        </form>

        <CoursesByStudent />
      </div>
    </>
  )
}
