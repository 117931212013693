import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './styles.module.scss'
import { Institutions } from '../../admin/entities/institution.entity'
import { ApiImage } from '../../../components/ApiImage'
import { InstitutionRepository } from '../../admin/repositories/instituicao.repository'

interface IProps {
  category: string
}

export const Scholarships = ({ category }: IProps) => {
  const [institutions, setInstitutions] = useState<Institutions[]>([])
  const navigate = useNavigate()

  const categoryParagraph =
    {
      '2': 'Escolas com bolsa de estudos:',
      '3': 'Cursos com bolsa de estudos:',
      '4': 'Cursos com bolsa de estudos:',
      default: 'Faculdades com bolsa de estudos:',
    }[category || 'default'] || 'Faculdades com bolsa de estudos:'

  const fetchInstitutions = useCallback(async () => {
    setInstitutions([])

    const _institutions = await InstitutionRepository.GetInstance().listRandom(
      category,
    )

    const institutions_shuffled = _institutions
      .filter((i) => !!i)
      .sort(() => 0.5 - Math.random())
    setInstitutions(institutions_shuffled.slice(0, 10))
  }, [category])

  useEffect(() => {
    fetchInstitutions()
  }, [])

  return (
    <div className={styles.bigSquare}>
      <p className={styles.title}>{categoryParagraph}</p>
      <div className={styles.partners_school_container}>
        {institutions.map((institution) => {
          const url = `/cursos/${institution.titulo}/${institution.id}/${category}`
          return (
            <div className={styles.partners_school}>
              <div className={styles.image_container}>
                <ApiImage width={128} url={institution?.url_imagem} />
              </div>
              <p>{institution.titulo}</p>
              <button onClick={() => navigate(url)}>Ver Cursos</button>
            </div>
          )
        })}
      </div>
    </div>
  )
}
