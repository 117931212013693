import { useState } from 'react';
import { toast } from 'react-toastify';

import { useValidateSchema } from '../../../../hooks/useSchemaValidator';
import {
  capitalizeFormDataSchema,
  CapitalizeFormDataSchemaType,
} from '../../../../utils/schemas/capitalizeFormDataSchema';
import { sendEmailService } from '../../../../services/send-email/send-mail';
import { handleKeyUpPhone } from '../../../../utils/formatting/phoneMask';

import styles from './styles.module.scss';

import { Input } from '../../../../components/Input';
import { Button } from '../../../../components/Button';
import { Checkbox } from '../../../../components/Checkbox';

export const FormCapitalize = () => {
  const [loading, setLoading] = useState(false);
  const [showScholarship, setShowScholarship] = useState(false);

  const { register, errors, handleSubmit, reset } = useValidateSchema(
    capitalizeFormDataSchema as unknown as CapitalizeFormDataSchemaType,
  );

  const treatmentError = () =>
    toast('Ocorreu algum erro, tente novamente.', { type: 'error' });

  const success = (name: string) => {
    toast(`Obrigado, ${name}, seus dados foram enviados com sucesso.`, {
      type: 'success',
    });
    reset();
    setShowScholarship(false);
  };

  const handleOnSubmit = async (dataForm: CapitalizeFormDataSchemaType) => {
    setLoading(true);

    const [, error] = await sendEmailService({
      subject: `Solicitação de Notificações - ${dataForm.name}`,
      to: process.env.REACT_APP_USER_EMAIL,
      variables: dataForm,
    });

    if (error) {
      treatmentError();
      return;
    }

    success(dataForm.name);
    setLoading(false);
  };

  const handleToggleNotFoundScholarship = () =>
    setShowScholarship((state) => !state);

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className={styles.container_form}
    >
      <Input
        text='Nome Completo'
        name='name'
        register={{ ...register('name') }}
        placeholder='Insira o seu nome'
        errors={errors?.name}
      />
      <Input
        text='E-mail'
        name='email'
        register={{ ...register('email') }}
        placeholder='Insira o seu melhor e-mail'
        errors={errors?.email}
      />
      <Input
        text='WattsApp'
        name='phone'
        register={{ ...register('phone') }}
        placeholder='Insira o seu wattsApp'
        errors={errors?.phone}
        onKeyUp={handleKeyUpPhone}
      />
      <Checkbox
        right_text='Aceito receber notificações por e-mail.'
        name='notification_by_email'
        register={{ ...register('notification_by_email') }}
        customizedStyles={styles.toggle}
      />
      <Checkbox
        right_text='Aceito receber notificações por whatsApp.'
        register={{ ...register('notification_by_phone') }}
        name='notification_by_phone'
        customizedStyles={styles.toggle}
      />
      <Checkbox
        right_text='Não encontrei a bolsa.'
        name='not_found'
        register={{ ...register('not_found') }}
        customizedStyles={styles.toggle}
        onClick={handleToggleNotFoundScholarship}
      />
      {showScholarship && (
        <Input
          text='Bolsas'
          name='scholarship'
          register={{ ...register('scholarship') }}
          placeholder='Insira quais bolsas deseja...'
          errors={errors?.scholarship}
        />
      )}
      <Button
        isPrimary
        labelText={loading ? 'Enviando...' : 'Enviar'}
        type='submit'
        disabled={loading}
      />
    </form>
  );
};
