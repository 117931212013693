import { IListIInstitutions } from '@/domain/contracts/institutions'
import api, { HttpResponse, treatmentForErrors } from '../api'
import { IInstitution } from '@/domain/entities/institution'

export class ListInstitutionsService implements IListIInstitutions {
  async loadAll(): Promise<HttpResponse<IInstitution[], Error>> {
    const request = api.get('/institutions')
    return treatmentForErrors(request)
  }
}
