import styles from './styles.module.scss';

interface IProps {
  step: 'step_1' | 'step_2' | 'step_3' | 'step_4';
}

export function Statusbar({ step }: IProps) {
  return (
    <div className={`${styles[`statusbar_${step}`]} ${styles.statusbar}`}>
      <div />
    </div>
  );
}
