import api from '../../../services/api';
import { Instituicao } from '../entities/instituicao.entity';
import { BaseRepository } from './base.repository';

export class InstitutionRepository extends BaseRepository {
  private static Instance: InstitutionRepository = null;
  public static GetInstance() {
    const instance = this.Instance
      ? this.Instance
      : new InstitutionRepository();
    return instance;
  }

  async listRandom(category_id: string): Promise<Instituicao[]> {
    const { data } = await api.get<{ instituicoes: Instituicao[] }>(
      `/instituicao/categoria/${category_id}`,
    );
    return data.instituicoes;
  }

  async list(): Promise<Instituicao[]> {
    const response = await api.get('/instituicao');
    return response.data.instituicoes as Instituicao[];
  }

  async findByName(name: string): Promise<Instituicao[]> {
    const headers = { Authorization: `Bearer ${this.token}` };
    const response = await api.get('/instituicao', {
      params: {
        name,
      },
      headers,
    });
    return response.data.instituicoes as Instituicao[];
  }

  async listCidades(idcategoria: string): Promise<string[]> {
    const headers = { Authorization: `Bearer ${this.token}` };
    const response = await api.get('/instituicao/cidades/' + idcategoria, {
      headers,
    });
    return response.data.cidades;
  }

  async listBairros(idcategoria: string, cidade: string): Promise<string[]> {
    const response = await api.get(
      `/instituicao/${idcategoria}/${cidade}/bairros`,
    );

    return response.data;
  }

  async listImagens(idinstituicao: string) {
    const response = await api.get(`/instituicao/${idinstituicao}/imagens`);
    return response.data.imagens;
  }

  async getCursoById(id: number) {
    const headers = { Authorization: `Bearer ${this.token}` };
    const response = await api.get('/curso/' + id, {
      headers,
    });

    return response.data.curso;
  }

  async adminList(token: string) {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await api.get('/admin/instituicoes', {
      headers,
    });

    return response.data.instituicoes;
  }

  async adminShowById(token: string, id: string) {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await api.get('/admin/instituicoes/' + id, {
      headers,
    });

    return response.data.instituicao;
  }

  async adminCreate(token: string, data: any) {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await api.post('/admin/instituicoes', data, {
      headers,
    });

    return response.data.instituicao;
  }

  async adminShowCourses(token: string, id: string) {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await api.get('/admin/instituicoes/' + id + '/cursos', {
      headers,
    });

    return response.data;
  }

  async adminShowCourse(token: string, id: string) {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await api.get('/admin/cursos/' + id, {
      headers,
    });

    return response.data;
  }

  async adminAtualizarCursoAtivado(token: string, id: number, idcurso: number) {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await api.put(
      '/admin/instituicoes/' + id + '/cursos/' + idcurso + '/ativar',
      {},
      {
        headers,
      },
    );

    return response.data;
  }

  async updateInstituicao(token: string, id: number, data: any) {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await api.put(
      '/admin/instituicoes/' + id,
      { data },
      {
        headers,
      },
    );

    return response.data.instituicao;
  }

  async uploadImage(token: string, id: string, file: File) {
    const formData = new FormData();
    formData.append('icone', file);

    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    };
    const response = await api.put(
      '/admin/instituicoes/' + id + '/icone',
      formData,
      {
        headers,
      },
    );

    return response.data.instituicao;
  }

  async adminAdicionarCurso(token: string, instituicaoid: string, data: any) {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await api.post(
      '/admin/instituicoes/' + instituicaoid + '/curso',
      {
        data,
      },
      {
        headers,
      },
    );

    return response.data;
  }

  async adminDeletarCurso(
    token: string,
    instituicaoid: string,
    cursoid: string,
  ) {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await api.delete(
      '/admin/instituicoes/' + instituicaoid + '/cursos/' + cursoid,
      {
        headers,
      },
    );

    return response.data;
  }

  async adminAtualizarCurso(token: string, idcurso: string, curso: any) {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await api.put(
      '/admin/cursos/' + idcurso,
      {
        data: curso,
      },
      {
        headers,
      },
    );

    return response.data.curso;
  }

  async listarInstituicoes(
    idcategoria: number,
    cidade?: string,
    bairro?: string,
  ) {
    const headers = {
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    const response = await api.get('/instituicao', {
      headers,
      params: {
        idcategoria,
        cidade,
        bairro,
      },
    });

    return response.data;
  }

  async listarImagensDaInstituicao(token: string, instituicaoid: string) {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await api.get(
      '/admin/instituicoes/' + instituicaoid + '/imagens',
      {
        headers,
      },
    );

    return response.data.instituicoes_imagems;
  }

  async adicionarFotoAInstituicao(
    token: string,
    idinstituicao: string,
    file: File,
  ) {
    const formData = new FormData();
    formData.append('imagem', file);

    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    };
    const response = await api.post(
      '/admin/instituicoes/' + idinstituicao + '/imagens',
      formData,
      {
        headers,
      },
    );

    return response.data.instituicoes_imagem;
  }

  async removerFotoAInstituicao(token: string, id: string) {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    };
    const response = await api.delete('/admin/instituicoes/imagens/' + id, {
      headers,
    });

    return response.data.imagem;
  }
}
