import { z } from 'zod';

export const friendFormDataSchemaWithEmail = z.object({
  name: z
    .string()
    .nonempty({ message: 'O nome é obrigatório' })
    .min(3, { message: 'O nome precisa de ter no mínimo 3 caracteres' }),
  cpf: z
    .string()
    .nonempty({ message: 'O cpf é obrigatório' })
    .min(14, { message: 'Formato de cpf inválido' }),
  telephone: z
    .string()
    .nonempty({ message: 'O telefone é obrigatório' })
    .min(10, { message: 'Formato de telefone inválido' }),
  second_phone: z
    .string()
    .nonempty({ message: 'O telefone é obrigatório' })
    .min(10, { message: 'Formato de telefone inválido' }),
  email: z.string().nonempty({ message: 'O email é obrigatório' }).email(),
  pix_key: z
    .string()
    .email()
    .nonempty({ message: 'Informar a chave é obrigatório' }),
});

export const friendFormDataSchemaWithPhone = z.object({
  name: z
    .string()
    .nonempty({ message: 'O nome é obrigatório' })
    .min(3, { message: 'O nome precisa de ter no mínimo 3 caracteres' }),
  cpf: z
    .string()
    .nonempty({ message: 'O cpf é obrigatório' })
    .min(14, { message: 'Formato de cpf inválido' }),
  telephone: z
    .string()
    .nonempty({ message: 'O telefone é obrigatório' })
    .min(10, { message: 'Formato de telefone inválido' }),
  second_phone: z
    .string()
    .nonempty({ message: 'O telefone é obrigatório' })
    .min(10, { message: 'Formato de telefone inválido' }),
  email: z
    .string()
    .nonempty({ message: 'O email é obrigatório' })
    .email({ message: 'Email inválido' }),
  pix_key: z
    .string()
    .nonempty({ message: 'O telefone é obrigatório' })
    .min(10, { message: 'Formato de telefone inválido' }),
});

export const friendFormDataSchemaWithCPF = z.object({
  name: z
    .string()
    .nonempty({ message: 'O nome é obrigatório' })
    .min(3, { message: 'O nome precisa de ter no mínimo 3 caracteres' }),
  cpf: z
    .string()
    .nonempty({ message: 'O cpf é obrigatório' })
    .min(14, { message: 'Formato de cpf inválido' }),
  telephone: z
    .string()
    .nonempty({ message: 'O telefone é obrigatório' })
    .min(10, { message: 'Formato de telefone inválido' }),
  second_phone: z
    .string()
    .nonempty({ message: 'O telefone é obrigatório' })
    .min(10, { message: 'Formato de telefone inválido' }),
  email: z.string().nonempty({ message: 'O email é obrigatório' }).email(),
  pix_key: z
    .string()
    .nonempty({ message: 'O cpf é obrigatório' })
    .min(14, { message: 'Formato de cpf inválido' })
    .max(14, { message: 'Formato de cpf inválido' }),
});

export type FriendFormDataSchemaTypeCPF = z.infer<
  typeof friendFormDataSchemaWithCPF
>;
export type FriendFormDataSchemaTypeEmail = z.infer<
  typeof friendFormDataSchemaWithEmail
>;
export type FriendFormDataSchemaTypePhone = z.infer<
  typeof friendFormDataSchemaWithPhone
>;
export type FriendFormDataSchemaType =
  | FriendFormDataSchemaTypeCPF
  | FriendFormDataSchemaTypeEmail
  | FriendFormDataSchemaTypePhone;
