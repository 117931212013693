import api from '../../../services/api';
import { IRegisterStudent, IResponse } from '../../../interfaces/types';
import { Aluno } from '../entities/aluno.entity';

export class AlunoRepository {
  token?: string;

  setToken(_token?: string) {
    this.token = _token;
  }

  private static Instance: AlunoRepository = null;
  public static GetInstance() {
    if (this.Instance == null) {
      this.Instance = new AlunoRepository();
    }

    return this.Instance;
  }

  async list(
    token: string,
    params: { page: number; perPage: number },
  ): Promise<IResponse<Aluno>> {
    const headers = { Authorization: `Bearer ${token}` };

    const { data } = await api.get<{ students: IResponse<Aluno> }>(
      '/admin/students',
      {
        headers,
        params,
      },
    );

    return data.students;
  }

  async findById(token: string): Promise<Aluno> {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await api.get('/aluno/consultar', {
      headers,
    });

    return response.data as Aluno;
  }

  async adminFindById(token: string, id: string) {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await api.get('/admin/alunos/consultar/id/' + id, {
      headers,
    });

    return response.data;
  }

  async findByProperty(
    token: string,
    property: string,
    value: string,
  ): Promise<Aluno[]> {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await api.get('/admin/alunos/' + property + '/' + value, {
      headers,
    });

    return response.data.alunos as Aluno[];
  }
  async save(entity: Aluno): Promise<Aluno> {
    throw new Error('Method not implemented.');
  }

  async cadastrar(cadastro: IRegisterStudent) {
    const response = await api.post('/aluno', cadastro);

    return response;
  }

  async documentos(token: string) {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await api.get('/aluno/documentos', {
      headers,
    });

    return response.data;
  }

  async adminListDocumentos(token: string, idaluno: string) {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await api.get(`/admin/aluno/${idaluno}/documentos`, {
      headers,
    });

    return response.data.documentos;
  }

  async adminDeleteDocumento(token: string, iddocumento: string) {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await api.delete(`/admin/documentos/${iddocumento}`, {
      headers,
    });

    return response.data.documento;
  }

  async adminAddDocumento(
    token: string,
    aluno_id: string,
    file: File,
    type: string,
  ) {
    const formData = new FormData();
    formData.append('arquivo', file);

    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    };

    const response = await api.post(
      `/admin/aluno/documento/anexar/${aluno_id}/${type}`,
      formData,
      {
        headers,
      },
    );

    return response.data.documento;
  }

  async atualizar(token: string, data: any) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await api.put(
      '/aluno/atualizar',
      { data },
      {
        headers,
      },
    );

    return response.data;
  }
}
