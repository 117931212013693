import { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import styles from './login.module.scss';

export function LoginInstituicao() {
  const { signIn } = useAuth();
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  async function handleLogin() {
    await signIn({ login, password, role: 'instituicao' });

    navigate('/perfil');
  }

  return (
    <div className={styles.background}>
      <div className={styles.backgroundTwo}>
        <div className={styles.column}>
          <img src='/logo posso.png'></img>
          <div className={styles.text2}>
            <p>Entrar - Instituição</p>
          </div>
          <div className={styles.text}>
            <p>Email da instituicao</p>
          </div>
          <input
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            placeholder='Email...'
          ></input>
          <div className={styles.text}>
            <p>Senha</p>
          </div>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder='Senha...'
          ></input>
          <div className={styles.bar}></div>
          <button onClick={handleLogin}>Entrar</button>
        </div>
        <div className={styles.columnTwo}>
          <img src='/login-illustrator.png'></img>
        </div>
      </div>
    </div>
  );
}
