export const handleKeyUpDate = (e?: React.FormEvent<HTMLInputElement>) => {
  if (!e) return (e.currentTarget.value = '');

  e.currentTarget.maxLength = 10;
  let value = e.currentTarget.value;

  value = value.replace(/\D/g, '');
  const length = value.length;

  if (length >= 2 && length <= 3) value = value.replace(/^(\d{2})/, '$1/');
  if (length >= 4 && length <= 5)
    value = value.replace(/^(\d{2})(\d{2})/, '$1/$2/');
  if (length === 6) value = value.replace(/^(\d{2})(\d{2})(\d{1})/, '$1/$2/$3');
  if (length >= 7 && length <= 8)
    value = value.replace(/^(\d{2})(\d{2})(\d{3})/, '$1/$2/$3');

  e.currentTarget.value = value;
};

export const removeKeyUpValues = (e: React.FormEvent<HTMLInputElement>) => {
  e.currentTarget.value = '';
};

export const datePattern = '[0-3][0-9]/[0-1][0-9]/[1-2][0-9][0-9][0-9]';

export const formatDate = (date: string) =>
  formatBrDate(date) !== 'Data Inválida' ? formatBrDate(date) : '01/01/1900';

export const formatBrDate = (value: string) => {
  const newDate = new Date(value);

  if (newDate.toString() !== 'Invalid Date')
    return new Intl.DateTimeFormat('pt-BR', {
      timeZone: 'UTC',
    }).format(newDate);

  const date = stringToDate(value);

  if (!date || date?.toString() !== 'Invalid Date')
    return new Intl.DateTimeFormat('pt-BR', {
      timeZone: 'UTC',
    }).format(date);

  return 'Data não informada';
};

export const stringToDate = (input: string): Date | undefined => {
  if (!input) return;
  const dateParts = typeof input === 'string' && input?.split('/');
  const dateObject = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
  return dateObject;
};
