import { useState } from 'react';
import styles from './styles.module.scss';
import { InstitutionRepository } from '../../../../repositories/instituicao.repository';
import { TypeState } from '..';
import { ICourse } from '../seeCourses';
import { useAuth } from '../../../../../../hooks/useAuth';
import { useParams } from 'react-router-dom';

interface IAddCourse {
  idcategoria: number;
  titulo: string;
  turno: string;
  modalidade: string;
  anobolsa: number;
  descricoes: string;
  valor: string;
  valorpromocional: string;
  vagas: number;
  percentual: string;
  status: 'Ativo';
  excluido: 'nao';
}

interface IProps {
  setState: React.Dispatch<React.SetStateAction<TypeState>>;
  courses: ICourse[];
  setCourses: React.Dispatch<React.SetStateAction<ICourse[]>>;
}

export const AddCourse = ({ setCourses, setState, courses }: IProps) => {
  const { token } = useAuth();
  const { id } = useParams();
  const [course, setCourse] = useState<IAddCourse>({
    anobolsa: 2023,
    descricoes: '',
    excluido: 'nao',
    idcategoria: 2,
    modalidade: 'PRESENCIAL',
    percentual: '',
    status: 'Ativo',
    titulo: '',
    turno: 'MANHA',
    vagas: 5,
    valor: '',
    valorpromocional: '',
  });

  async function addCourse() {
    let hasNull = false;
    Object.keys(course).forEach((i) => {
      if (!course[i] && i != 'descricoes') {
        hasNull = true;
      }
    });

    if (hasNull) {
      return;
    }

    const novoCurso =
      await InstitutionRepository.GetInstance().adminAdicionarCurso(
        token,
        id,
        course,
      );
    setCourses([novoCurso, ...courses]);
    setState('CURSOS');
  }

  return (
    <div className={styles.form_container}>
      <select
        onChange={(e) =>
          setCourse({ ...course, idcategoria: Number(e.target.value) })
        }
      >
        <option value={'2'}>Ensino Básico</option>
        <option value={'3'}>Técnico</option>
        <option value={'4'}>Profissionalizante</option>
        <option value={'5'}>EJA</option>
        <option value={'6'}>Idiomas</option>
        <option value={'7'}>Pré-Vestibular / Preparatório</option>
        <option value={'8'}>Infantil</option>
        <option value={'9'}>Período Integral</option>
      </select>
      <select
        onChange={(e) => setCourse({ ...course, modalidade: e.target.value })}
      >
        <option value={'PRESENCIAL'}>PRESENCIAL</option>
        <option value={'EAD'}>EAD</option>
      </select>
      <select onChange={(e) => setCourse({ ...course, turno: e.target.value })}>
        <option value={'MANHA'}>MANHA</option>
        <option value={'TARDE'}>TARDE</option>
        <option value={'NOITE'}>NOITE</option>
      </select>
      <input
        onChange={(e) => setCourse({ ...course, titulo: e.target.value })}
        placeholder='titulo'
        value={course?.titulo}
      ></input>
      <input
        onChange={(e) =>
          setCourse({ ...course, anobolsa: Number(e.target.value) })
        }
        placeholder='anobolsa'
        value={course?.anobolsa}
      ></input>
      <input
        onChange={(e) =>
          setCourse({ ...course, vagas: Number(e.target.value) })
        }
        placeholder='vagas'
        value={course?.vagas}
      ></input>
      <input
        onChange={(e) => setCourse({ ...course, descricoes: e.target.value })}
        placeholder='descricoes'
        value={course?.descricoes}
      ></input>
      <input
        onChange={(e) => setCourse({ ...course, valor: e.target.value })}
        placeholder='valor'
        value={course?.valor}
      ></input>
      <input
        onChange={(e) =>
          setCourse({ ...course, valorpromocional: e.target.value })
        }
        placeholder='valorpromocional'
        value={course?.valorpromocional}
      ></input>
      <input
        onChange={(e) => setCourse({ ...course, percentual: e.target.value })}
        placeholder='percentual'
        value={course?.percentual}
      ></input>
      <button onClick={addCourse}>Adicionar Curso</button>
    </div>
  );
};
