import { z } from 'zod';

export const blogFormDataSchema = z.object({
  title: z
    .string()
    .nonempty({ message: 'Campo obrigatório' })
    .min(3, { message: 'O campo precisa de ter no mínimo 3 caracteres' }),
  content: z
    .string()
    .nonempty({ message: 'Campo obrigatório' })
    .min(3, { message: 'O campo precisa de ter no mínimo 3 caracteres' }),
  image_url: z.string().nonempty({ message: 'Campo obrigatório' }),
  url: z.string().nonempty({ message: 'Campo obrigatório' }),
});

export type BlogFormDataSchemaType = z.infer<typeof blogFormDataSchema>;
export type UpdateBlogFormDataSchemaType = BlogFormDataSchemaType & {
  id: string;
};
