import { ButtonHTMLAttributes } from 'react';
import styles from './styles.module.scss';

export interface IPropsButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  labelText: string;
  isPrimary?: boolean;
}

export function Button({
  labelText,
  isPrimary = false,
  ...props
}: IPropsButton) {
  return (
    <div className={!isPrimary ? styles.default_button : styles.second_button}>
      <button {...props}>{labelText}</button>
    </div>
  );
}
