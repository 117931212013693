import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Money } from 'phosphor-react';

import styles from './styles.module.scss';

import api from '../../../../services/api';

import { IFriends, IResponse } from '../../../../interfaces/types';

import { Loader } from '../../../../components/Loader';
import Tooltip from '../../../../components/Tooltip';
import { Modal } from '../../../../components/Modal';
import { FormPaymentPE } from './formAddPayment';
import { Pagination } from '../../../../components/Pagination';
import { SelectPerPage } from '../../../../components/Pagination/Select';

import { formatMoney } from '../../../../utils/formatting/money';
import { PE_INDICATOR_VALUE } from '../../../../constants';
import { FriendsRepository } from '../../repositories/friends.repository';
import { useAuth } from '../../../../hooks/useAuth';

export function ShowPEFriends() {
  const [response, setResponse] = useState({} as IResponse<IFriends>);
  const [dataFriend, setDataFriend] = useState({} as IFriends);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const { count, data: friends } = response;
  const { token } = useAuth();

  const getFriends = useCallback(async () => {
    const data = await FriendsRepository.GetInstance().list(token, {
      page: currentPage,
      perPage,
    });
    setResponse(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    getFriends();
  }, []);

  const handleOpenModal = (data: IFriends) => {
    setDataFriend(data);
    setOpenModal(true);
  };

  const handleRegister = async (dataForm) => {
    setLoading(true);
    const formattedData = { ...dataForm, friend_pe_id: dataFriend.id };
    const { data } = await api.post('/payments', formattedData);

    if (!data?.error) {
      const { data }: any = await api.get(
        `friends/by-code?code=${dataFriend.code}`,
      );

      if (!data?.friend) return toast('Código PE errado.', { type: 'error' });

      const { friend } = data;

      await api.put('/friends', {
        ...friend,
        total_receivable: friend.total_receivable
          ? friend.total_receivable - +dataForm.amount_quantity
          : 0,
        total_received: friend.total_received
          ? friend.total_received + +dataForm.amount_quantity
          : +dataForm.amount_quantity,
      });
    }
    toast('Comprovante salvo com sucesso!');
    await getFriends();
    setOpenModal(false);
    setLoading(false);
  };

  return (
    <div className={styles.list_pe}>
      <h1>Administrador - Amigos PE</h1>

      <SelectPerPage perPage={perPage} setPerPage={setPerPage} />

      {loading && <Loader />}

      {openModal && (
        <Modal.Root title='Registrar pagamento' content=''>
          <FormPaymentPE
            setOpenModal={setOpenModal}
            handleOnSubmit={handleRegister}
          />
        </Modal.Root>
      )}

      <table className={styles.table_container}>
        <thead>
          <tr>
            <td>Nome</td>
            <td>Código</td>
            <td>Email</td>
            <td>Chave</td>
            <td>Telefone</td>
            <td>Telefone 2</td>
            <td>Quant.PE</td>
            <td>A receber</td>
            <td>Recebido</td>
            <div className={styles.actions}>Ações</div>
          </tr>
        </thead>
        {friends?.length > 0 ? (
          friends.map((friend) => {
            const totalReceivable =
              friend.total_receivable * PE_INDICATOR_VALUE;
            const totalReceived = friend.total_received * PE_INDICATOR_VALUE;

            return (
              <tr>
                <td>{friend.name}</td>
                <td>{friend.code}</td>
                <td>{friend.email}</td>
                <td>{friend.chave_pix}</td>
                <td>{friend.phone}</td>
                <td>{friend.second_phone}</td>
                <td style={{ textAlign: 'center' }}>
                  {friend.total_receivable}
                </td>
                <td>{formatMoney(`${totalReceivable},0`)}</td>
                <td>{formatMoney(`${totalReceived},0`)}</td>
                <div className={styles.actions}>
                  <Tooltip title='Pagar'>
                    <Money onClick={() => handleOpenModal(friend)} size={24} />
                  </Tooltip>
                </div>
              </tr>
            );
          })
        ) : (
          <p>Nenhum aluno encontrado</p>
        )}
      </table>

      <Pagination
        count={count}
        page={currentPage}
        perPage={perPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}
