import { Outlet } from 'react-router-dom'
import { Info } from '../components/wpp'
import { Footer } from '../components/FooterPage'
import { Header } from '../components/header'

export function Layout() {
  return (
    <>
      <Header />
      <Info />
      <Outlet />
      <Footer />
    </>
  )
}
