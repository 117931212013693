import { useParams } from 'react-router-dom'
import { IListCourses } from '../../domain/contracts/courses'
import { useFormatOptions } from '../../hooks/use-format-options'
import { useQuery } from '@tanstack/react-query'
import { Course } from '@/domain/entities/course'
import { IInstitution } from '@/domain/entities/institution'
import { useCallback, useEffect, useState } from 'react'

type ListACoursesProps = {
  listCourseService: IListCourses
}

export function useListCourses({ listCourseService }: ListACoursesProps) {
  const { formatOptions } = useFormatOptions()
  const { id: studentId } = useParams()
  const [courses, setCourses] = useState<Course[]>([])

  const { data: dataCourses, isLoading: isLoadingCourses } = useQuery({
    queryKey: ['list_courses'],
    initialData: [{ courses: [] }, null],
    queryFn: listCourseService.loadAll,
  })

  const filterCoursesByInstitution = useCallback(
    (id: number) => {
      const [data] = dataCourses
      const coursesFiltered =
        data?.courses?.filter((course) => course.idinstituicao === id) || []
      setCourses(coursesFiltered)
    },
    [dataCourses],
  )

  const { data: coursesResponse } = useQuery({
    queryKey: ['list_course_by_student_id'],
    initialData: [
      {
        data: {
          courses: {} as Course,
          institutions: {} as IInstitution,
          registrations: [] as Course[],
        },
      },
      null,
    ],
    queryFn: () => listCourseService.loadByStudentId(+studentId),
  })

  const courseOptions = formatOptions(courses, 'titulo')

  const [enrolled] = coursesResponse

  const enrolledCourses = enrolled?.data?.courses
  const registrations = enrolled?.data?.registrations
  const institution = enrolled?.data?.institutions

  useEffect(() => {
    filterCoursesByInstitution(+institution?.id)
  }, [filterCoursesByInstitution])

  return {
    courseOptions,
    institution,
    registrations,
    courses,
    isLoadingCourses,
    enrolledCourses,
    filterCoursesByInstitution,
  }
}
