import { Link } from 'react-router-dom';

// styles
import styles from './styles.module.scss';

//components
import { FiPhoneCall } from 'react-icons/fi';
import { Button } from '../../../../components/Button';

export function InterestedPartnerSuccess() {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <h1>Cadastro realizado com sucesso!</h1>
          <p>
            Aguarde que nossa equipe entrará em contato para efetivar a parceria
          </p>
          <p>
            <FiPhoneCall></FiPhoneCall> (85)98210-6409
          </p>
          <p>
            <FiPhoneCall></FiPhoneCall> (85)21806407
          </p>
          <Link className={styles.back_button} to='/'>
            <Button labelText='Voltar para a Home' />
          </Link>
        </div>
      </div>
    </>
  );
}
