import { ButtonHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';
import { Button } from '../Button';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  labelText: string;
  link: string;
  isPrimary?: boolean;
}

export function LinkButton({
  labelText,
  link,
  isPrimary = false,
  ...props
}: IProps) {
  return (
    <Link
      to={link}
      className={isPrimary ? styles.default_button : styles.second_button}
    >
      <Button labelText={labelText} {...props} />
    </Link>
  );
}
