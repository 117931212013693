import styles from './who.module.css';

export function Who() {
  return (
    <div className={styles.background}>
      <h2>O que é o Posso Estudar Educação</h2>
      <p>
        Com o objetivo de auxiliar estudantes que por quaisquer motivos não
        possam arcar com a mensalidade integral de instituições privadas de
        ensino, a empresa foi introduzida ao mercado para democratizar a
        educação no país. Nossa plataforma foi desenvolvida em 2019, visando
        formar o caráter do ser humano e cooperar com a educação brasileira.
      </p>
      <p>
        Em nosso site, o aluno poderá encontrar diversas opções de colégios,
        cursos e universidades com descontos de até 50% para investir no futuro
        da melhor maneira possível. Para se matricular, basta adquirir todas as
        informações por intermédio do nosso site e concretizar a matrícula sem
        burocracia e preocupação alguma. Vale lembrar que, pensando em você,
        estudante parceiro, desenvolvemos diversas parcerias com instituições do
        universo educacional para que você esteja sempre evoluindo e adquirindo
        cada vez mais conhecimento para valorizar o seu futuro.
      </p>
      <div>
        <p>
          <h4>Missão:</h4> A nossa missão é proporcionar uma educação
          qualificada aos estudantes que não possam manter valores de
          mensalidade integral para investir no futuro.
        </p>
        <p>
          <h4>Visão:</h4> A nossa visão é desenvolver uma prestação de serviços
          que, em longo prazo, possa auxiliar jovens e crianças a realizarem o
          sonho de conquistar uma educação digna, além de um espaço futuro no
          mercado de trabalho, tornando-nos, assim, referência em descontos de
          mensalidade.
        </p>
        <p>
          <h4>Valores:</h4> Os principais valores que norteiam a nossa empresa
          são ética, integridade e democratização social. Por intermédio de uma
          educação digna para os jovens e crianças do país, entendemos que é
          possível tornar a nossa sociedade mais justa e igualitária.
        </p>
      </div>
    </div>
  );
}
