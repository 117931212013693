import { useState } from 'react';
import { Input } from '../../../../../components/Input';
import { Modal } from '../../../../../components/Modal';

import { useValidateSchema } from '../../../../../hooks/useSchemaValidator';
import { handleKeyUpMoney } from '../../../../../utils/formatting/money';
import {
  PaymentPEFormDataSchemaType,
  paymentFormDataSchema,
} from '../../../../../utils/schemas/paymentPEFormDataSchema';

import styles from './styles.module.scss';
import { PE_INDICATOR_VALUE } from '../../../../../constants';

interface IProps {
  handleOnSubmit: any;
  setOpenModal: any;
}

export function FormPaymentPE({ handleOnSubmit, setOpenModal }: IProps) {
  const [defaultValues, setDefaultValues] = useState({} as any);
  const { register, errors, handleSubmit } = useValidateSchema(
    paymentFormDataSchema as unknown as PaymentPEFormDataSchemaType,
  );

  const onChangeInput = (e) => {
    const value = e.target.value;
    setDefaultValues({
      amount: `R$ ${value * PE_INDICATOR_VALUE}`,
    });
  };

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className={styles.double_input}>
        <Input
          text='Nº do recibo'
          name='receipt_number'
          register={{ ...register('receipt_number') }}
          placeholder='Insira o nº do recibo'
          errors={errors?.receipt_number}
        />

        <Input
          text='Quantidade paga'
          name='amount_quantity'
          type='number'
          register={{ ...register('amount_quantity') }}
          placeholder='Indicações pagas'
          errors={errors?.amount_quantity}
          onChange={onChangeInput}
        />
      </div>

      <div className={styles.double_input}>
        <Input
          value={`R$ ${PE_INDICATOR_VALUE},00`}
          text='Valor de cada AMIGO PE'
          name='amount_receivable'
          disabled
        />
        <Input
          text='Valor pago'
          value={defaultValues.amount}
          name='amount'
          onKeyUp={handleKeyUpMoney}
          disabled
          placeholder='R$ 0,00'
        />
      </div>

      <Modal.ButtonRoot>
        <Modal.Button
          labelText='Voltar'
          handleClickButton={() => setOpenModal(false)}
          type='button'
        />
        <Modal.Button labelText='Registrar' isPrimary type='submit' />
      </Modal.ButtonRoot>
    </form>
  );
}
