import { useAuth } from '../hooks/useAuth';
import styles from './perfil.module.scss';
import { AlunoPerfilPage } from './aluno.page';
import { StudentsRegistered } from './admin/pages/institutions/studentsRegistered';

export function PerfilPage() {
  const { aluno, instituicao } = useAuth();
  return (
    <div className={styles.main_container}>
      {aluno ? (
        <AlunoPerfilPage />
      ) : (
        <StudentsRegistered institution={instituicao} />
      )}
    </div>
  );
}
