import styles from './styles.module.scss';
import { Link } from 'react-router-dom';

export function PEfriend() {
  return (
    <div className={styles.background}>
      <div className={styles.title}>
        <h2>Amigo PE</h2>
        <p>E como você pode participar? É fácil:</p>
      </div>
      <p>
        <h4>Cadastre-se:</h4>preencha o formulário abaixo. Não leva mais do que
        alguns minutos.
      </p>
      <p>
        <h4>Receba Seu Código Exclusivo:</h4>Após o cadastro, você receberá um
        código exclusivo. Esse código é a sua chave para desbloquear suas
        recompensas.
      </p>
      <p>
        <h4>Compartilhe com Seus Amigos: </h4>Divulgue o seu código para amigos
        que possam estar interessados em nossas vagas escolares ou cursos. Eles
        usarão esse código no momento da do preenchimento do formulário de
        matrícula.
      </p>
      <p>
        <h4>Ganhe Recompensas:</h4>Toda vez que alguém se matricular com o seu
        código e finalizar o pagamento, você ganhará R$10,00 como forma de
        agradecimento.
      </p>
      <p>
        <h4>Receba Seus Ganhos:</h4>As recompensas serão creditadas em sua conta
        através de pix dentro de até 10 dias úteis após a confirmação do
        pagamento da matrícula.
      </p>
      <div className={styles.redirect}>
        <Link className={styles.link_button} to='/friend-pe'>
          Formulário Amigo PE
        </Link>
      </div>
    </div>
  );
}
