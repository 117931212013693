import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface IProps {
  content: string;
  title: string;
  children: ReactNode;
}

export function ModalRoot({ content, title, children }: IProps) {
  return (
    <div className={styles.container_modal}>
      <div className={styles.content_modal}>
        <h1>{title}</h1>
        {content && <span>{content}</span>}
        {children}
      </div>
    </div>
  );
}
