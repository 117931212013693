import { ICourse } from '../seeCourses';
import styles from './styles.module.scss';
import { InstitutionRepository } from '../../../../repositories/instituicao.repository';
import { useAuth } from '../../../../../../hooks/useAuth';
import { TypeState } from '..';

interface IProps {
  setState: React.Dispatch<React.SetStateAction<TypeState>>;
  editingCurso: ICourse;
  setEditingCurso: React.Dispatch<React.SetStateAction<ICourse>>;
}

export const UpdateCourse = ({
  setState,
  editingCurso,
  setEditingCurso,
}: IProps) => {
  const { token } = useAuth();

  async function updateCourse(course: ICourse) {
    await InstitutionRepository.GetInstance().adminAtualizarCurso(
      token,
      course.id.toString(),
      course,
    );
    setState('CURSOS');
  }

  return (
    <div className={styles.form_container}>
      <p>Editando course: {editingCurso?.titulo}</p>

      <select
        value={editingCurso.idcategoria}
        onChange={(e) =>
          setEditingCurso({
            ...editingCurso,
            idcategoria: Number(e.target.value),
          })
        }
      >
        <option value={'2'}>Ensino Básico</option>
        <option value={'3'}>Técnico</option>
        <option value={'4'}>Profissionalizante</option>
        <option value={'5'}>EJA</option>
        <option value={'6'}>Idiomas</option>
        <option value={'7'}>Pré-Vestibular / Preparatório</option>
        <option value={'8'}>Infantil</option>
        <option value={'9'}>Período Integral</option>
      </select>
      <select
        value={editingCurso.modalidade?.toUpperCase()}
        onChange={(e) =>
          setEditingCurso({ ...editingCurso, modalidade: e.target.value })
        }
      >
        <option value={''}>NAO TEM</option>
        <option value={'PRESENCIAL'}>PRESENCIAL</option>
        <option value={'EAD'}>EAD</option>
      </select>
      <select
        value={editingCurso.turno?.toUpperCase()}
        onLoad={(e) =>
          setEditingCurso({ ...editingCurso, turno: e.currentTarget.value })
        }
        onChange={(e) =>
          setEditingCurso({ ...editingCurso, turno: e.target.value })
        }
      >
        <option value={'MANHA'}>MANHA</option>
        <option value={'TARDE'}>TARDE</option>
        <option value={'NOITE'}>NOITE</option>
      </select>

      <select
        value={editingCurso.status?.toUpperCase()}
        onChange={(e) =>
          setEditingCurso({ ...editingCurso, status: e.target.value })
        }
      >
        <option value={''}>NAO TEM</option>
        <option value={'ATIVO'}>ATIVO</option>
        <option value={'BLOQUEADO'}>BLOQUEADO</option>
      </select>
      <input
        onChange={(e) =>
          setEditingCurso({ ...editingCurso, titulo: e.target.value })
        }
        placeholder='titulo'
        value={editingCurso?.titulo}
      ></input>
      <input
        onChange={(e) =>
          setEditingCurso({
            ...editingCurso,
            anobolsa: Number(e.target.value),
          })
        }
        placeholder='anobolsa'
        value={editingCurso?.anobolsa}
      ></input>
      <input
        onChange={(e) =>
          setEditingCurso({
            ...editingCurso,
            vagas: Number(e.target.value),
          })
        }
        placeholder='vagas'
        value={editingCurso?.vagas}
      ></input>
      <input
        onChange={(e) =>
          setEditingCurso({ ...editingCurso, descricoes: e.target.value })
        }
        placeholder='descricoes'
        value={editingCurso?.descricoes}
      ></input>
      <input
        onChange={(e) =>
          setEditingCurso({ ...editingCurso, valor: e.target.value })
        }
        placeholder='valor'
        value={editingCurso?.valor}
      ></input>
      <input
        onChange={(e) =>
          setEditingCurso({
            ...editingCurso,
            valorpromocional: e.target.value,
          })
        }
        placeholder='valorpromocional'
        value={editingCurso?.valorpromocional}
      ></input>
      <input
        onChange={(e) =>
          setEditingCurso({ ...editingCurso, percentual: e.target.value })
        }
        placeholder='percentual'
        value={editingCurso?.percentual}
      ></input>
      <button onClick={() => updateCourse(editingCurso)}>
        Atualizar Curso
      </button>
    </div>
  );
};
