import { CapitalizeFormDataSchemaType } from '../../utils/schemas/capitalizeFormDataSchema';
import api, { treatmentForErrors } from '../api';

export type InputEmailType = {
  to: string;
  subject: string;
  variables: CapitalizeFormDataSchemaType;
};

export const sendEmailService = (input: InputEmailType) => {
  const request = api.post('/email/send-email', input);
  return treatmentForErrors(request);
};
