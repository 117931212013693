import { ImageFieldProps } from 'react-admin';
import api from '../services/api';

interface IApiImageProps extends ImageFieldProps {
  url: string;
  width: number;
}

export function ApiImage({ url, width }: IApiImageProps) {
  return <img width={width} src={`${api.getUri()}/uploads/${url}`}></img>;
}
