import { useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel'

import 'bootstrap/dist/css/bootstrap.css'
import styles from './main.module.scss'

import { CursoRepository } from '../admin/repositories/curso.repository'
import { InstitutionRepository } from '../admin/repositories/instituicao.repository'

import { USE_TERMS } from '../../constants'
import { useCurso } from '../../hooks/useCurso'
import { UseTerms } from '../../components/UseTerms'
import { Storage } from '../../constants/Enum'

import { Modal } from '../../components/Modal'
import { Blog } from './blog'
import { Capitalize } from './captalize-form'
import { PeFriend } from './pe-friend'
import { Scholarships } from './scholarships'
import { PathInformation } from './path-information'
import { useNavigate } from 'react-router-dom'

interface IInstituicaoTitulo {
  id: number
  titulo: string
}

export default function MainPage() {
  const [cidades, setCidades] = useState<string[]>([])
  const [titulos, setTitulos] = useState<any[]>([])
  const [bairros, setBairros] = useState<string[]>([])
  const { pesquisa, selectTitulo } = useCurso()
  const [cidade, setCicade] = useState('')
  const [category, setCategory] = useState('2')
  const [bairro, setBairro] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [showTerms, setShowTerms] = useState(true)
  const [tipoSelected, setTipoSelected] = useState<'INST' | 'CURSO'>('INST')
  const [instituicoesTitulos, setInstituicoesTitulos] = useState<
    IInstituicaoTitulo[]
  >([])
  const [selectedInstituicao, setSelectedInstituicao] = useState<string>('-1')

  const acceptedUseTerms =
    localStorage.getItem(Storage.acceptedUseTerms) === 'accepted'
  const navigate = useNavigate()

  useEffect(() => {
    fetchData()
  }, [])

  function isEscola(): boolean {
    return (
      category == '2' || category == '5' || category == '8' || category == '9'
    )
  }

  async function fetchData() {
    fetchCidades()
  }

  async function fetchCidades() {
    const _cidades = await InstitutionRepository.GetInstance().listCidades(
      category,
    )
    setCidades([])
    setCidades((current) => [...current, ..._cidades])
  }

  async function fetchBairros(idcategoria: string, cidade: string) {
    const _bairros = await InstitutionRepository.GetInstance().listBairros(
      idcategoria,
      cidade,
    )
    setBairros(_bairros)
  }

  async function fetchTitulos(
    categoriaid: string,
    cidade: string,
    bairro: string,
  ) {
    if (tipoSelected == 'INST') {
      await fetchInstituicoesTitulos(categoriaid, cidade, bairro)
      return
    }

    if (!category || !cidade) return

    const _titulos =
      await CursoRepository.GetInstance().listTitulosByCidadeAndCategoria(
        Number(categoriaid),
        cidade,
        bairro,
      )
    const titulosMapped = _titulos.map((t) => t.titulo)
    setTitulos([])
    setTitulos((current) => [...current, ...titulosMapped])
    selectTitulo(titulosMapped[0])
  }

  async function fetchInstituicoesTitulos(
    categoriaid: string,
    cidade: string,
    bairro: string,
  ) {
    const inst_titulos =
      await InstitutionRepository.GetInstance().listarInstituicoes(
        Number(categoriaid),
        cidade,
        bairro,
      )
    setInstituicoesTitulos(inst_titulos)
  }

  function handleBuscar() {
    if (tipoSelected == 'CURSO') {
      if (!category || !cidade || !bairro || !pesquisa.titulo) return
      navigate(`/cursos/${category}/${cidade}/${bairro}/${pesquisa.titulo}`)
    } else {
      const instituicao = instituicoesTitulos.find(
        (i) => i.id.toString() == selectedInstituicao,
      )
      if (!selectedInstituicao || !instituicao) return
      navigate(
        `/cursos/${instituicao.titulo}/${selectedInstituicao}/${category}`,
      )
    }
  }

  const handleAcceptTerms = (accepted: boolean) => {
    const accept = accepted ? 'accepted' : 'noAccepted'
    localStorage.setItem(Storage.acceptedUseTerms, accept)
    setShowModal(false)
    setShowTerms(false)
  }

  return (
    <div className={styles.mainContainer}>
      {!acceptedUseTerms && showTerms && (
        <UseTerms
          openTerms={() => setShowModal(true)}
          handleAcceptTerms={handleAcceptTerms}
        />
      )}

      {showModal && (
        <Modal.Root title="Termos de uso" content={USE_TERMS}>
          <Modal.ButtonRoot>
            <Modal.Button
              labelText="Não concordo"
              handleClickButton={() => handleAcceptTerms(false)}
            />
            <Modal.Button
              labelText="Concordo"
              handleClickButton={() => handleAcceptTerms(true)}
              isPrimary
            />
          </Modal.ButtonRoot>
        </Modal.Root>
      )}

      <div className={styles.caroussel}>
        <Carousel>
          <Carousel.Item>
            <img src="img1.png" alt="Image One" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="img2.png" alt="Image Two" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="img3.png" alt="Image Tree" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="img4.png" />
          </Carousel.Item>
        </Carousel>
      </div>

      <div className={styles.background}>
        <div className={styles.input2}>
          <div className={styles.aligh}>
            <input
              onClick={async () => {
                setTipoSelected('INST')
                await fetchTitulos(category, cidade, bairro)
              }}
              name="tipo"
              type="radio"
              value={isEscola() ? 'Escola' : 'Instituição'}
            ></input>
            <p>{isEscola() ? 'Escola' : 'Instituição'}</p>
          </div>
          <div className={styles.aligh}>
            <input
              onClick={async () => {
                setTipoSelected('CURSO')
                await fetchTitulos(category, cidade, bairro)
              }}
              name="tipo"
              type="radio"
            ></input>
            <p>{isEscola() ? 'Série' : 'Curso'}</p>
          </div>
        </div>

        <div className={styles.filter}>
          <div className={styles.column}>
            <p>O que deseja estudar?</p>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value={'2'}>Ensino Básico</option>
              <option value={'3'}>Técnico</option>
              <option value={'4'}>Profissionalizante</option>
              <option value={'5'}>EJA</option>
              <option value={'6'}>Idiomas</option>
              <option value={'7'}>Pré-Vestibular / Preparatório</option>
              <option value={'9'}>Período Integral</option>
              <option value={'8'}>Faculdade</option>
            </select>
          </div>
          <div className={styles.column}>
            <p>Escolha uma cidade:</p>
            <select
              value={cidade}
              onChange={async (e) => {
                setCicade(e.target.value)
                setBairros([])
                setTitulos([])
                await fetchBairros(category, e.target.value)
              }}
            >
              <option>Escolha a cidade...</option>
              {cidades?.map((c) => {
                return <option>{c}</option>
              })}
            </select>
          </div>
          <div className={styles.column}>
            <p>Escolha um bairro:</p>
            <select
              onChange={async (e) => {
                setBairro(e.target.value)
                setTitulos([])
                await fetchTitulos(category, cidade, e.target.value)
              }}
            >
              <option>Escolha o bairro...</option>
              {bairros?.map((c) => {
                return <option>{c}</option>
              })}
            </select>
          </div>
          <div className={styles.column}>
            {tipoSelected == 'CURSO' ? (
              <p>{isEscola() ? 'Escolha uma série:' : 'Escolha um curso: '}</p>
            ) : (
              <p>
                {isEscola()
                  ? 'Escolha uma escola:'
                  : 'Escolha uma instituição: '}
              </p>
            )}
            {tipoSelected == 'CURSO' ? (
              <select onChange={(e) => selectTitulo(e.target.value)}>
                <option>
                  {isEscola()
                    ? 'Escolha uma série... '
                    : 'Escolha um curso... '}
                </option>
                {titulos.length > 0 ? (
                  titulos.map((t) => {
                    return <option>{t}</option>
                  })
                ) : (
                  <option value="none">Nada encontrado...</option>
                )}
              </select>
            ) : (
              <select onChange={(e) => setSelectedInstituicao(e.target.value)}>
                <option>
                  {isEscola()
                    ? 'Escolha uma escola... '
                    : 'Escolha uma instituição... '}
                </option>
                {instituicoesTitulos.length > 0 ? (
                  instituicoesTitulos.map((t) => {
                    return <option value={t.id}>{t.titulo}</option>
                  })
                ) : (
                  <option value="none">Nada encontrado...</option>
                )}
              </select>
            )}
          </div>
        </div>
        <div>
          <button onClick={handleBuscar} className={styles.button}>
            BUSCAR
          </button>
        </div>
      </div>

      <PathInformation />

      <Scholarships category={category} />

      <PeFriend />

      <Blog />

      <Capitalize />
    </div>
  )
}
