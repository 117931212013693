import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// hooks api
import api from '../../services/api';
import { useValidateSchema } from '../../hooks/useSchemaValidator';

// components
import { TitleFormStudents } from '../../components/TitleForm';
import { Input } from '../../components/Input';
import { Button } from '../../components/Button';

import styles from './styles.module.scss';

// utils
import {
  FriendFormDataSchemaType,
  friendFormDataSchemaWithCPF,
  friendFormDataSchemaWithEmail,
  friendFormDataSchemaWithPhone,
} from '../../utils/schemas/friendFormDataSchema';
import { cpfPattern, handleKeyUpCpf } from '../../utils/formatting/cpfMask';
import { handleKeyUpPhone } from '../../utils/formatting/phoneMask';
import { CustomerSelect } from '../../components/Select/selectCustomized';
import { handleKeyUpEmail } from '../../utils/formatting/emailMask';
import { Loader } from '../../components/Loader';

type TypeKey = 'PHONE' | 'CPF' | 'EMAIL';

export function FriendPEForms() {
  const [key, setKey] = useState<TypeKey>('PHONE');
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const keys = {
    PHONE: 'telefone',
    CPF: 'CPF',
    EMAIL: 'e-mail',
  };

  const geFuncKeyType = useCallback(
    (obj: { PHONE: any; CPF: any; EMAIL: any }) => {
      return {
        PHONE: obj.PHONE,
        CPF: obj.CPF,
        EMAIL: obj.EMAIL,
      }[key];
    },
    [key],
  );

  const friendFormDataSchema = geFuncKeyType({
    PHONE: friendFormDataSchemaWithPhone,
    CPF: friendFormDataSchemaWithCPF,
    EMAIL: friendFormDataSchemaWithEmail,
  });

  const { register, errors, handleSubmit } = useValidateSchema(
    friendFormDataSchema as unknown as FriendFormDataSchemaType,
  );

  async function registerPEFriend(dataForm: FriendFormDataSchemaType) {
    try {
      const dataFormatted = {
        ...dataForm,
        nome: dataForm.name,
        phone: dataForm.telephone,
        chave_pix: dataForm.pix_key,
      };
      setLoader(true);
      const {
        data: { friend },
      } = await api.post('/friends', dataFormatted);

      if (friend?.message) {
        toast(friend.message, { type: 'error' });
        setLoader(false);
        return;
      }

      navigate(`success`, {
        state: { code_friend: friend.code },
      });
      setLoader(false);
    } catch (error: any) {
      toast(error?.response?.data?.message?.message);
      setLoader(false);
    }
  }

  const getKeyPixType = useCallback((key: TypeKey) => keys[key], []);

  const getHandleKeyUpKeyPix = (e) =>
    geFuncKeyType({
      PHONE: () => handleKeyUpPhone(e),
      CPF: () => handleKeyUpCpf(e),
      EMAIL: () => handleKeyUpEmail(e),
    });

  return (
    <>
      {loader && <Loader />}
      <form
        onSubmit={handleSubmit(registerPEFriend)}
        className={styles.container}
      >
        <TitleFormStudents title='Cadastro' />
        <div className={styles.content_form}>
          <Input
            text='Nome'
            name='name'
            register={{ ...register('name') }}
            placeholder='Insira o nome do aluno'
            errors={errors.name}
          />

          <section className={styles.double_input}>
            <Input
              text='CPF'
              name='cpf'
              register={{ ...register('cpf') }}
              placeholder='Insira o seu cpf'
              errors={errors?.cpf}
              onKeyUp={handleKeyUpCpf}
              pattern={cpfPattern}
            />
            <Input
              text='Email'
              name='email'
              register={{ ...register('email') }}
              placeholder='Insira o email do responsável'
              errors={errors?.email}
            />
          </section>

          <section className={styles.double_input}>
            <Input
              text='Telefone'
              name='telephone'
              register={{ ...register('telephone') }}
              placeholder='Insira o telefone do responsável'
              errors={errors?.telephone}
              onKeyUp={handleKeyUpPhone}
            />
            <Input
              text='Telefone 2'
              name='second_phone'
              register={{ ...register('second_phone') }}
              placeholder='Insira outro telefone'
              errors={errors?.second_phone}
              onKeyUp={handleKeyUpPhone}
            />
          </section>

          <section className={styles.select_content}>
            <CustomerSelect
              className={styles.select}
              title='Escolha a chave'
              options={[
                { value: 'PHONE', text: 'Telefone' },
                { value: 'CPF', text: 'CPF' },
                { value: 'EMAIL', text: 'E-mail' },
              ]}
              name='key_type'
              onChange={(e) => setKey(e.target.value as TypeKey)}
            />
            <Input
              text={`Chave pix - ${getKeyPixType(key)}`}
              name='pix_key'
              register={{ ...register('pix_key') }}
              placeholder={`Insira a chave pix ${getKeyPixType(key)}`}
              errors={errors?.pix_key}
              onKeyUp={(e) => getHandleKeyUpKeyPix(e)()}
            />
          </section>
          <div className={styles.buttons}>
            <Button labelText='Salvar' type='submit' />
          </div>
        </div>
      </form>
    </>
  );
}
