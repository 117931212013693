import { CustomerSelect } from '../../Select/selectCustomized';
import styles from './styles.module.scss';

interface IProps {
  perPage: number;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
}

export const SelectPerPage = ({ perPage, setPerPage }: IProps) => {
  return (
    <div className={styles.selected_content}>
      <CustomerSelect
        title='Itens por página'
        defaultValue={perPage}
        options={[
          { value: '5', text: '5' },
          { value: '10', text: '10' },
          { value: '15', text: '15' },
          { value: '20', text: '20' },
        ]}
        onChange={(e) => setPerPage(+e.target.value)}
      />
    </div>
  );
};
