import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface IProps {
  children: ReactNode;
}

export function ModalButtonRoot({ children }: IProps) {
  return <div className={styles.modal_buttons}>{children}</div>;
}
