import { useEffect, useState } from 'react'
import styles from './aluno.admin.module.scss'
import { AlunoRepository } from '../repositories/aluno.repository'
import { useAuth } from '../../../hooks/useAuth'
import { useNavigate, useParams } from 'react-router-dom'
import api from '../../../services/api'
import { Aluno } from '../entities/aluno.entity'
import { formatBrDate } from '../../../utils/formatting/dateMask'
import { formatMoney } from '../../../utils/formatting/money'

interface IDocumento {
  id: number
  tipo: string
  arquivo: string
}

interface ICreateDocumento {
  tipo: string
  arquivo: File
}

interface IAlunoData {
  aluno: {
    id: number
    email: string
    alunonome: string
    alunonascimento: string
    alunorg: string
    alunocpf: string
    responsavelnome: string
    responsavelcpf: string
    responsavelemail: string
    responsavelnascimento: string
    responsaveltelefone: string
    responsaveltelefone2: string
    status: string
    ultimaescola: string
    cep: string
    endereco: string
    conheceu: string
  }
  inscricoes: IRegistrations[]
}

interface IRegistrations {
  status: string
  statuspagamento: string
  categoria: {
    titulo: string
  }
  instituicao: {
    id: number
    titulo: string
    url_imagem: string
  }
  curso: {
    turno: string
    titulo: string
  }
}

export function AlunoPage() {
  const { id } = useParams()
  const [aluno, setAluno] = useState<IAlunoData>()
  const [registrations, setRegistrations] = useState<IRegistrations[]>([])
  const { token } = useAuth()
  const [state, setState] = useState<
    'ALUNO' | 'DOCUMENTOS' | 'ADD DOCUMENT' | 'INSCRICAO' | 'VER INSCRICAO'
  >('ALUNO')
  const [documento, setDocumento] = useState<ICreateDocumento>()
  const [registration, setRegistration] = useState({} as IRegistrations)
  const [documentos, setDocumentos] = useState<IDocumento[]>([])

  const navigate = useNavigate()

  const setStatus = (status: string) =>
    ({
      Aguardando: 'Aguardando',
      pending: 'Aguardando',
      reproved: 'Reprovado',
      canceled: 'Cancelado',
      approved: 'Aprovado',
      in_processing: 'Em processo',
    }[status])

  const formatStudent = (data: Aluno) => {
    return {
      Nome: data.alunonome,
      CPF: data.alunocpf,
      'Data de Nascimento': data?.alunonascimento
        ? formatBrDate(data.alunonascimento as unknown as string)
        : 'Não cadastrado',
      Email: data.email || 'Não cadastrado',
      Endereço: data.endereco,
      CEP: data.cep,
      'Termos de uso': data.accepted_terms ? 'Aceito' : 'Não aceito',
      conheceu: data.conheceu,
      'Renda familiar': data?.family_income
        ? formatMoney(`${data.family_income / 100}`)
        : 'Não cadastrado',

      'Nome do responsável': data.responsavelnome,
      'CPF do Responsável': data.responsavelcpf,
      'Email do Responsável': data.responsavelemail,
      'Data de Nascimento do responsável': data?.responsavelnascimento
        ? formatBrDate(data.responsavelnascimento as unknown as string)
        : 'Não cadastrado',
      Telefone: data.responsaveltelefone,
      'Telefone 2': data.responsaveltelefone2,
      Status: setStatus(data.status),
      'Última Escola': data.ultimaescola,

      'Data do cadastro': data?.logdata
        ? formatBrDate(data.logdata as unknown as string)
        : 'Não cadastrado',
      'Última atualização': data?.updated_at
        ? formatBrDate(data.updated_at)
        : 'Não cadastrado',
    }
  }

  async function fetchData() {
    try {
      const data = await AlunoRepository.GetInstance().adminFindById(token, id)
      setRegistrations(data.inscricoes)
      const formattedStudent: any = formatStudent(data.aluno)

      setAluno(formattedStudent)

      const documentos =
        await AlunoRepository.GetInstance().adminListDocumentos(token, id)
      setDocumentos(documentos)
    } catch (error) {
      console.error('error: ', error)
    }
  }

  async function addDocumento() {
    const newDocument = await AlunoRepository.GetInstance().adminAddDocumento(
      token,
      id,
      documento.arquivo,
      documento.tipo,
    )
    setDocumento({} as ICreateDocumento)
    setState('ALUNO')
    setDocumentos([...documentos, newDocument])
  }

  async function deletarDocumento(iddocumento: string) {
    await AlunoRepository.GetInstance().adminDeleteDocumento(token, iddocumento)
    fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className={styles.mainContainer}>
      <div className={styles.header_buttons}>
        <button onClick={() => setState('ALUNO')}>Aluno</button>
        <button onClick={() => setState('DOCUMENTOS')}>Documentos</button>
        <button onClick={() => setState('INSCRICAO')}>Inscrições</button>
      </div>
      {state == 'ALUNO' ? (
        <>
          <h1>Aluno</h1>
          <div className={styles.students}>
            {aluno &&
              Object.keys(aluno).map((key) => {
                return (
                  <div className={styles.student_content}>
                    <strong>{key}:</strong> <span>{aluno[key]}</span>
                  </div>
                )
              })}
          </div>
        </>
      ) : state == 'DOCUMENTOS' || state == 'ADD DOCUMENT' ? (
        <div>
          <p>Documentos</p>

          {state == 'ADD DOCUMENT' ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <input
                value={documento?.tipo}
                onChange={(e) =>
                  setDocumento({ ...documento, tipo: e.target.value })
                }
                placeholder="tipo"
              />
              <input
                type="file"
                onChange={(e) =>
                  setDocumento({ ...documento, arquivo: e.target.files[0] })
                }
              />
              <button onClick={() => addDocumento()}>Adicionar</button>
              <button onClick={() => setState('DOCUMENTOS')}>Cancelar</button>
            </div>
          ) : (
            <div className={styles.documents}>
              {documentos?.map((doc) => {
                return (
                  <p>
                    <div>
                      {doc.tipo} ---
                      <a
                        target="blank"
                        href={`${api.getUri()}/uploads/documentos/${
                          doc.arquivo
                        }`}
                        rel="noreferrer"
                      >
                        {doc.arquivo}
                      </a>
                    </div>
                    <button onClick={() => deletarDocumento(doc.id.toString())}>
                      APAGAR
                    </button>
                  </p>
                )
              })}

              <button onClick={() => setState('ADD DOCUMENT')}>
                Adicionar Documento
              </button>
            </div>
          )}
        </div>
      ) : state == 'INSCRICAO' ? (
        <>
          <p>Inscrições</p>
          <div className={styles.registrations}>
            {registrations.map((item) => {
              return (
                <div
                  onClick={() => {
                    setRegistration(item)
                    setState('VER INSCRICAO')
                  }}
                  className={styles.inscricao}
                >
                  <p>{item.instituicao?.titulo ?? 'NAO TEM INSTITUIÇÃO'}</p>
                  <p>{item.curso?.titulo ?? 'NAO TEM CURSO'}</p>
                  <p>{item.categoria?.titulo ?? 'NAO TEM CATEGORIA'}</p>
                  <p>{`Turno: ${item.curso?.turno}`}</p>
                </div>
              )
            })}
          </div>
        </>
      ) : (
        registration && (
          <div className={styles.inscricaoValueContainer}>
            <p>INSCRICAO</p>
            <div>
              <p>CURSO:</p> <p>{registration.curso.titulo}</p>
            </div>
            <div>
              <p>STATUS:</p> <p>{registration.status}</p>
            </div>
            <div>
              <p>STATUS PAGAMENTO:</p> <p>{registration.statuspagamento}</p>
            </div>
            <a
              href=""
              onClick={() =>
                navigate(`/instituicao/${registration.instituicao.id}`)
              }
            >
              Ir para Instituicao
            </a>
          </div>
        )
      )}
    </div>
  )
}
