import { IListIInstitutions } from '@/domain/contracts/institutions'
import { IInstitution } from '@/domain/entities/institution'
import { useFormatOptions } from '../../hooks/use-format-options'
import { useQuery } from '@tanstack/react-query'

type ListInstitutionsProps = {
  listInstitutionsService: IListIInstitutions
}

export function useListInstitutions({
  listInstitutionsService,
}: ListInstitutionsProps) {
  const { formatOptions } = useFormatOptions()

  const {
    data,
    error: institutionsError,
    isLoading: isLoadingInstitutions,
  } = useQuery({
    queryKey: ['list_institutions'],
    initialData: [null, null],
    queryFn: listInstitutionsService.loadAll,
  })

  const [response] = data
  const institutions = formatOptions<IInstitution>(response, 'name')

  return {
    institutions,
    institutionsError,
    isLoadingInstitutions,
  }
}
