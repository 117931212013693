import ReactDOM from 'react-dom/client'
import { Providers } from './providers'
import { AppRouter } from './routes/app.routes'

import './global.css'
import 'react-toastify/dist/ReactToastify.css'

import { register } from 'swiper/element/bundle'
register()
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'
import 'swiper/css/effect-fade'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Providers>
    <AppRouter />
  </Providers>,
)
