import { z } from 'zod';

export const paymentFormDataSchema = z.object({
  receipt_number: z
    .string()
    .nonempty({ message: 'O número do recibo é obrigatório' })
    .min(3, { message: 'O número precisa de ter no mínimo 3 caracteres' }),
  amount_quantity: z
    .string()
    .nonempty({ message: 'Informar a quantidade é obrigatório' }),
});

export type PaymentPEFormDataSchemaType = z.infer<typeof paymentFormDataSchema>;
