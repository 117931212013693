import { useEffect, useState } from 'react';
import { CursoRepository, ICurso } from './admin/repositories/curso.repository';

import styles from './cursos.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { Curso } from '../components/curso';

interface ICursosPage {
  cursosInstituicao: boolean;
}

export function CursosPage({ cursosInstituicao }: ICursosPage) {
  const { token } = useAuth();
  const [cursos, setCursos] = useState<ICurso[]>([]);
  const navigate = useNavigate();
  const { idcategoria, cidade, titulo, bairro, idinstituicao, instituicao } =
    useParams();

  useEffect(() => {
    fetchCursos();
  }, []);

  async function fetchCursos() {
    let cursos: any[] = [];
    if (cursosInstituicao) {
      cursos =
        await CursoRepository.GetInstance().listarCursosDaInstituicaoPorCategoria(
          token,
          idinstituicao,
          idcategoria,
        );
    } else {
      cursos = await CursoRepository.GetInstance().listarCursos(
        token,
        Number(idcategoria),
        cidade,
        titulo,
        bairro,
      );
    }
    setCursos(cursos);
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.title}>
        {cursosInstituicao ? (
          <p>Bolsas de estudo encontradas para {instituicao}</p>
        ) : (
          <p>Bolsas de estudo encontradas para {titulo} </p>
        )}
      </div>
      <div className={styles.container}>
        <div className={styles.filtro}>FILTRO</div>
        {cursos?.length > 0 ? (
          <div className={styles.cursosContainer}>
            {cursos.map((curso) => {
              return (
                <Curso
                  key={curso.id}
                  onClickQuero={() =>
                    navigate(`/register-student/${curso.id}/step-1`)
                  }
                  onClickDetalhes={() => navigate(`/curso/${curso.id}`)}
                  curso={curso}
                />
              );
            })}
          </div>
        ) : (
          <div className={styles.title}>
            <p>Nenhum curso encontrado</p>
            <p>Pesquise por outra categoria ou outra instituicao</p>
          </div>
        )}
      </div>
    </div>
  );
}
