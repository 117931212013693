import { ImageFieldProps } from 'react-admin';
import api from '../../services/api';
import { useState } from 'react';

interface IApiImageProps extends ImageFieldProps {
  url: string;
  width?: number;
  height?: number;
  alt?: string;
}

export function ImageApi({ url, width, height, alt }: IApiImageProps) {
  const [imgError, setImgError] = useState(false);

  const handleError = () => setImgError(true);

  if (imgError) return null;

  return (
    <img
      width={width}
      height={height}
      onError={handleError}
      src={`${api.getUri()}/uploads/${url}`}
      alt={alt}
    />
  );
}
