import React from 'react';

import styles from './styles.module.scss';

interface TooltipProps {
  title: string;
  children: JSX.Element;
}

const Tooltip: React.FC<TooltipProps> = ({ title, children }) => {
  return (
    <div className={styles.container}>
      {children}
      <span>{title}</span>
    </div>
  );
};

export default Tooltip;
