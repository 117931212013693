import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Eye, Pencil, Trash } from 'phosphor-react'
import { toast } from 'react-toastify'

import styles from './styles.module.scss'

import api from '../../../../../services/api'

import { Aluno } from '../../../entities/aluno.entity'
import { AlunoRepository } from '../../../repositories/aluno.repository'
import { useAuth } from '../../../../../hooks/useAuth'

import { Loader } from '../../../../../components/Loader'
import Tooltip from '../../../../../components/Tooltip'
import { Pagination } from '../../../../../components/Pagination'
import { IResponse } from '../../../../../interfaces/types'
import { SelectPerPage } from '../../../../../components/Pagination/Select'
import { Modal } from '../../../../../components/Modal'

export default function AlunosPage() {
  const [response, setResponse] = useState({} as IResponse<Aluno>)
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const [searchName, setSearchName] = useState('')
  const [loading, setLoading] = useState(true)
  const [deleteStudent, setDeleteStudent] = useState({} as Aluno)
  const [searchFilter, setSearchFilter] = useState('alunonome')
  const { token } = useAuth()
  const navigate = useNavigate()
  const { count, data: students } = response

  const fetchData = useCallback(async () => {
    try {
      const data = await AlunoRepository.GetInstance().list(token, {
        page: currentPage,
        perPage,
      })
      setResponse(data)
      setLoading(false)
    } catch (error) {
      console.error('error: ', error)
      setLoading(false)
    }
  }, [AlunoRepository, token, currentPage, perPage, setResponse, setLoading])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  async function findByName() {
    if (!searchName) return
    setLoading(true)

    const students = await AlunoRepository.GetInstance().findByProperty(
      token,
      searchFilter,
      searchName,
    )
    setResponse({ data: students, count: 1, page: 1, perPage: 20 })
    setLoading(false)
  }

  function handleDetailsStudent(id: number) {
    navigate(`/aluno/${id}`)
  }

  function handleEditStudent(student: Aluno) {
    navigate(`edit/${student.id}`, { state: student })
  }

  const handleDeleteStudent = (student: Aluno) => {
    setDeleteStudent(student)
  }

  const handleCloseModal = () => setDeleteStudent({} as Aluno)

  const handleConfirmDeleteStudent = async () => {
    try {
      setLoading(true)
      const headers = { Authorization: `Bearer ${token}` }
      const { data } = await api.get(
        `/admin/aluno/${deleteStudent.id}/documentos`,
        { headers },
      )

      const promises = data.documentos.map((doc) => {
        return api.delete(`/admin/documentos/${doc.id}`, { headers })
      })
      await Promise.all(promises)

      await api.delete(`/admin/students/${deleteStudent.id}`, { headers })

      setDeleteStudent({} as Aluno)
      toast('Aluno deletado com sucesso.')
      fetchData()
    } catch (error) {
      console.error('error: ', error)
      setLoading(false)
    }
  }

  return (
    <div className={styles.main_container}>
      <h1>Administrador - Alunos</h1>
      <div className={styles.search}>
        <input
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
        ></input>
        <select
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
        >
          <option value={'alunonome'}>Nome do Aluno</option>
          <option value={'alunocpf'}>CPF do Aluno</option>
          <option value={'responsavelnome'}>Nome do Responsavel</option>
          <option value={'responsavelcpf'}>CPF do Responsavel</option>
          <option value={'email'}>Email</option>
        </select>
        <button onClick={findByName}>Search</button>
        <SelectPerPage perPage={perPage} setPerPage={setPerPage} />
      </div>

      {loading && (
        <>
          <Loader /> <h1 className={styles.loading}>...Buscando</h1>
        </>
      )}

      {deleteStudent?.id && (
        <div className={styles.content_modal}>
          <Modal.Root content="" title="Excluir Aluno">
            <span>
              Deseja realmente excluir o aluno {deleteStudent.alunonome}?<br />
              Essa ação não pode ser desfeita
            </span>
            <Modal.ButtonRoot>
              <Modal.Button labelText="Cancelar" onClick={handleCloseModal} />
              <Modal.Button
                labelText="Excluir"
                onClick={handleConfirmDeleteStudent}
                isPrimary
              />
            </Modal.ButtonRoot>
          </Modal.Root>
        </div>
      )}

      {!loading && (
        <table className={styles.table_container}>
          <thead>
            <tr>
              <td>Identificador</td>
              <td>Nome</td>
              <td>Cpf</td>
              <td>Responsável</td>
              <td>CPF Responsável</td>
              <td>Email</td>
              <div className={styles.actions}>Ações</div>
            </tr>
          </thead>
          {students?.length > 0 ? (
            students.map((student) => {
              return (
                <tr>
                  <td>{student.id}</td>
                  <td>{student.alunonome}</td>
                  <td>{student.alunocpf}</td>
                  <td>{student.responsavelnome}</td>
                  <td>{student.responsavelcpf}</td>
                  <td>{student.responsavelemail}</td>
                  <div className={styles.actions}>
                    <Tooltip title="Detalhes">
                      <Eye
                        onClick={() => handleDetailsStudent(student.id)}
                        size={24}
                      />
                    </Tooltip>

                    <Tooltip title="Editar">
                      <Pencil
                        onClick={() => handleEditStudent(student)}
                        size={24}
                      />
                    </Tooltip>

                    <Tooltip title="Excluir">
                      <Trash
                        onClick={() => handleDeleteStudent(student)}
                        size={24}
                      />
                    </Tooltip>
                  </div>
                </tr>
              )
            })
          ) : (
            <p>Nenhum aluno encontrado</p>
          )}
        </table>
      )}

      <Pagination
        count={count}
        perPage={perPage}
        page={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  )
}
