import { zodResolver } from '@hookform/resolvers/zod'
import { DeepPartial, FieldValues, useForm } from 'react-hook-form'
import { ZodType } from 'zod'

export function useValidateSchema<T extends FieldValues>(
  formSchema: T,
  defaultValues?: DeepPartial<T> | undefined,
) {
  type SchemaType = typeof formSchema
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<SchemaType>({
    resolver: zodResolver(formSchema as unknown as ZodType),
    defaultValues: (defaultValues || {}) as any,
  })

  return {
    register,
    handleSubmit,
    setValue,
    errors,
    control,
    reset,
    watch,
    isSubmitting,
  }
}
