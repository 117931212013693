import styles from './header.module.css'
import { useAuth } from '../hooks/useAuth'
import { Link } from 'react-router-dom'

export function Header() {
  const { token, admin, signOut } = useAuth()

  return (
    <div className={styles.header}>
      {!admin && (
        <Link to={'/'}>
          <img src="/logo posso.png"></img>
        </Link>
      )}

      <div className={styles.container}>
        {token && admin ? (
          <>
            <Link className={styles.FakeButton} onClick={signOut} to={'/'}>
              Sair do ADMIN
            </Link>
            <Link className={styles.FakeButton} to={'/instituicao'}>
              Instituições
            </Link>
            <Link className={styles.FakeButton} to={'/interested-partners'}>
              Interessados
            </Link>
            <Link className={styles.FakeButton} to={'/aluno'}>
              Alunos
            </Link>
            <Link className={styles.FakeButton} to={'/show-pe-friends'}>
              Amigos PE
            </Link>
            <Link className={styles.FakeButton} to={'/blogs'}>
              Blogs
            </Link>
          </>
        ) : (
          <>
            <Link className={styles.FakeButton} to={'/aluno/login'}>
              Aluno
            </Link>
            <Link className={styles.FakeButton} to={'/instituicao/login'}>
              Instituição
            </Link>
          </>
        )}
      </div>
    </div>
  )
}
