import { InputHTMLAttributes, LegacyRef, forwardRef } from 'react';
import styles from './styles.module.scss';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  register?: any;
  left_text?: string;
  right_text?: string;
  customizedStyles?: string;
}

export const Checkbox = forwardRef(
  (
    {
      customizedStyles,
      name,
      register,
      left_text,
      right_text,
      ...props
    }: IProps,
    ref,
  ) => {
    return (
      <div className={styles.checkbox_container}>
        <label
          className={customizedStyles || styles.switch}
          ref={ref as LegacyRef<HTMLLabelElement>}
        >
          {left_text && <span className={styles.switch_text}>{left_text}</span>}
          <div className={styles.switch_wrapper}>
            <input type='checkbox' name={name} {...register} {...props} />
            <span className={styles.switch_button}></span>
          </div>
          {right_text && (
            <span className={styles.switch_text}>{right_text}</span>
          )}
        </label>
      </div>
    );
  },
);
