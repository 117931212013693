import { QUESTIONS } from '../../constants';
import { BodyAskedQuestions } from './bodyAskedQuestions';
import styles from './styles.module.scss';

export function FrequentlyAskedQuestions() {
  const { ONE, TWO, THREE, FOUR, FIVE } = QUESTIONS;
  return (
    <div className={styles.asked_questions}>
      <h1>Dúvidas Frequentes</h1>
      <BodyAskedQuestions question={ONE.QUESTION} response={ONE.RESPONSE} />
      <BodyAskedQuestions question={TWO.QUESTION} response={TWO.RESPONSE} />
      <BodyAskedQuestions question={THREE.QUESTION} response={THREE.RESPONSE} />
      <BodyAskedQuestions question={FOUR.QUESTION} response={FOUR.RESPONSE} />
      <BodyAskedQuestions question={FIVE.QUESTION} response={FIVE.RESPONSE} />
    </div>
  );
}
