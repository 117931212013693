import { z } from 'zod';

export const capitalizeFormDataSchema = z
  .object({
    name: z
      .string()
      .nonempty({ message: 'O nome é obrigatório' })
      .min(3, { message: 'O nome precisa de ter no mínimo 3 caracteres' }),
    email: z.string().nonempty({ message: 'O email é obrigatório' }).email(),
    notification_by_email: z.boolean(),
    notification_by_phone: z.boolean(),
    not_found: z.boolean(),
    scholarship: z
      .string()
      .max(250, { message: 'A bolsa pode ter no máximo 250 caracteres' })
      .optional(),
    phone: z
      .string()
      .nonempty({ message: 'O telefone é obrigatório' })
      .min(10, { message: 'Formato de telefone inválido' }),
  })
  .refine((data) => !data.not_found || (data.not_found && data.scholarship), {
    path: ['scholarship'],
    message: 'A bolsa é obrigatória',
  });

export type CapitalizeFormDataSchemaType = z.infer<
  typeof capitalizeFormDataSchema
>;
