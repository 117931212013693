import { Link } from 'react-router-dom'
import styles from './styles.module.scss'
import { Button } from '../Button'
import { ButtonHTMLAttributes } from 'react'

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  redirectLink: string
  right_label?: string
  type?: 'submit' | 'reset' | 'button'
  handleClick?: () => void
  containerStyles?: 'flex_end' | 'flex_start'
}

export function FooterButtons({
  redirectLink,
  type,
  handleClick,
  right_label,
  containerStyles,
  ...props
}: IProps) {
  const container_styles = containerStyles
    ? `${styles[containerStyles]} ${styles.footer_buttons}`
    : styles.footer_buttons

  return (
    <div className={container_styles}>
      <Link to={redirectLink}>
        <Button labelText="Voltar" />
      </Link>
      <Button
        {...props}
        isPrimary={!!right_label}
        labelText={right_label || 'Avançar'}
        onClick={handleClick}
        type={type}
      />
    </div>
  )
}
