import api from '../../../services/api';
import { IFriends, IResponse } from '../../../interfaces/types';

export class FriendsRepository {
  token?: string;

  setToken(_token?: string) {
    this.token = _token;
  }

  private static Instance: FriendsRepository = null;
  public static GetInstance() {
    const instance = this.Instance ? this.Instance : new FriendsRepository();
    return instance;
  }

  async list(
    token: string,
    params: { page: number; perPage: number },
  ): Promise<IResponse<IFriends>> {
    const headers = { Authorization: `Bearer ${token}` };

    const { data } = await api.get<{ friends: IResponse<IFriends> }>(
      '/friends',
      {
        headers,
        params,
      },
    );

    return data.friends;
  }
}
