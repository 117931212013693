import { toast } from 'react-toastify';

import { Button } from '../../../../components/Button';
import { FooterButtons } from '../../../../components/FooterButtonsCustomized';
import { CustomerSelect } from '../../../../components/Select/selectCustomized';

import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/useAuth';

import { useValidateSchema } from '../../../../hooks/useSchemaValidator';
import {
  EditPartnerFormDataSchemaType,
  EditStatusPartnerFormDataSchemaType,
  editPartnerFormDataSchema,
} from '../../../../utils/schemas/registerPartnersFormDataSchema';

import styles from './styles.module.scss';

interface IProps {
  data: EditPartnerFormDataSchemaType;
  handleGoBack: () => void;
  closeModal: () => void;
  getPartners: () => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditInterestedPartnerForm = ({
  data: restData,
  handleGoBack,
  setLoading,
  getPartners,
  closeModal,
}: IProps) => {
  const { register, handleSubmit } = useValidateSchema(
    editPartnerFormDataSchema as unknown as EditStatusPartnerFormDataSchemaType,
    { ...restData, contacted: restData.contacted ? 'yes' : 'no' },
  );
  const { token } = useAuth();

  const handleOnSubmit = async (
    dataForm: EditStatusPartnerFormDataSchemaType,
  ) => {
    setLoading(true);
    const headers = { Authorization: `Bearer ${token}` };
    const { data } = await api.put(
      '/interested-partners',
      {
        ...restData,
        ...dataForm,
        contacted: dataForm.contacted === 'yes' ? true : false,
      },
      { headers },
    );

    const treatmentError = () => {
      toast(data.partner.message, { type: 'error' });
      setLoading(false);
    };

    const success = () => {
      toast('Dados salvos com sucesso.');
      setLoading(false);
      closeModal();
      getPartners();
    };

    return !data.partner?.id ? treatmentError() : success();
  };

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className={styles.content_form}
    >
      <section className={styles.select_container}>
        <CustomerSelect
          className={styles.select_content}
          options={[
            { text: 'Pendente', value: 'pending' },
            { text: 'Em Processo', value: 'in_process' },
            { text: 'Cancelado', value: 'canceled' },
            { text: 'Reprovado', value: 'reproved' },
            { text: 'Aprovado', value: 'approved' },
          ]}
          title='Status'
          register={{ ...register('status') }}
        />

        <CustomerSelect
          className={styles.select_content}
          options={[
            { text: 'Sim', value: 'yes' },
            { text: 'Não', value: 'no' },
          ]}
          title='Contactado'
          register={{ ...register('contacted') }}
        />
      </section>

      <FooterButtons.Root>
        <FooterButtons.ButtonRoot>
          <Button labelText='Voltar' onClick={handleGoBack} />
          <Button labelText='Salvar' isPrimary type='submit' />
        </FooterButtons.ButtonRoot>
      </FooterButtons.Root>
    </form>
  );
};
