export const handleKeyUpCnpj = (e?: React.FormEvent<HTMLInputElement>) => {
  if (!e) return (e.currentTarget.value = '');

  e.currentTarget.maxLength = 17;
  let value = e.currentTarget.value;

  value = value.replace(/\D/g, '');
  const length = value.length;

  if (length >= 2 && length <= 5) value = value.replace(/^(\d{2})/, '$1.');
  if (length >= 6 && length <= 8)
    value = value.replace(/^(\d{2})(\d{3})/, '$1.$2.');
  if (length >= 9 && length <= 12)
    value = value.replace(/^(\d{2})(\d{3})(\d{3})/, '$1.$2.$3/');
  if (length > 13)
    value = value.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5',
    );
  e.currentTarget.value = value;
};

export const removeKeyUpValues = (e: React.FormEvent<HTMLInputElement>) => {
  e.currentTarget.value = '';
};

export const cpfMask = (cpf: string) => {
  const regex = (text: string) =>
    text.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3-$4');
  const cpfFormatted = cpf ? regex(cpf) : '';
  return cpfFormatted;
};

export const cnpjPattern = '[0-9]{2}.[0-9]{3}.[0-9]{3}/[0-9]{4}-[0-9]{2}';
