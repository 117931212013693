import { CursosPage } from '../pages/cursos'
import { CursoPage } from '../components/curso.page'
import { PerfilPage } from '../pages/perfil.page'
import { LoginAluno } from '../pages/login.aluno'
import { LoginInstituicao } from '../pages/login.instituicao'
import { Who } from '../pages/who'
import { FrequentlyAskedQuestions } from '../pages/frequentlyAskedQuestions'
import MainPage from '../pages/main/main'
import { LoginAdmin } from '../pages/login.admin'
import { StudentsFormOne } from '../pages/students/step_1/studentForm_1'
import { StudentsForm } from '../pages/students/step_2/studentForm'
import { StudentsFormStepThree } from '../pages/students/step_3/studentFormStep3'
import { StudentsFormStepFour } from '../pages/students/step_4/studentFormStep4'
import { StudentsFormStepSuccess } from '../pages/students/success/index.tsx'

import { FriendPEForms } from '../pages/friendsPE'
import { FriendPESuccess } from '../pages/friendsPE/success'
import { PEfriend } from '../pages/friendsPE/info'
import { RegisterInterestedPartner } from '../pages/partnerSchool/interestedPartner/registerForm'
import { PartnerSchool } from '../pages/partnerSchool'
import { InterestedPartnerSuccess } from '../pages/partnerSchool/interestedPartner/success'
import ErrorBoundary from '@/pages/erro-boundary'

export const mainRoutes = [
  {
    path: '*',
    element: (
      <ErrorBoundary>
        <MainPage />
      </ErrorBoundary>
    ),
  },
  { path: '/', element: <MainPage /> },
  { path: '/home', element: <MainPage /> },
  { path: '/aluno/login', element: <LoginAluno /> },
  { path: '/instituicao/login', element: <LoginInstituicao /> },
  { path: '/admin/login', element: <LoginAdmin /> },
  { path: '/curso/:id', element: <CursoPage /> },
  { path: '/register-student/:course_id/step-1', element: <StudentsFormOne /> },
  { path: '/register-student/:course_id/step-2', element: <StudentsForm /> },
  {
    path: '/register-student/:course_id/step-3',
    element: <StudentsFormStepThree />,
  },
  {
    path: '/register-student/:course_id/step-4',
    element: <StudentsFormStepFour />,
  },
  {
    path: '/register-student/:course_id/success',
    element: <StudentsFormStepSuccess />,
  },
  {
    path: '/cursos/:idcategoria/:cidade/:bairro/:titulo',
    element: <CursosPage cursosInstituicao={false} />,
  },
  {
    path: '/cursos/:instituicao/:idinstituicao/:idcategoria',
    element: <CursosPage cursosInstituicao={true} />,
  },
  { path: '/perfil', element: <PerfilPage /> },
  { path: '/', element: <MainPage /> },
  {
    path: '/frequently-asked-questions',
    element: <FrequentlyAskedQuestions />,
  },
  { path: '/quem-somos', element: <Who /> },
  { path: '/amigoPE', element: <PEfriend /> },
  {
    path: '/register-interested-partner',
    element: <RegisterInterestedPartner />,
  },
  {
    path: '/register-interested-success',
    element: <InterestedPartnerSuccess />,
  },
  { path: '/partner-school', element: <PartnerSchool /> },
  { path: '/friend-pe', element: <FriendPEForms /> },
  { path: '/friend-pe/success', element: <FriendPESuccess /> },
]
