import { useLocation, useNavigate, useParams } from 'react-router-dom';

// styles
import styles from './styles.module.scss';

// utils
import {
  StudentThirdStepType,
  studentThirdStepFormDataSchema,
} from '../../../utils/schemas/studentFormDataSchema';
import { cpfPattern, handleKeyUpCpf } from '../../../utils/formatting/cpfMask';
import { handleKeyUpPhone } from '../../../utils/formatting/phoneMask';
import { handleKeyUpCep } from '../../../utils/formatting/cepMask';
import {
  datePattern,
  handleKeyUpDate,
} from '../../../utils/formatting/dateMask';

//hooks
import { useValidateSchema } from '../../../hooks/useSchemaValidator';

//components
import { Header } from '../../../components/header';
import { Statusbar } from '../../../components/Statusbar';
import { Input } from '../../../components/Input';
import { TitleFormStudents } from '../../../components/TitleForm';
import { Loader } from '../../../components/Loader';
import { FooterButtons } from '../../../components/FooterButtons';

interface IFormStudent {
  name_responsible: string;
  date_of_birth_responsible: string;
  cpf_responsible: string;
  email: string;
  telephone: string;
  address: string;
  cep: string;
}

export function StudentsFormStepThree() {
  const { register, isSubmitting, errors, handleSubmit } = useValidateSchema(
    studentThirdStepFormDataSchema as unknown as StudentThirdStepType,
  );
  const navigate = useNavigate();
  const { course_id } = useParams();
  const { state } = useLocation();

  const hasErrors =
    !!errors?.address ||
    !!errors?.cep ||
    !!errors?.cpf_responsible ||
    !!errors?.name_responsible ||
    !!errors?.email ||
    !!errors?.telephone ||
    !!errors?.date_of_birth_responsible;

  const handleOnSubmit = (responsible: IFormStudent) => {
    if (!hasErrors)
      navigate(`/register-student/${course_id}/step-4`, {
        state: { responsible, student: state?.student },
      });
  };

  return (
    <>
      <Header />
      {isSubmitting && <Loader />}
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        className={styles.container_form}
      >
        <Statusbar step='step_3' />

        <span>Etapa 3 de 4</span>

        <section className={styles.content_form}>
          <TitleFormStudents title='Dados do Responsável' />

          <div className={styles.double_input}>
            <Input
              text='Nome do Responsável'
              name='name_responsible'
              register={{ ...register('name_responsible') }}
              placeholder='Insira o nome do responsável'
              errors={errors?.name_responsible}
            />
            <Input
              text='CPF do Responsável'
              name='cpf_responsible'
              register={{ ...register('cpf_responsible') }}
              placeholder='Insira o cpf do responsável'
              onKeyUp={handleKeyUpCpf}
              pattern={cpfPattern}
              errors={errors?.cpf_responsible}
            />
          </div>

          <div className={styles.double_input}>
            <Input
              text='Data de Nascimento do responsável'
              name='date_of_birth_responsible'
              register={{ ...register('date_of_birth_responsible') }}
              placeholder='Insira a data de nascimento do responsável'
              errors={errors?.date_of_birth_responsible}
              onKeyUp={handleKeyUpDate}
              pattern={datePattern}
            />
            <Input
              text='Email'
              name='email'
              register={{ ...register('email') }}
              placeholder='Insira o email do responsável'
              errors={errors?.email}
            />
          </div>

          <div className={styles.double_input}>
            <Input
              text='Telefone'
              name='telephone'
              register={{ ...register('telephone') }}
              placeholder='Insira o telefone do responsável'
              errors={errors?.telephone}
              onKeyUp={handleKeyUpPhone}
            />
            <Input
              text='Telefone 2'
              name='second_phone'
              register={{ ...register('second_phone') }}
              placeholder='Insira outro telefone'
              errors={errors?.second_phone}
              onKeyUp={handleKeyUpPhone}
            />
          </div>

          <section className={styles.double_input}>
            <Input
              text='Endereço'
              name='address'
              register={{ ...register('address') }}
              placeholder='Insira o endereço'
              errors={errors?.address}
            />
            <Input
              text='CEP'
              name='cep'
              register={{ ...register('cep') }}
              placeholder='Insira o cep'
              errors={errors?.cep}
              onKeyUp={handleKeyUpCep}
            />
          </section>
          <FooterButtons
            redirectLink={`/register-student/${course_id}/step-2`}
          />
        </section>
      </form>
    </>
  );
}
