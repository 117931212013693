export const emailPattern =
  '[a-zA-Z0-9_]+ ( [.] [a-zA-Z0-9_]+)*@ [a-zA-Z0-9_]+ ( [.] [a-zA-Z0-9_]+)* [.] [a-zA-Z] {1,5}';

export const handleKeyUpEmail = (e?: React.FormEvent<HTMLInputElement>) => {
  let value = e.currentTarget.value;
  value = value.replace(/\\/g, '');

  e.currentTarget.maxLength = 50;
  e.currentTarget.value = value;
};
