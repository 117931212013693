import React, { InputHTMLAttributes } from 'react';
import { Info } from 'phosphor-react';
import { FieldError } from 'react-hook-form';

import styles from './styles.module.scss';
import Tooltip from '../Tooltip';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  text: string;
  register?: any;
  errors?: FieldError;
}

export const Input: React.FC<IProps> = ({
  text,
  register,
  errors = null,
  ...props
}) => {
  const TooltipError = (
    <Tooltip title={errors?.message || ''}>
      <Info size={36} color='red' />
    </Tooltip>
  );
  const style = errors?.message ? styles.error : '';
  return (
    <div className={styles.container}>
      <p>{text}:</p>
      <div className={styles.content}>
        <input {...register} {...props} className={style} />
        {!!errors?.message && errors.message && TooltipError}
      </div>
    </div>
  );
};
