import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

export const PeFriend = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img src='/Group7275.png' />
        <div className={styles.text_content}>
          <h3>Descubra o Programa Amigo PE e Ganhe Recompensas!</h3>
          <p>
            Basta se cadastrar em nosso site para receber um código exclusivo.
            Compartilhe esse código com amigos interessados em nossas vagas
            escolares ou cursos. Quando alguém usar seu código e concluir a
            matrícula, você receberá R$10,00 como agradecimento. Suas
            recompensas serão creditadas em até 10 dias úteis. Participe agora e
            comece a compartilhar e ganhar!{' '}
          </p>
          <Link className={styles.link_button} to='/amigoPE'>
            Saiba Mais
          </Link>
        </div>
      </div>
    </div>
  );
};
