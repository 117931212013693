export const handleKeyUpCep = (e?: React.FormEvent<HTMLInputElement>) => {
  if (!e) return (e.currentTarget.value = '');

  e.currentTarget.maxLength = 9;
  let value = e.currentTarget.value;

  value = value.replace(/\D/g, '');
  const length = value.length;

  if (length >= 5 && length <= 6) value = value.replace(/^(\d{5})/, '$1-');
  if (length >= 7 && length <= 8)
    value = value.replace(/^(\d{5})(\d{3})/, '$1-$2');

  e.currentTarget.value = value;
};

export const removeKeyUpValues = (e: React.FormEvent<HTMLInputElement>) => {
  e.currentTarget.value = '';
};

export const cepPattern = '[0-9]{5}-[0-9]{3}';
