import { useState } from 'react'
import { useAuth } from '../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import styles from './login.module.scss'
import { toast } from 'react-toastify'

export function LoginAdmin() {
  const { signIn } = useAuth()
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  async function handleLogin() {
    const error = await signIn({ login, password, role: 'admin' })

    if (error)
      return toast(error.message || 'Ocorreu algum erro, tente novamente.', {
        type: 'error',
      })

    navigate('/instituicao')
    window.location.reload()
  }

  return (
    <div className={styles.background}>
      <div className={styles.backgroundTwo}>
        <div className={styles.column}>
          <img src="/logo posso.png"></img>
          <div className={styles.text2}>
            <p>LOGIN Admin</p>
          </div>
          <div className={styles.text}>
            <p>Digite o email do admin</p>
          </div>
          <input
            value={login}
            type="email"
            onChange={(e) => setLogin(e.target.value)}
            placeholder="Email"
          ></input>
          <div className={styles.text}>
            <p>Digite a senha do admin</p>
          </div>
          <input
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Senha"
          ></input>
          <div className={styles.bar}></div>
          <button onClick={handleLogin}>Entrar</button>
        </div>
        <div className={styles.columnTwo}>
          <img src="/login-illustrator.png"></img>
        </div>
      </div>
    </div>
  )
}
