import { IUpdateRegisteredCourses } from '@/domain/contracts/registered-courses'
import { Course } from '@/domain/entities/course'
import { queryClient } from '@/providers/libs/query-client'
import { useMutation } from '@tanstack/react-query'

type UpdateACoursesProps = {
  updateCourseService: IUpdateRegisteredCourses
  id: number
}

export const useEditRegisteredCourse = ({
  updateCourseService,
  id,
}: UpdateACoursesProps) => {
  const mutation = useMutation({
    mutationKey: ['edit-course', id],
    mutationFn: (data: Course) => updateCourseService.update(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['list_course_by_student_id'] })
    },
  })

  return { mutation }
}
