import styles from './styles.module.scss';

interface IProps {
  question: string;
  response: string;
}

export function BodyAskedQuestions({ question, response }: IProps) {
  return (
    <div className={styles.question}>
      <strong>{question}</strong>
      <span>{response}</span>
    </div>
  );
}
