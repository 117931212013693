import { InputHTMLAttributes } from 'react'
import { FieldError } from 'react-hook-form'

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  title: string
  className?: string
  register?: any
  errors?: FieldError
  options: { value: string; text: string }[]
}

export function CustomerSelect({
  title,
  register,
  className,
  options,
  ...props
}: IProps) {
  return (
    <div className={`${className}`}>
      <p>{title}</p>
      <select {...register} {...props}>
        <option value="" disabled selected>
          Nada selecionado...
        </option>
        {options.map((op) => {
          return (
            <option key={op.value} value={op.value}>
              {op.text}
            </option>
          )
        })}
      </select>
    </div>
  )
}
