import { useLocation } from 'react-router-dom';
import styles from './styles.module.scss';

export function FriendPESuccess() {
  const { code_friend } = useLocation().state as { code_friend: string };
  return (
    <div className={styles.background}>
      <div className={styles.background}>
        <div className={styles.backgroundTwo}>
          <div className={styles.title}>
            <p>Seu Código do AmigoPE é: </p>
          </div>
          <div>
            <p
              style={{
                fontSize: 32,
              }}
            >
              {code_friend}
            </p>

            <p
              style={{
                fontSize: 16,
              }}
            >
              Compartilhe-o com os seus amigos!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
